import * as React from 'react';
import { Wrapper, Input, WrapperButton } from './style';
import { Button } from '../Button';

interface CodeInputProps {
  action?: any;
  state?: boolean;
  val1: any;
  setVal1: any;
  val2: any;
  setVal2: any;
  val3: any;
  setVal3: any;
  val4: any;
  setVal4: any;
  val5: any;
  setVal5: any;
  val6: any;
  setVal6: any;
}

export const CodeInput: React.FC<CodeInputProps> = ({
  action,
  state,
  val1,
  setVal1,
  val2,
  setVal2,
  val3,
  setVal3,
  val4,
  setVal4,
  val5,
  setVal5,
  val6,
  setVal6,
}: CodeInputProps): JSX.Element => {
  const [ref1, setRef1] = React.useState();
  const [ref2, setRef2] = React.useState();
  const [ref3, setRef3] = React.useState();
  const [ref4, setRef4] = React.useState();
  const [ref5, setRef5] = React.useState();
  const [ref6, setRef6] = React.useState();
  const [full, setFull] = React.useState(true);

  if (ref1 !== undefined && !val1) {
    //@ts-ignore
    ref1.focus();
  }

  function handleKeyDown(event: any, value: number) {
    const keyName = event.key;
    if (event.target.value !== '' || keyName === 'Backspace') {
      if (keyName !== 'Backspace') {
        setTimeout(() => {
          if (value === 1) {
            //@ts-ignore
            ref2.focus();
          } else if (value === 2) {
            //@ts-ignore
            ref3.focus();
          } else if (value === 3) {
            //@ts-ignore
            ref4.focus();
          } else if (value === 4) {
            //@ts-ignore
            ref5.focus();
          } else if (value === 5) {
            //@ts-ignore
            ref6.focus();
          }
        }, 100);
      } else {
        if (value === 2) {
          //@ts-ignore
          ref1.focus();
        } else if (value === 3) {
          //@ts-ignore
          ref2.focus();
        } else if (value === 4) {
          //@ts-ignore
          ref3.focus();
        } else if (value === 5) {
          //@ts-ignore
          ref4.focus();
        } else if (value === 6) {
          //@ts-ignore
          ref5.focus();
        }
      }
    }
  }

  if (
    val1 !== '' &&
    val2 !== '' &&
    val3 !== '' &&
    val4 !== '' &&
    val5 !== '' &&
    val6 !== '' &&
    full
  ) {
    setFull(false);
  }

  if (
    (val1 === '' ||
      val2 === '' ||
      val3 === '' ||
      val4 === '' ||
      val5 === '' ||
      val6 === '') &&
    !full
  ) {
    setFull(true);
  }

  function handleName() {
    if (full && !state) {
      return 'Escribe el código';
    } else if (!full && !state) {
      return 'Confirmar';
    } else if (!full && state) {
      return 'Cargando...';
    }
  }

  return (
    <>
      <Wrapper>
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef1(ref)}
          onKeyUp={(key) => handleKeyDown(key, 1)}
          value={val1}
          onChange={setVal1}
        />
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef2(ref)}
          onKeyUp={(key) => handleKeyDown(key, 2)}
          value={val2}
          onChange={setVal2}
        />
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef3(ref)}
          onKeyUp={(key) => handleKeyDown(key, 3)}
          value={val3}
          onChange={setVal3}
        />
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef4(ref)}
          onKeyUp={(key) => handleKeyDown(key, 4)}
          value={val4}
          onChange={setVal4}
        />
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef5(ref)}
          onKeyUp={(key) => handleKeyDown(key, 5)}
          value={val5}
          onChange={setVal5}
        />
        <Input
          maxLength={1}
          //@ts-ignore
          ref={(ref) => setRef6(ref)}
          onKeyUp={(key) => handleKeyDown(key, 6)}
          value={val6}
          onChange={setVal6}
        />
      </Wrapper>
      <WrapperButton>
        {/*
            // @ts-ignore TS2589 */}
        <Button
          label={handleName()}
          onClick={action}
          mt="10px"
          py="14px"
          height="auto"
          raised
          disabled={full || state}
        />
      </WrapperButton>
    </>
  );
};
