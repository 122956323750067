import styled, { keyframes } from 'styled-components';
import { ifProp } from 'styled-tools';
import {
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
} from 'styled-system';

export const KeyframeOpacity = keyframes`
  0% {
    opacity: .1;
  }
  
  50% {
    opacity: .2;
  }
  
  100% {
    opacity: .1;
  }
`;

export interface BoxProps extends SpaceProps, LayoutProps, FlexboxProps {
  empty?: boolean;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  background-color: ${ifProp('empty', 'none', '#444')};
  animation: ${ifProp('empty', 'none', KeyframeOpacity)};
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  ${flexbox}
  ${space}
    ${layout}
`;

Box.defaultProps = {
  empty: false,
};
