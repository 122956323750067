import * as React from 'react';
import axios from 'axios';
import { GOOGLE_INTEGRATION_KEY, GOOGLE_INTEGRATION_URL } from '../../config';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import useReactRouter from 'use-react-router';
import { Placeholder } from '../../components/Placeholder';
import { RouteComponentProps } from 'react-router';
import firebase from 'firebase';

export const ExternalTrackingPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const { history } = useReactRouter();
  const dataUrl = location.pathname.split('/');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const query = `{\n  clientGuideTracking(clientId: "${dataUrl[2]}", reference: "${dataUrl[3]}") {\n    guide\n  }\n}\n`;
    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };
    axios
      .post(
        GOOGLE_INTEGRATION_URL,
        {
          query: query,
        },
        config,
      )
      .then((response: any) => {
        const { clientGuideTracking } = response.data.data;
        history.push(`/guide/${clientGuideTracking.guide}`);
      })
      .catch((error: any) => {
        console.log(error);
        setError(true);

        firebase
          .analytics()
          .logEvent('client_guide_catch', { guide: dataUrl[1] });
      });
  }, [dataUrl, history]);

  if (error) {
    return (
      <Alert
        title="No hay nada por acá"
        subtitle="¿En qué te podemos ayudar?"
        actions={
          <>
            {/*
            // @ts-ignore TS2589 */}
            <Button onClick={(): void => history.push('/')} mt={2} outlined>
              Rastrear otra guía
            </Button>
          </>
        }
      />
    );
  }

  return <Placeholder />;
};
