import styled from 'styled-components';
import { theme, prop } from 'styled-tools';
import {
  Box,
  Flex as RebassFlex,
  Heading as Head,
} from 'rebass/styled-components';

import defaultImage from './defaultImage';

export { Box, Text } from 'rebass/styled-components';

export const Logo = styled(Box)`
  border: 1px solid ${theme('colors.dustyGray')};
  background-color: ${theme('colors.alto')};
  background-image: url(${prop('src')});
  background-size: cover;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  min-width: ${prop('size')}px;
`;

Logo.defaultProps = {
  src: defaultImage,
};

export const Heading = styled(Head)`
  font-size: 16px;
`;

export const Flex = styled(RebassFlex)`
  &:after {
    content: '';
    height: 0.5em;
    border-top: 1px solid black;
    z-index: -1;
  }
`;
