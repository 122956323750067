//@ts-nocheck
import * as React from 'react';
import useReactRouter from 'use-react-router';
import { AddressContainer } from '../../containers';
import { RouteComponentProps } from 'react-router';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { Placeholder } from '../../components/Placeholder';
import axios from 'axios';
import { GOOGLE_INTEGRATION_KEY, GOOGLE_INTEGRATION_URL } from '../../config';
import { Layout } from '../../components/Layout';
import firebase from 'firebase';

export const AddressPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  //@ts-ignore
  const validate = localStorage.getItem('token-address');
  /* eslint-disable @typescript-eslint/no-unused-vars*/
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState();
  const [edit, setEdit] = React.useState();
  const [lite, setLite] = React.useState();
  const [noPoints, setNoPoints] = React.useState(false);
  const [validateToken, setValidateToken] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [business, setBusiness] = React.useState();
  const [confirmContainer, setConfirmContainer] = React.useState();
  const [locationAddress, setLocationAddress] = React.useState();
  const [step, setStep] = React.useState();
  const [message, setMessage] = React.useState(
    'No fue posible comunicarse con el servidor',
  );
  const [streetNumber, setStreetNumber] = React.useState();
  const [guide, setGuide] = React.useState();
  const { history } = useReactRouter();
  const dataUrl = location.pathname.split('/');
  const token = dataUrl[3];
  const config = {
    headers: {
      'x-api-key': GOOGLE_INTEGRATION_KEY,
    },
  };
  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    if (token) {
      // Validate token change address
      const dataToken = {
        token: token,
        guide: dataUrl[1],
      };
      const validateQuery = `{\n  validateToken(guide: "${dataToken.guide}", token: ${dataToken.token}) {\n    validate\n expired\n  }\n}`;
      axios
        .post(
          GOOGLE_INTEGRATION_URL,
          {
            query: validateQuery,
          },
          config,
        )
        .then((response: any) => {
          const { validateToken } = response.data.data;
          if (validateToken.validate && !validateToken.expired) {
            setValidateToken(true);

            firebase.analytics().logEvent('token_valid', { guide, token });
          } else if (validateToken.validate && validateToken.expired) {
            firebase.analytics().logEvent('token_expired', { guide, token });
            setMessage('Su token ha expirado');
            setError(true);
          } else {
            firebase.analytics().logEvent('token_invalid', { guide, token });
            setMessage('No se ha podido verificar el código');
            setError(true);
          }
        })
        .catch((error) => {
          firebase.analytics().logEvent('error_token', { guide, token });
          setNoPoints(true);
        });
    }
  }, []);

  if (location.state !== undefined && !data) {
    // @ts-ignore
    const {
      data,
      edit,
      lite,
      business,
      confirm,
      locationAddress,
      step,
      streetNumber,
    } = location.state;
    if (data.points !== [] && noPoints) {
      setNoPoints(true);
    }
    setData(data);
    setEdit(edit);
    setLite(lite);
    setBusiness(business);
    setConfirmContainer(confirm);
    setLocationAddress(locationAddress);
    setStep(step);
    setStreetNumber(streetNumber);
  }

  function validateGuide() {
    const expression = new RegExp(
      '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$',
    );
    return expression.test(dataUrl[1]);
  }

  if (validateGuide() && !guide) {
    const guide = atob(dataUrl[1]);
    setGuide(guide);
  } else if (!guide) {
    setGuide(dataUrl[1]);
  }
  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    if (dataUrl[1].length > 8 && !business) {
      setBusiness('SD');
    } else if (dataUrl[1].length < 8 && !business) {
      setBusiness('OD');
    }

    const guideQuery = `{\n guideTracking(guide: "${guide}") {\n id\n reference\n    businessType {\n   name\n   }\n  contact {\n email\n phone\n }\n client {\n email\n cellPhone\n }\n   originalAddress\n   address\n {\n fullAddress\n externalNumber\n municipality\n internalNumber\n neighborhood\n street\n zipCode\n latitude\n longitude\n zipCode\n }\n history\n {\n statusId\n statusName\n statusDescription\n createdAt\n updatedAt\n }\n createdAt\n updatedAt\n }\n }`;
    axios
      .post(
        GOOGLE_INTEGRATION_URL,
        {
          query: guideQuery,
        },
        config,
      )
      .then((response: any) => {
        const { guideTracking } = response.data.data;

        if (guideTracking === null) {
          setNoPoints(true);
        } else {
          setData(guideTracking);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setNoPoints(true);
        //@ts-ignore
        firebase.analytics().logEvent('guide_address_page_catch', { guide });
      });
  }, []);

  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  //@ts-ignore
  if (
    !validateToken &&
    !loading &&
    !data &&
    validate === null &&
    //@ts-ignore
    location.state === undefined &&
    lite !== undefined
  ) {
    history.push({
      pathname: `/${dataUrl[1]}/confirm`,
      state: { data: '', id: dataUrl[1], number: 0, lite: false },
    });
  }

  if (error || noPoints) {
    return (
      <Alert
        title={message}
        subtitle="Intentalo de nuevo más tarde"
        actions={
          <>
            {/*
          // @ts-ignore TS2589 */}
            <Button onClick={() => history.push(`/guide/${dataUrl[1]}`)} raised>
              Volver
            </Button>

            <Button onClick={() => history.push('/')} mt={2} outlined>
              Rastrear otra guía
            </Button>
          </>
        }
      />
    );
  }

  if (data) {
    return (
      <Layout withPadding={false}>
        <AddressContainer
          data={data}
          edit={edit}
          id={dataUrl[1]}
          lite={lite}
          validate={validateToken}
          infoUrl={location}
          business={business}
          confirm={confirmContainer}
          locationAddress={locationAddress}
          step={step}
          streetNumber={streetNumber}
        />
      </Layout>
    );
  }

  if (loading) {
    return <Placeholder />;
  }
};
