import * as React from 'react';
import { TrackingDetail, Layout } from '../../containers';
import { RouteComponentProps } from 'react-router';

export interface DetailPageParams {
  id: string;
}

export const DetailPage: React.FC<RouteComponentProps<DetailPageParams>> = ({
  match,
  location,
}: RouteComponentProps<DetailPageParams>): JSX.Element => (
  <Layout>
    <TrackingDetail id={match.params.id} infoUrl={location} messenger />
  </Layout>
);
