import * as React from 'react';
import {
  AddressesCard,
  SwipeableAddress,
  ScrollDiv,
  Dialog,
  DialogActions,
  DialogButton,
  DialogTitle,
  DialogContent,
  AppBar,
  CustomPaper,
} from '../../components';
import { MAP_URL, IGEO_URL, IGEO_KEY } from '../../config';
import { MapLeaflet, WrapperTool } from '../../components/Map/style';
import { Marker, TileLayer, Tooltip as TooltipLeaflet } from 'react-leaflet';
import { pointIcon } from '../../components/Map/icons';
import { LatLngExpression } from 'leaflet';
import Search from '@material-ui/icons/Search';
import TouchApp from '@material-ui/icons/TouchApp';
import MyLocation from '@material-ui/icons/MyLocation';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Text,
  WrapperAbsolute,
  WrapperAddress,
  WrapperButtons,
  Box,
  BoxMap,
  SwipeSteps,
  WrapperInput,
  BoxList,
} from './style';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useReactRouter from 'use-react-router';
import { CheckAddressContainer } from '../';
import firebase from 'firebase';

import useDebounce from './useDebounce';

export interface AddressContainerProps {
  data: any;
  edit?: boolean;
  id: string;
  listData?: any;
  addressData?: AddressDataProps;
  header?: any;
  lite?: boolean;
  validate?: boolean;
  infoUrl?: any;
  business?: string;
  confirm?: boolean;
  locationAddress?: any;
  step?: number;
  streetNumber?: string;
}

interface AddressDataProps {
  idAddress: number;
  externalNumber: string;
  internalNumber: string;
  neighborhood: string;
  street: string;
  zipCode: string;
  municipality: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    responsivePhone: {
      width: '100%',
      height: '100%',
    },
    responsiveDesktop: {
      flex: 1,
    },
  }),
);

export function getSteps() {
  return ['Calle y número exterior', 'Completa datos', 'Confirma y envía'];
}

export const AddressContainer: React.FC<AddressContainerProps> = ({
  data,
  edit,
  id,
  lite,
  validate,
  infoUrl,
  business,
  confirm,
  locationAddress,
  step,
  streetNumber,
}: AddressContainerProps): JSX.Element => {
  const { history } = useReactRouter();
  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(() => {
    if (data.address) {
      /* eslint-disable @typescript-eslint/no-use-before-define */
      setMarkerAddress([
        data.address.latitude || '19.429065',
        data.address.longitude || '-99.1607667',
      ]);
    }
  }, []);

  const [markerAddress, setMarkerAddress] = React.useState<LatLngExpression>([
    Number(window.localStorage.getItem('latitude')) || 0,
    Number(window.localStorage.getItem('longitude')) || 0,
  ]);
  const matchesPhone = useMediaQuery('(max-width:780px)');
  const matchesPhoneScroll = useMediaQuery('(max-width:450px)');
  const [marker, setMarker] = React.useState(null);
  const [draggable, setDraggable] = React.useState(null);
  const [directions, setDirections] = React.useState();
  const [correctAddress, setCorrectAddress] = React.useState();
  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [newAddress, setNewAddress] = React.useState();
  const [newAddressData, setNewAddressData] = React.useState();
  const [externalNumber, setExternalNumber] = React.useState(
    streetNumber ? streetNumber : '',
  );
  const [inputAddress, setInputAddress] = React.useState('');
  const [noResult, setNoResult] = React.useState(false);
  const [stepModal, setStepModal] = React.useState(edit && step !== 1);
  const [actualStep, setActualStep] = React.useState(0);
  const [modalMessaage, setModalMessaage] = React.useState([
    'Busca tu domicilio, iniciando por calle y numero exterior.',
    'También podrás configurar tu domicilio en el mapa.',
  ]);
  const [modalTitle, setModalTitle] = React.useState('Ingresa tu dirección');
  const [zoom, setZoom] = React.useState(12);
  const [mapRef, setMapRef] = React.useState();
  const [activeStep, setActiveStep] = React.useState(step ? step : 0);
  const [backdrop, setBackdrop] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const steps = getSteps();
  const api = IGEO_URL;
  const key = IGEO_KEY;
  const classes = useStyles();

  // State and setter for search term
  const [searchTerm, setSearchTerm] = React.useState('');
  // State and setter for search results
  const [results, setResults] = React.useState([]);
  // State for search status (whether there is a pending API request)
  const [isSearching, setIsSearching] = React.useState(false);

  // Now we call our hook, passing in the current searchTerm value.
  // The hook will only return the latest value (what we passed in) ...
  // ... if it's been more than 500ms since it was last called.
  // Otherwise, it will return the previous value of searchTerm.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  if (correctAddress && actualStep === 0 && step !== 1) {
    setActualStep(1);
    setStepModal(true);
    setModalTitle('Información adicional');
    setModalMessaage([
      'Ingresa referencias de tu domicilio (Descripción de la fachada, entre calles, accesos de seguridad, etc).',
      'El numero interior es opcional',
    ]);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    if (!correctAddress) {
      firebase.analytics().logEvent('click_confirm_button', { id });
    } else {
      firebase.analytics().logEvent('page_confirm', { id });
    }
  }, [correctAddress]);

  function saveMarker(map: any) {
    setMarker(map);
  }

  if (confirm && correctAddress === undefined) {
    //@ts-ignore
    setCorrectAddress(true);
    setNewAddress(data);
  }

  function updatePosition() {
    firebase.analytics().logEvent('on_drag_map', { id });
    if (marker !== null) {
      const latitudeMarker = marker.leafletElement.getLatLng().lat;
      const longitudeMarker = marker.leafletElement.getLatLng().lng;

      window.localStorage.setItem('latitude', latitudeMarker);
      window.localStorage.setItem('longitude', longitudeMarker);

      setMarkerAddress([latitudeMarker, longitudeMarker]);
      console.log(newAddress);

      //@ts-ignore
      newAddress.latitude = latitudeMarker;
      //@ts-ignore
      newAddress.longitude = longitudeMarker;
      setNewAddress(newAddress);
      //queryReverseGeocode(latitudeMarker, longitudeMarker);
    }
  }

  function toggleDraggable() {
    setDraggable(!draggable);
  }

  async function getLatLngFromAddress(value: any) {
    setProgress(true);
    //setInputAddress(value);
    // const dataFetchSearch = `query {\n  search(address: "${value}") {\n    output\n  }\n}\n`;
    const dataFetchSearch = `query {\n autocomplete(address: "${value}") {\n id\n country\n state\n city\n zipcode\n neighborhood\n street\n exterior_identifier\n longitude\n latitude\n source\n }\n}`;
    /* eslint-disable @typescript-eslint/no-use-before-define */
    fetchDataGeo(dataFetchSearch, 'search');
    if (value === '') {
      // @ts-ignore
      setDirections([]);
    }
  }

  function selectedAddress(value: string) {
    setBackdrop(true);
    setDirections(null);
    const dataFetchGeocode = `query {\n  geocode(address: "${value}") {\n    address\n    area\n    country\n    cp\n    latitude\n    longitude\n    locality\n    route\n    streetNumber\n    sublocality\n  }\n}`;
    /* eslint-disable @typescript-eslint/no-use-before-define */
    fetchDataGeo(dataFetchGeocode, 'geocode');
  }

  if (lite === false) {
    if (data.businessType) {
      if (data.businessType.name === 'SAMEDAY' && !newAddressData) {
        setNewAddressData(data.points[1]);
        setMarkerAddress([
          data.points[1].addresses[0].latitude,
          data.points[1].addresses[0].longitude,
        ]);
      } else if (data.businessType.name === 'EXPRESS' && !newAddressData) {
        setNewAddressData(data.points[0]);
        setMarkerAddress([
          data.points[0].addresses[0].latitude,
          data.points[0].addresses[0].longitude,
        ]);
      }
    }
  }

  function queryReverseGeocode(latitude: number, longitude: number) {
    const dataFetchReverse = `query {\n  reverseGeocode(latitude: ${latitude}, longitude: ${longitude}) {\n    id\n    address\n    area\n    streetNumber\n    locality\n    condensed\n    route\n   cp\n    sublocality\n  }\n}`;
    fetchDataGeo(dataFetchReverse, 'reverseGeocode');
  }

  React.useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        // Set isSearching state
        setIsSearching(true);
        setBackdrop(true);
        setProgress(true);
        // Fire off our API call

        const dataFetchSearch = `query {\n autocomplete(address: "${debouncedSearchTerm}") {\n id\n country\n state\n city\n zipcode\n neighborhood\n street\n exterior_identifier\n longitude\n latitude\n source\n }\n}`;
        axios
          .post(
            api,
            {
              query: dataFetchSearch,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                key,
              },
            },
          )
          .then((response: any) => {
            const { data } = response.data;
            if (data.autocomplete) {
              setIsSearching(false);
              setProgress(false);
              setBackdrop(false);
              setDirections(data.autocomplete);
              if (data.autocomplete.length === 0) {
                setError(true);
              }
            }
          })
          .catch((err: any) => {
            console.log(err);

            setError(true);
            setIsSearching(false);
            setBackdrop(false);
            setProgress(false);

            firebase
              .analytics()
              .logEvent('igeo_catch', { guide: id, type: origin });
          });
      } else {
        setResults([]);
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm],
  );

  function fetchDataGeo(dataFetch: string, origin: string) {
    axios
      .post(
        api,
        {
          query: dataFetch,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            key,
          },
        },
      )
      .then((response: any) => {
        const { data } = response.data;
        if (data.autocomplete) {
          setProgress(false);
          setDirections(data.autocomplete);
          if (data.autocomplete.length === 0) {
            setError(true);
          }
        }
        /*if (origin === 'search' && data !== null) {
          setProgress(false);
          setDirections(data.search);
          if (data.search.length === 0) {
            setError(true);
          }
        } else if (origin === 'geocode') {
          setBackdrop(false);
          if (data.geocode[0]) {
            setMarkerAddress([
              data.geocode[0].latitude,
              data.geocode[0].longitude,
            ]);
            setCompleted(true);
            setNewAddress(data.geocode[0]);
            setExternalNumber(data.geocode[0].streetNumber);
          } else {
            setNoResult(true);
            setModalTitle('Alerta');
            setModalMessaage(['Selecciona una dirección completa', '']);
            setInputAddress('');
          }
        } else if (origin === 'reverseGeocode') {
          if (data.reverseGeocode[0]) {
            setNewAddress(data.reverseGeocode[0]);
            setExternalNumber(data.reverseGeocode[0].streetNumber);
            setCompleted(true);
          } else {
            setNoResult(true);
            setModalTitle('Alerta');
            setModalMessaage(['Selecciona una dirección completa', '']);
            setInputAddress('');
          }
          if (data.reverseGeocode[0].length === 0) {
            setError(true);
          }
        }*/
      })
      .catch((err: any) => {
        console.log(err);
        setError(true);
        setBackdrop(false);
        setProgress(false);

        firebase
          .analytics()
          .logEvent('igeo_catch', { guide: id, type: origin });
      });
  }

  function getLatLng() {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitudeLocation = position.coords.latitude;
      const longitudeLocation = position.coords.longitude;
      setMarkerAddress([latitudeLocation, longitudeLocation]);
      queryReverseGeocode(latitudeLocation, longitudeLocation);
    });
  }

  if (zoom === 12) {
    setTimeout(() => {
      setZoom(16);
    }, 1000);
  }

  function StepperComponent() {
    return (
      <SwipeSteps>
        <Stepper
          style={{ backgroundColor: 'transparent', paddingBottom: 5 }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel> </StepLabel>
            </Step>
          ))}
        </Stepper>
      </SwipeSteps>
    );
  }

  function validateElement() {
    // @ts-ignore
    if (mapRef.leafletElement._zoom) {
      // @ts-ignore
      setZoom(mapRef.leafletElement._zoom);
    }
  }

  function selectedAddressAutocomplete(address: any) {
    setMarkerAddress([address.latitude, address.longitude]);
    window.localStorage.setItem('latitude', address.latitude);
    window.localStorage.setItem('longitude', address.longitude);
    setCompleted(true);
    setNewAddress(address);
    setExternalNumber(address.exterior_identifier);
    //@ts-ignore
    setDirections();
  }

  return (
    <CustomPaper
      style={{ marginTop: !matchesPhone ? '13%' : 0, marginBottom: '5%' }}
    >
      {!matchesPhone && (
        <AppBar
          title="Información de tu paquete"
          iconClick={() => history.push('/')}
          guide={id}
        />
      )}
      <Backdrop
        style={{ zIndex: 4000 }}
        open={backdrop}
        onClick={() => setBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {completed && !correctAddress && (
        <WrapperAbsolute
          style={{
            right: !matchesPhone && '60%',
            left: !matchesPhone && '27%',
            top: !matchesPhone ? 210 : 80,
          }}
        >
          <Text fontSize="15px" fontWeight="semi-bold" color="white">
            Define tu ubicación
          </Text>
        </WrapperAbsolute>
      )}
      {correctAddress ? (
        <CheckAddressContainer
          headerAction={() => {
            // @ts-ignore
            setCorrectAddress(false);
            handleBack();
          }}
          data={newAddress}
          streetNumber={externalNumber}
          location={markerAddress}
          lite={lite}
          infoUrl={infoUrl}
          business={business}
          steps={StepperComponent()}
          responsive={matchesPhone}
        />
      ) : (
        <BoxMap>
          {edit && StepperComponent()}
          <Box flex={1} style={{ display: 'flex', flexDirection: 'row' }}>
            <MapLeaflet
              // @ts-ignore
              ref={(ref: any) => setMapRef(ref)}
              center={[markerAddress[0] - 0.0025, markerAddress[1]]}
              zoom={zoom}
              onzoomend={() => validateElement()}
              dragging={edit}
              style={{
                position: matchesPhone ? 'absolute' : 'static',
                width: matchesPhone ? '100%' : '50%',
              }}
            >
              <TileLayer
                url={String(MAP_URL)}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              {newAddress && (
                <Marker
                  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
                  //@ts-ignore
                  icon={pointIcon}
                  draggable={newAddress}
                  onDragend={() => updatePosition()}
                  position={markerAddress}
                  ref={(refMarker) => saveMarker(refMarker)}
                  ondragstart={() => toggleDraggable()}
                >
                  <TooltipLeaflet direction="right" permanent>
                    <WrapperTool>Tu domicilio</WrapperTool>
                  </TooltipLeaflet>
                </Marker>
              )}
            </MapLeaflet>
            <Box flex={1}>
              {edit ? (
                <SwipeableAddress
                  error={error && !completed}
                  guide={id}
                  header={
                    !completed ? (
                      <WrapperInput>
                        <Search color="primary" />
                        <InputBase
                          //disabled={isSearching}
                          readOnly={isSearching}
                          autoFocus={true}
                          className={classes.input}
                          placeholder="Busca tu calle y número exterior..."
                          inputProps={{ 'aria-label': 'search google maps' }}
                          onChange={(event) =>
                            setSearchTerm(event.target.value)
                          }
                        />
                        {/*<Tooltip
                          onClick={() => getLatLng()}
                          title="Mi ubicación actual"
                        >
                          <MyLocation color="primary" />
                        </Tooltip>*/}
                      </WrapperInput>
                    ) : (
                      <WrapperAddress>
                        <Text
                          fontSize="18px"
                          fontWeight="semi-bold"
                          color="purple"
                          marginBottom="2"
                        >
                          ¿Está bien tu dirección?
                        </Text>
                        <BoxList>
                          <TextField
                            size="small"
                            label="Calle"
                            // @ts-ignore
                            value={newAddress && newAddress.street}
                            style={{ paddingRight: 10 }}
                            fullWidth
                            disabled
                          />
                          <TextField
                            error={!externalNumber || externalNumber === '0'}
                            size="small"
                            label={
                              !externalNumber || externalNumber === '0'
                                ? 'Ingrese el número exterior'
                                : 'Número exterior'
                            }
                            value={externalNumber}
                            fullWidth
                            onChange={(e) => setExternalNumber(e.target.value)}
                          />
                        </BoxList>
                        <WrapperButtons>
                          <Button
                            variant="outlined"
                            onClick={() => setCompleted(false)}
                            color="secondary"
                            fullWidth
                            style={{ marginRight: 10 }}
                          >
                            Buscar
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              // @ts-ignore
                              setCorrectAddress(true);
                              handleNext();
                            }}
                            color="primary"
                            disabled={!externalNumber || externalNumber === '0'}
                            fullWidth
                          >
                            Continuar
                          </Button>
                        </WrapperButtons>
                      </WrapperAddress>
                    )
                  }
                  completed={completed}
                  visible={false}
                >
                  {directions && (
                    <ScrollDiv
                      isAddress={!matchesPhoneScroll}
                      heightResponsive={300}
                    >
                      {progress && (
                        <BoxList style={{ justifyContent: 'center' }}>
                          <CircularProgress />
                        </BoxList>
                      )}

                      {
                        // @ts-ignore
                        directions.map((addresses: any) => {
                          if (
                            addresses.street &&
                            addresses.exterior_identifier
                          ) {
                            return (
                              <BoxList key={addresses.id}>
                                <TouchApp color="primary" />
                                <ListItem
                                  divider
                                  button
                                  key={addresses.street}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    selectedAddressAutocomplete(addresses)
                                  }
                                >
                                  <ListItemText
                                    secondary={
                                      addresses.street +
                                      ' ' +
                                      addresses.exterior_identifier +
                                      ' ' +
                                      addresses.neighborhood +
                                      ' ' +
                                      addresses.zipcode
                                    }
                                  />
                                </ListItem>
                              </BoxList>
                            );
                          }
                        })
                      }
                    </ScrollDiv>
                  )}
                </SwipeableAddress>
              ) : (
                <AddressesCard
                  data={data}
                  id={id}
                  edit={edit}
                  validate={validate}
                  addressData={
                    newAddressData
                      ? // @ts-ignore
                        newAddressData.addresses[0].addressObject
                      : data.address
                  }
                  business={business}
                />
              )}
            </Box>
          </Box>
        </BoxMap>
      )}
      <Dialog open={noResult || stepModal} style={{ zIndex: 2700 }}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <Box>{modalMessaage[0]}</Box>
          {modalMessaage[1]}
        </DialogContent>
        <DialogActions>
          <DialogButton
            action="close"
            onClick={() => {
              setNoResult(false);
              setStepModal(false);
            }}
            isDefaultAction
          >
            Continuar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </CustomPaper>
  );
};
