import * as React from 'react';
import { ButtonHelp, ButtonHelpHighLight, WrapperButtons } from './style';
import useReactRouter from 'use-react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

interface ButtonsHelpProps {
  id: string;
  data: any;
  backupUrl?: boolean;
  business?: string;
}

export const ButtonsHelp: React.FC<ButtonsHelpProps> = ({
  id,
  data,
  backupUrl,
  business,
}: ButtonsHelpProps): JSX.Element => {
  const { history } = useReactRouter();
  const matchesPhone = useMediaQuery('(max-width:770px)');

  function onClickHelp() {
    if (backupUrl) {
      history.push({
        pathname: `/${id}/help`,
        state: { data, lite: true, business },
      });
    } else {
      history.push({ pathname: `/${id}/help`, state: { data, business } });
    }
  }

  function onClickGoBack() {
    history.push(`/`);
  }

  return (
    <WrapperButtons
      style={{
        position: matchesPhone ? 'absolute' : 'relative',
        bottom: matchesPhone && 0,
        left: matchesPhone && 0,
        right: matchesPhone && 0,
      }}
    >
      <ButtonHelp onClick={() => onClickHelp()}>Necesito Ayuda</ButtonHelp>
      <ButtonHelpHighLight onClick={() => onClickGoBack()}>
        Buscar otra guía
      </ButtonHelpHighLight>
    </WrapperButtons>
  );
};
