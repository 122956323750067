import * as React from 'react';

// Apollo boost
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createBrowserHistory } from 'history';
// Themes
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import { Normalize } from 'styled-normalize';
import { ThemeProvider as MaterialThemeProvider } from '@rmwc/theme';
import '@material/theme/dist/mdc.theme.css';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

// Router
import { Router, Route } from 'react-router';

// Config

import { API_URL, OPTIMIZELY_KEY, DOMAIN_URL } from './config';

// Theme
import theme from './theme';
import themeShein from './theme/themeShein';

// Pages
import {
  HomePage,
  DetailPage,
  HelpPage,
  ConfirmIdentityPage,
  AddressPage,
  CodeConfirmPage,
  GeneralHelpPage,
  TrackingLitePage,
  ExternalTrackingPage,
  SaveAddressPage,
} from './pages';

// Client schema
import clientSchema from './graphql/client-schema';
import * as resolvers from './graphql/resolvers';

// Config Apollo
/*const wsLink = new WebSocketLink({
  uri: String(WS_API_URL),
  options: {
    reconnect: true,
  },
});*/
const httpLink = new HttpLink({
  uri: String(API_URL),
  headers: {
    role: 'admin',
  },
});

const history = createBrowserHistory();

const link = split(
  // split based on operation type
  ({ query }): boolean => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  typeDefs: clientSchema,
  resolvers,
});

// Global style
const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  #root {
    background-color: #fafafa;
    height: auto !important; /* real browsers */
    height: 100%; /* IE6: treaded as min-height*/
    min-height: 100%; /* real browsers */
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: black;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-top: 5px;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;
const App: React.FC = (): JSX.Element => {
  const matchesPhone = useMediaQuery('(max-width:600px)');
  const userId = localStorage.getItem('userId');
  const optimizely = createInstance({
    sdkKey: OPTIMIZELY_KEY,
  });
  const attributes = {
    userId,
    clientId: 7600,
  };
  const generalCount = localStorage.getItem('generalCount');
  const dataUrl = window.location.host;
  dataUrl.replace('www.', '');

  const domainType = dataUrl === DOMAIN_URL ? 'shein' : 'ivoy';
  localStorage.setItem('domain', domainType);

  if (!generalCount) {
    localStorage.setItem('generalCount', null);
  }
  if (generalCount === null) {
    localStorage.removeItem('count');
    localStorage.removeItem('countConfirm');
    localStorage.removeItem('countCode');
  }
  return (
    /* eslint-disable @typescript-eslint/ban-ts-ignore */
    //@ts-ignore
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: userId,
        attributes,
      }}
    >
      <ApolloProvider client={client}>
        <div
          style={{
            height: '100vh',
            width: '100%',
            background: 'white',
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            style={{ height: matchesPhone ? '100%' : '80%' }}
          >
            <Router history={history}>
              <StyledThemeProvider
                theme={domainType === 'ivoy' ? theme : themeShein}
              >
                <MaterialThemeProvider
                  options={{
                    primary:
                      domainType === 'ivoy'
                        ? theme.colors.primary
                        : themeShein.colors.primary,
                    secondary:
                      domainType === 'ivoy'
                        ? theme.colors.secondary
                        : themeShein.colors.secondary,
                  }}
                >
                  <Normalize />
                  <GlobalStyle />
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/:id/" component={DetailPage} />
                  <Route exact path="/:id/help" component={HelpPage} />
                  <Route exact path="/:id/address" component={AddressPage} />
                  <Route
                    exact
                    path="/:id/confirm"
                    component={ConfirmIdentityPage}
                  />
                  <Route exact path="/:id/code" component={CodeConfirmPage} />
                  <Route
                    exact
                    path="/:id/general-help"
                    component={GeneralHelpPage}
                  />
                  <Route exact path="/order/:id" component={DetailPage} />
                  <Route exact path="/guide/:id" component={TrackingLitePage} />
                  <Route
                    exact
                    path="/:id/address/:token"
                    component={AddressPage}
                  />
                  <Route
                    exact
                    path="/external/:clientId/:reference"
                    component={ExternalTrackingPage}
                  />
                  <Route
                    exact
                    path="/:id/confirmAddress"
                    component={SaveAddressPage}
                  />
                </MaterialThemeProvider>
              </StyledThemeProvider>
            </Router>
          </Grid>
        </div>
      </ApolloProvider>
    </OptimizelyProvider>
  );
};

export default App;
