import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Text } from 'rebass/styled-components';
import Chip from '@material-ui/core/Chip';

export const MainContainer = styled.div`
  left: 0;
  bottom: 0;
  right: 0;
  padding: 7%;
  background-color: ${theme('colors.background')};
  z-index: 1000;
  transition: 0.3s;
  font-family: Roboto, sans-serif;
`;

export const Title = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 2%;
`;

export const WrapperData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const WrapperRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const WrapperColumn = styled.div`
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

export const Subtitle = styled(Text)`
  color: ${theme('colors.gray')};
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: bold;
`;

export const ValueSubtitle = styled(Text)`
  color: ${theme('colors.dustyGray')};
  font-size: 15px;
  text-transform: uppercase;
`;

export const FullSizeChip = styled(Chip)`
  width: 90%;
`;
