import * as React from 'react';
import {
  Button,
  CardNotification,
  Container,
  Header,
  ItemsList,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Alert,
  Placeholder,
  AppBar,
} from '../../components';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import { Box } from '@material-ui/core';
import axios from 'axios';
import { GetTimelineDocument } from '../../graphql/generated';
import { useApolloClient } from 'react-apollo';
import {
  CLIENT_URL,
  GOOGLE_INTEGRATION_KEY,
  GOOGLE_INTEGRATION_URL,
} from '../../config';
import { items } from '../../containers/HelpLiteContainer/items';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import firebase from 'firebase';

export const ConfirmIdentityPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const { history } = useReactRouter();
  const [number, setNumber] = React.useState();
  const [typeCode, setTypeCode] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  const client = useApolloClient();
  const [error, setError] = React.useState(false);
  const [newData, setNewData] = React.useState();
  const [numberComplete, setNumberComplete] = React.useState();
  const [email, setEmail] = React.useState();
  const [emailComplete, setEmailComplete] = React.useState();
  const [noMethods, setNoMethods] = React.useState(false);
  const [lite, setLite] = React.useState();
  const [loadingData, setLoadingData] = React.useState();
  const [business, setBusiness] = React.useState();
  const [alternFlow, setAlternFlow] = React.useState(false);
  const [generalCount, setGeneralCount] = React.useState();
  const dataUrl = location.pathname.split('/');
  const countConfirm = localStorage.getItem('countConfirm');
  const parseCount = parseInt(countConfirm);

  if (location.state !== undefined && newData === undefined) {
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    //@ts-ignore
    const { data, lite, business } = location.state;
    setNewData(data);
    setLite(lite);
    setBusiness(business);
  }

  React.useEffect(() => {
    const generalCount = localStorage.setItem('generalCount', 'true');
    // @ts-ignore
    setGeneralCount(generalCount);
    return () => {
      localStorage.setItem('generalCount', undefined);
    };
  }, [generalCount]);

  function handleItemList(type: string) {
    // @ts-ignore
    setTypeCode(type);
  }

  async function search() {
    const payload = await client.query({
      query: GetTimelineDocument,
      variables: { id: dataUrl[1] },
    });
    return payload;
  }

  if (
    (newData === '' || newData === undefined) &&
    (number === 0 || number === undefined)
  ) {
    search()
      .then((resolve) => {
        setNewData(resolve.data.tracking);
      })
      .catch(() => {
        setError(true);
      });
  }

  if (
    newData !== undefined &&
    (number !== undefined || email !== undefined) &&
    !loadingData
  ) {
    // @ts-ignore
    setLoadingData(true);
  } else if (newData === '' && !number && loadingData === undefined) {
    // @ts-ignore
    setLoadingData(false);
  }

  if (newData !== undefined && newData !== '') {
    // @ts-ignore
    const phoneData = newData.contact.phone;
    // @ts-ignore
    const emailData = newData.contact.email;
    if (lite && number === undefined) {
      if (
        (phoneData === null ||
          phoneData === undefined ||
          phoneData.length < 6) &&
        (emailData === null ||
          emailData === undefined ||
          emailData.length < 6) &&
        !noMethods
      ) {
        setNoMethods(true);
      }
      if (
        phoneData !== null &&
        phoneData !== undefined &&
        phoneData.length > 6 &&
        number === undefined
      ) {
        setNumber(phoneData.substr(-4));
        setNumberComplete(phoneData);
      }
      if (
        emailData !== null &&
        emailData !== undefined &&
        emailData.length > 6 &&
        email === undefined
      ) {
        setEmail(emailData.split('@'));
        setEmailComplete(emailData);
      }
    }

    if (number === undefined && lite === false) {
      // @ts-ignore
      setNumber(newData.points[1].receivers[0].phone.substr(-4));
      // @ts-ignore
      if (newData.points[1].receivers[0].email) {
        // @ts-ignore
        setEmail(newData.points[1].receivers[0].email.split('@'));
        // @ts-ignore
        setEmailComplete(newData.points[1].receivers[0].email);
      } else {
        // @ts-ignore
        setEmail('');
        // @ts-ignore
        setEmailComplete('');
      }
      // @ts-ignore
      setNumberComplete(newData.points[1].receivers[0].phone);
    }
  }

  if (emailComplete && numberComplete) {
    if (
      // @ts-ignore
      (emailComplete === '' || emailComplete.length < 7) &&
      // @ts-ignore
      (numberComplete === '' || numberComplete.length < 7)
    ) {
      setNoMethods(true);
    }
  }

  function sendMsj() {
    setLoading(true);
    if (lite) {
      const queryCreateToken = `mutation {\n  createToken(guide: "${dataUrl[1]}", type: "${typeCode}") {\n    guide\n    status\n  }\n}\n`;
      const config = {
        headers: {
          'x-api-key': GOOGLE_INTEGRATION_KEY,
        },
      };
      axios
        .post(
          GOOGLE_INTEGRATION_URL,
          {
            query: queryCreateToken,
          },
          config,
        )
        .then((res) => {
          const { createToken } = res.data.data;
          if (createToken !== null) {
            history.push({
              pathname: `/${dataUrl[1]}/code`,
              state: {
                data: newData,
                id: dataUrl[1],
                method:
                  typeCode === 'email'
                    ? // @ts-ignore
                      `${email[0].substring(0, 2)}xxxxxx@${email[1]}`
                    : `+(52) xxxxx - ${number}`,
                lite: true,
                business,
              },
            });
          } else {
            setLoading(false);
            setShowDialog(true);
            if (parseCount >= 2 && !alternFlow) {
              setTimeout(() => {
                setAlternFlow(true);
              }, 2000);
            }
            localStorage.setItem(
              'countConfirm',
              countConfirm ? `${parseCount + 1}` : '1',
            );
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setShowDialog(true);
          if (parseCount >= 2 && !alternFlow) {
            setTimeout(() => {
              setAlternFlow(true);
            }, 2000);
          }
          localStorage.setItem(
            'countConfirm',
            countConfirm ? `${parseCount + 1}` : '1',
          );

          firebase
            .analytics()
            .logEvent('create_token_catch', { guide: dataUrl[1] });
        });
    } else {
      const data = {
        id: dataUrl[1],
        type: typeCode,
        phone: numberComplete,
        email: emailComplete,
      };
      axios({
        method: 'post',
        url: `${CLIENT_URL}send-code`,
        data,
      })
        .then((response) => {
          if (response.status === 200) {
            history.push({
              pathname: `/${dataUrl[1]}/code`,
              state: {
                data: newData,
                id: dataUrl[1],
                number,
                lite: false,
                business,
              },
            });
          } else {
            setShowDialog(true);
          }
        })
        .catch((err) => {
          setShowDialog(true);
        });
    }
  }

  const matchesPhone = useMediaQuery('(max-width:780px)');

  if (loadingData || noMethods) {
    return (
      <>
        {!matchesPhone && (
          <AppBar
            title="Información de tu paquete"
            iconClick={() => history.push('/')}
            guide={dataUrl[1]}
          />
        )}
        <Container header={<Header text="Confirmar identidad" icon />}>
          <CardNotification title="Por la seguridad de tu información debemos verificar tu identidad" />
          {emailComplete && (
            <ItemsList
              // @ts-ignore
              item={`Recibir código via email a ${email[0].substring(
                0,
                2,
                // @ts-ignore
              )}xxxxxx@${email[1]}`}
              action={() => handleItemList('email')}
              color={typeCode === 'email' ? '#C4C4C4' : ''}
            />
          )}
          {numberComplete && (
            <ItemsList
              item={`Recibir código via SMS en +(52) xxxxx - ${number}`}
              action={() => handleItemList('sms')}
              color={typeCode === 'sms' ? '#C4C4C4' : ''}
            />
          )}
          {noMethods && (
            <ItemsList
              item="No es posible validar tu identidad, pica aquí"
              action={() =>
                history.push({
                  pathname: `/${dataUrl[1]}/general-help`,
                  state: {
                    lite: true,
                    idGuide: dataUrl[1],
                    business: 'SD',
                    ...items[0],
                  },
                })
              }
              color={typeCode === 'sms' ? '#C4C4C4' : ''}
            />
          )}
          <Box css={{ padding: 20, marginTop: '30%' }}>
            {/*
              // @ts-ignore TS2589 */}
            <Button
              onClick={() => sendMsj()}
              label={loading ? 'Cargando...' : 'Continuar'}
              py="14px"
              height="auto"
              disabled={typeCode === undefined || loading}
              raised
            />
          </Box>
          <Dialog open={alternFlow} onClose={(): void => setAlternFlow(false)}>
            <DialogTitle>Alerta</DialogTitle>
            <DialogContent>
              Vemos que has intentado validar el problema que tuviste...
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="close"
                onClick={() => setAlternFlow(false)}
                isDefaultAction
              >
                Volver
              </DialogButton>
              <DialogButton
                action="close"
                onClick={() => {
                  localStorage.setItem('generalCount', null);
                  history.push({
                    pathname: `/${dataUrl[2]}/general-help`,
                    state: {
                      lite: true,
                      idGuide: dataUrl[2],
                      business: 'SD',
                      ...items['SD'][0],
                    },
                  });
                }}
                isDefaultAction
              >
                Flujo alterno
              </DialogButton>
            </DialogActions>
          </Dialog>
          <Dialog open={showDialog} onClose={(): void => setShowDialog(false)}>
            <DialogTitle>Disculpanos</DialogTitle>
            <DialogContent>
              No ha sido posible contactarnos contigo, intentalo más tarde
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="close"
                onClick={() => setLoading(false)}
                isDefaultAction
              >
                Volver
              </DialogButton>
            </DialogActions>
          </Dialog>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <Alert
        title={'No se ha podido enviar el código'}
        subtitle="Intentalo de nuevo más tarde"
        actions={
          <>
            {/*
            // @ts-ignore TS2589 */}
            <Button onClick={(): void => history.push(`/${dataUrl[1]}`)} raised>
              Volver
            </Button>

            <Button onClick={(): void => history.push('/')} mt={2} outlined>
              Rastrear otra guía
            </Button>
          </>
        }
      />
    );
  }

  return <Placeholder />;
};
