import styled from 'styled-components';
import { theme } from 'styled-tools';

export { Text } from 'rebass/styled-components';

export const Container = styled.div`
  border-bottom: 1px solid ${theme('colors.alto')};
  padding: 12px;
  padding-left: 25px;
  background-color: ${theme('colors.background')};
  transition: 0.3s;
  font-family: Roboto, sans-serif;
  &:hover {
    background-color: ${theme('colors.alto')};
  }
`;
