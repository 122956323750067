import React from 'react';
import { AppBarComponent, Icon } from './style';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Box } from 'rebass/styled-components';
import Typography from '@material-ui/core/Typography';
import Logo from '../../images/logo.svg';

interface AppBarProps {
  iconClick: any;
  guide: string;
  title: string;
}

export const AppBar: React.FC<AppBarProps> = ({
  iconClick,
  guide,
  title,
}: AppBarProps): JSX.Element => (
  <AppBarComponent position="static">
    <Toolbar>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={iconClick}
      >
        <Icon src={Logo} />
      </IconButton>
      <Box>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ lineHeight: 1 }}
        >
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          No. {guide}
        </Typography>
      </Box>
    </Toolbar>
  </AppBarComponent>
);
