import * as React from 'react';
import {
  Container,
  PictureContainer,
  ContainerText,
  Title,
  SubTitle,
} from './style';

export interface MessengerProps {
  title: string;
  name: string | any;
  img: string;
}

export const Messenger: React.FC<MessengerProps> = ({
  title,
  name,
  img,
}: MessengerProps): JSX.Element => {
  return (
    <Container>
      <PictureContainer src={img} />
      <ContainerText>
        <Title>{title}</Title>
        <SubTitle>{name}</SubTitle>
      </ContainerText>
    </Container>
  );
};
