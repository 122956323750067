import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Box } from 'rebass/styled-components';
export { Text, Box } from 'rebass/styled-components';

export const WrapperAbsolute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 30%;
  right: 30%;
  height: 25px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0.5px solid ${theme('colors.primary')};
  z-index: 1900;
  transition: 0.3s;
`;

export const WrapperAddress = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const BoxMap = styled(Box)`
  flex: 1;
  display: flex;
  height: 80vh;
  flex-direction: column;
  background-color: ${theme('colors.background')};
`;

export const SwipeSteps = styled.div`
  background-color: white;
  z-index: 1800;
  max-height: 15%;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.5s;
  width: 100%;
`;

export const WrapperInput = styled.div`
  width: 100%;
  padding: 3%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
`;

export const BoxList = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
