import * as React from 'react';
import {
  Button,
  Input,
  Box,
  DialogContent,
  DialogActions,
  DialogButton,
} from '../../components';
import {
  Subtitle,
  WrapperColumn,
  WrapperRow,
  BackFloat,
  ContainerHeader,
} from './style';
import useReactRouter from 'use-react-router';
import { LatLngExpression } from 'leaflet';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import logo from '../../images/backArrow.png';
import firebase from 'firebase';

interface CheckAddressProps {
  headerAction: any;
  data: any;
  location: LatLngExpression;
  lite: boolean;
  infoUrl: any;
  business: string;
  steps: React.ReactNode;
  responsive: boolean;
  streetNumber: any;
}

export const CheckAddressContainer: React.FC<CheckAddressProps> = ({
  headerAction,
  data,
  location,
  lite,
  infoUrl,
  business,
  steps,
  responsive,
  streetNumber,
}: CheckAddressProps): JSX.Element => {
  const { history } = useReactRouter();
  const dataUrl = infoUrl.pathname.split('/');
  const id = dataUrl[1];
  const [open, setOpen] = React.useState(false);
  const [routeValue, setRouteValue] = React.useState(data && data.street);
  const [subLocalityValue, setSubLocalityValue] = React.useState(
    data ? data.neighborhood : '',
  );
  const [localityValue, setLocalityValue] = React.useState(
    data ? data.state : '',
  );
  const [cpValue, setCpValue] = React.useState(data ? data.zipcode : '');
  const [streetNumberValue, setStreetNumberValue] = React.useState(
    streetNumber ? streetNumber : '',
  );
  const [intNumberValue, setIntNumberValue] = React.useState(
    data ? data.intNumber : '',
  );
  const [referenceValue, setReferenceValue] = React.useState(
    data.reference ? data.reference : '',
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ContainerHeader>
        <BackFloat
          style={{ left: !responsive ? '18%' : '3%' }}
          src={logo}
          onClick={headerAction}
        />
        {steps}
      </ContainerHeader>
      <Box paddingLeft="5%" paddingRight="5%" backgroundColor="white">
        <WrapperColumn>
          <WrapperColumn>
            <Subtitle>Colonia</Subtitle>
            <Input
              name="sublocality"
              value={subLocalityValue}
              /*
              //@ts-ignore*/
              onChange={(e) => setSubLocalityValue(e.target.value)}
              disabled
            />
          </WrapperColumn>
          <WrapperColumn>
            <Subtitle>Calle</Subtitle>
            <Input
              name="route"
              value={routeValue}
              /*
              //@ts-ignore*/
              onChange={(e) => setRouteValue(e.target.value)}
              disabled
            />
          </WrapperColumn>
          <WrapperColumn>
            <Subtitle>Municipio</Subtitle>
            <Input
              name="locality"
              value={localityValue}
              /*
              //@ts-ignore*/
              onChange={(e) => setLocalityValue(e.target.value)}
              disabled
            />
          </WrapperColumn>
          <WrapperRow>
            <WrapperColumn>
              <Subtitle>Número Exterior</Subtitle>
              <Input
                name="streetNumber"
                value={streetNumberValue}
                /*
                //@ts-ignore*/
                onChange={(e) => setStreetNumberValue(e.target.value)}
                disabled
              />
            </WrapperColumn>
            <WrapperColumn>
              <Subtitle>Número Interior</Subtitle>
              <Input
                name="intNumber"
                value={intNumberValue}
                /*
                //@ts-ignore*/
                onChange={(e) => setIntNumberValue(e.target.value)}
              />
            </WrapperColumn>
          </WrapperRow>
          <WrapperColumn>
            <Subtitle>Código postal</Subtitle>
            <Input
              name="cp"
              value={cpValue}
              /*
              //@ts-ignore*/
              onChange={(e) => setCpValue(e.target.value)}
            />
          </WrapperColumn>
          <WrapperColumn>
            <Subtitle>Referencia</Subtitle>
            <Input
              name="reference"
              value={referenceValue}
              /*
              //@ts-ignore*/
              onChange={(e) => setReferenceValue(e.target.value)}
            />
          </WrapperColumn>
          {/*
              //@ts-ignore*/}
          <Button
            onClick={() => {
              referenceValue.trim().length > 5
                ? history.push(`/${id}/confirmAddress`, {
                    data: {
                      area: data.city,
                      localityValue,
                      subLocalityValue,
                      routeValue,
                      streetNumberValue,
                      intNumberValue,
                      cpValue,
                      referenceValue,
                      location,
                      lite,
                    },
                    business,
                  })
                : setOpen(true);
            }}
            label="Continuar"
            mt="10px"
            mb="10px"
            py="14px"
            height="auto"
            raised
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2000,
            }}
          >
            <Fade in={open}>
              <Box backgroundColor="white">
                <DialogContent>Ingrese el campo "Referencia"</DialogContent>
                <DialogActions>
                  <DialogButton
                    action="close"
                    onClick={() => {
                      firebase
                        .analytics()
                        .logEvent('page_confirm_button', { id });
                      setOpen(false);
                    }}
                    isDefaultAction
                  >
                    Continuar
                  </DialogButton>
                </DialogActions>
              </Box>
            </Fade>
          </Modal>
        </WrapperColumn>
      </Box>
    </>
  );
};
