import * as React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { ApproxTime, DivTimeText, PrincipalTimeText, Pointer } from './style';
import L from 'leaflet';

export interface PopUpMarkerProps {
  position: any;
  text: string;
  time: number;
  icon: any;
}
L.Popup.mergeOptions({
  className: 'PopUpStyle',
});
export const PopUpMarker: React.FC<PopUpMarkerProps> = ({
  position,
  text,
  time,
  icon,
}: PopUpMarkerProps): JSX.Element => {
  return (
    <Marker position={position} icon={icon}>
      {text ? (
        <Popup>
          <Pointer />
          <ApproxTime>
            <PrincipalTimeText>{time}</PrincipalTimeText>
            <DivTimeText>min</DivTimeText>
          </ApproxTime>
          {text}
        </Popup>
      ) : null}
    </Marker>
  );
};
