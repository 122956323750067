import * as React from 'react';

import { Box } from './style';

export const Placeholder: React.FC = (): JSX.Element => (
  <Box flex="1" flexDirection="column" paddingTop={32} marginLeft={4} empty>
    <Box flexDirection="row" empty>
      <Box size={92} />

      <Box flex="1" flexDirection="column" marginLeft={16} empty>
        <Box width="60%" height={20} marginBottom={10} />
        <Box width="70%" height={20} />
      </Box>
    </Box>

    <Box flexDirection="column" marginTop={32} empty>
      <Box width="80%" height={20} marginBottom={16} />
      <Box width="80%" height={20} marginBottom={16} />
      <Box width="80%" height={20} marginBottom={16} />
      <Box width="80%" height={20} />
    </Box>
  </Box>
);
