import * as React from 'react';
import axios from 'axios';
import useReactRouter from 'use-react-router';
import {
  GuideLiteContainer,
  Layout,
  OrderLiteContainer,
} from '../../containers';
import { GOOGLE_INTEGRATION_KEY, TRACKING_BASESIVO_URL } from '../../config';
import { Placeholder, Alert, Button, AppBar } from '../../components';
import { Box } from 'rebass/styled-components';
import { ScrollDiv } from '../../components/ScrollDiv';
import { RouteComponentProps } from 'react-router';
import Fab from '@material-ui/core/Fab';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import firebase from 'firebase';

export const TrackingLitePage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const { history } = useReactRouter();
  const dataUrl = location.pathname.split('/');
  const domain = localStorage.getItem('domain');
  const [guide, setGuide] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [typeDelivery, setTypeDelivery] = React.useState(false);
  const [data, setData] = React.useState();

  if (dataUrl[2].length > 8 && !typeDelivery) {
    setTypeDelivery(true);
  }

  function validateGuide() {
    const expression = new RegExp(
      '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$',
    );
    return expression.test(dataUrl[2]);
  }

  if (validateGuide() && !guide) {
    const guide = dataUrl[2]; //atob(dataUrl[2]);
    if (domain !== 'ivoy') {
      // @ts-ignore
      setGuide(dataUrl[2]);
      // @ts-ignore
    } else setGuide(guide);
  } else if (!guide) {
    // @ts-ignore
    setGuide(dataUrl[2]);
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect((): void => {
    firebase.analytics().setUserProperties({ guide });
    let guideQuery = `{  getBasesivoTracking(filter: {guide_ivoy: "${guide}"}) {    id    reference    trackingBusinessType {      name    }    trackingContact {      email      phone    }    trackingClient {      email      firstName      lastName      cellPhone    fullHistory   }    originalAddress    trackingAddress {      fullAddress      externalNumber      internalNumber      neighborhood      municipality      street      zipCode      latitude      longitude    }    trackingHistory {      statusId      statusName      statusDescription  process   cancelation    createdAt      updatedAt    }  }}`;
    if (domain !== 'ivoy') {
      guideQuery = `{\n  clientGuideTracking(clientId: "123386", reference: "${guide}") {\n id\n reference\n    businessType {\n   name\n   }\n  contact {\n email\n phone\n }\n client {\n email\n firstName\n lastName\n cellPhone\n }\n   originalAddress\n   address\n {\n fullAddress\n externalNumber\n internalNumber\n municipality\n neighborhood\n street\n zipCode\n latitude\n longitude\n zipCode\n }\n history\n {\n statusId\n statusName\n statusDescription\n createdAt\n updatedAt\n }\n createdAt\n updatedAt\n }\n }`;
    }
    const orderQuery = `{\n orderTracking(order: ${parseInt(
      guide,
    )}) {\n    eta\n  orderStatus {\n   id\n    name\n   }\n  messenger {\n      firstName\n      lastName\n      latitude\n      longitude\n    }\n    orderAddresses {\n      address {\n        externalNumber\n        street\n      latitude\n     longitude\n      }\n    }\n    orderHistories {\n      nameOrderStatus\n      dateAdd\n    }\n  }\n}\n`;
    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };

    axios
      .post(
        TRACKING_BASESIVO_URL,
        {
          query: typeDelivery ? guideQuery : orderQuery,
        },
        config,
      )
      .then((response: any) => {
        const {
          guideTracking,
          orderTracking,
          clientGuideTracking,
          getBasesivoTracking,
        } = response.data.data;
        console.log('guideTracking', guideTracking)
        console.log('orderTracking', orderTracking)
        console.log('clientGuideTracking', clientGuideTracking)
        console.log('getBasesivoTracking', getBasesivoTracking)

        if (
          guideTracking ||
          orderTracking ||
          clientGuideTracking ||
          getBasesivoTracking
        ) {
          let userId;
          /*if (!item) {
            history.go(0);
          }*/

          if (getBasesivoTracking.trackingHistory.length > 1) {
            if (guideTracking) {
              userId = guideTracking.id;
              setData(guideTracking);
            } else if (clientGuideTracking) {
              userId = clientGuideTracking.id;
              setData(clientGuideTracking);
            } else if (getBasesivoTracking) {
              userId = getBasesivoTracking.id;
              setData(getBasesivoTracking);
            } else {
              userId = orderTracking.id;
              setData(orderTracking);
            }
            localStorage.setItem('userId', userId);
            setLoading(false);
          } else {
            if (getBasesivoTracking.trackingHistory[0].statusId == 1 && getBasesivoTracking?.trackingClient?.fullHistory == false) {
              setLoading(false);
              setError(true);
            } else {
              if (getBasesivoTracking) {
                userId = getBasesivoTracking.id;
                setData(getBasesivoTracking);
                setLoading(false);
              }
            }
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setError(true);

        firebase.analytics().logEvent('lite_page_catch', { guide });
      });
  }, []);

  if (loading) {
    return <Placeholder />;
  }

  if (error || !data) {
    return (
      <Alert
        title={!data ? 'Guía no encontrada' : 'No hay nada por acá'}
        subtitle="¿En qué te podemos ayudar?"
        actions={
          <>
            {/*
            // @ts-ignore TS2589 */}
            <Button onClick={(): void => history.push('/')} mt={2} outlined>
              Rastrear otra guía
            </Button>
          </>
        }
      />
    );
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const { height } = getWindowDimensions();
  return (
    <Box backgroundColor="#FFFFF">
      <AppBar
        title="Realiza el seguimiento de tu paquete"
        iconClick={() => history.push('/')}
        guide={guide}
      />
      <ScrollDiv isAddress heightResponsive={height - 70}>
        {/*
            // @ts-ignore */}
        <Layout style={{ paddingTop: 100 }}>
          {typeDelivery ? (
            <GuideLiteContainer data={data} infoUrl={location} guide={guide} />
          ) : (
            <OrderLiteContainer data={data} guide={guide} />
          )}
        </Layout>
      </ScrollDiv>
      <Button onClick={(): void => history.push('/')} outlined width={200} style={{ margin: "auto", display: "block" }}>
        Rastrear otra guía
      </Button>
      <Fab
        style={{ position: 'absolute', bottom: 20, right: 20, zIndex: 2500 }}
        color="primary"
        variant="extended"
        onClick={() =>
          history.push({
            pathname: `/${guide}/help`,
            state: { data, lite: true, business: typeDelivery ? 'SD' : 'OD' },
          })
        }
      >
        <HeadsetMic />
        ¿Necesitas ayuda?
      </Fab>
    </Box >
  );
};
