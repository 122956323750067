export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const WS_API_URL = process.env.REACT_APP_WS_API_URL;
export const MAP_URL = process.env.REACT_APP_TILE_URL;
export const GOOGLE_INTEGRATION_URL =
  process.env.REACT_APP_GOOGLE_INTEGRATION_URL;
export const GOOGLE_INTEGRATION_GRAPHQL_URL =
  process.env.REACT_APP_GOOGLE_INTEGRATION_GRAPHQL_URL;
export const GOOGLE_INTEGRATION_KEY =
  process.env.REACT_APP_GOOGLE_INTEGRATION_KEY;
export const OPTIMIZELY_KEY = process.env.REACT_APP_OPTIMIZELY_KEY;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const SENTRY_ENV = process.env.REACT_APP_ENV;
export const IGEO_URL = process.env.REACT_APP_IGEO_URL;
export const IGEO_KEY = process.env.REACT_APP_IGEO_KEY;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const TRACKING_BASESIVO_URL = process.env.REACT_APP_TRACKING_BASESIVO;
