import * as React from 'react';
import {
  MainContainer,
  Title,
  Subtitle,
  WrapperColumn,
  WrapperData,
  WrapperRow,
  FullSizeChip,
} from './style';
import useReactRouter from 'use-react-router';
import { Button, AppBar } from '../';
import { AddressContainerProps } from '../../containers/AddressContainer';
import { Box } from 'rebass/styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import HelpIcon from '@material-ui/icons/Help';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

export const AddressesCard: React.FC<AddressContainerProps> = ({
  data,
  edit,
  id,
  addressData,
  validate,
  business,
}: AddressContainerProps): JSX.Element => {
  const { history } = useReactRouter();
  const matchesPhone = useMediaQuery('(max-width:780px)');
  const [open, setOpen] = React.useState('');

  const handleTooltipClose = () => {
    setOpen('');
  };

  const handleTooltipOpen = (idTootip: string) => {
    setOpen(idTootip);
  };

  function validateData(field: any) {
    const validatedField =
      field === undefined ||
      field === null ||
      field === '-' ||
      field === 'N/A' ||
      field === 'sin datos' ||
      field === '';

    return !validatedField ? field : 's/n';
  }

  return (
    <>
      <AppBar
        title="Información de tu paquete"
        iconClick={() => history.push('/')}
        guide={id}
      />
      <MainContainer
        style={{
          paddingTop: edit && '0',
          paddingBottom: edit && '0',
          position: matchesPhone ? 'absolute' : 'static',
        }}
      >
        <Title>Los datos que tenemos</Title>
        <WrapperData>
          <Box
            width="100%"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: !matchesPhone ? 'space-between' : 'flex-start',
            }}
          >
            <WrapperRow
              style={{ justifyContent: 'space-between', maxWidth: '51%' }}
            >
              <WrapperColumn>
                <Subtitle
                  style={{
                    color:
                      validateData(addressData.neighborhood) === 's/n' && 'red',
                  }}
                >
                  Colonia
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'neighborhood'}
                  title={validateData(addressData.neighborhood)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={validateData(addressData.neighborhood)}
                    color={
                      validateData(addressData.neighborhood) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.neighborhood) === 's/n' ? (
                        <WarningIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
              <WrapperColumn>
                <Subtitle
                  style={{
                    color: validateData(addressData.street) === 's/n' && 'red',
                  }}
                >
                  Calle
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'street'}
                  title={validateData(addressData.street)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={validateData(addressData.street)}
                    color={
                      validateData(addressData.street) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.street) === 's/n' ? (
                        <WarningIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
              <WrapperColumn>
                <Subtitle
                  style={{
                    color:
                      validateData(addressData.externalNumber) === 's/n' &&
                      'red',
                  }}
                >
                  Exterior
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'externalNumber'}
                  title={validateData(addressData.externalNumber)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={validateData(addressData.externalNumber)}
                    color={
                      validateData(addressData.externalNumber) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.externalNumber) === 's/n' ? (
                        <WarningIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
            </WrapperRow>
            <WrapperRow
              style={{ justifyContent: 'space-between', maxWidth: '51%' }}
            >
              <WrapperColumn>
                <Subtitle
                  style={{
                    color:
                      validateData(addressData.internalNumber) === 's/n' &&
                      'red',
                  }}
                >
                  Interior
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'internalNumber'}
                  title={validateData(addressData.internalNumber)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={validateData(addressData.internalNumber)}
                    color={
                      validateData(addressData.internalNumber) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.internalNumber) === 's/n' ? (
                        <HelpIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
              <WrapperColumn>
                <Subtitle
                  style={{
                    color:
                      validateData(addressData.municipality) === 's/n' && 'red',
                  }}
                >
                  Municipio
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'municipality'}
                  title={validateData(addressData.municipality)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={validateData(addressData.municipality)}
                    color={
                      validateData(addressData.municipality) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.municipality) === 's/n' ? (
                        <WarningIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
              <WrapperColumn>
                <Subtitle
                  style={{
                    color: validateData(addressData.zipCode) === 's/n' && 'red',
                  }}
                >
                  Código postal
                </Subtitle>
                <Tooltip
                  placement="top"
                  open={open === 'zipCode'}
                  title={validateData(addressData.zipCode)}
                >
                  <FullSizeChip
                    variant="outlined"
                    size="small"
                    label={
                      validateData(addressData.zipCode)
                        ? addressData.zipCode
                        : 's/n'
                    }
                    color={
                      validateData(addressData.zipCode) === 's/n'
                        ? 'secondary'
                        : 'primary'
                    }
                    icon={
                      validateData(addressData.zipCode) === 's/n' ? (
                        <WarningIcon />
                      ) : (
                        <DoneIcon />
                      )
                    }
                  />
                </Tooltip>
              </WrapperColumn>
            </WrapperRow>
          </Box>
        </WrapperData>
        {/*
            //@ts-ignore*/}
        <Button
          onClick={() =>
            validate
              ? history.push({
                  pathname: `/${id}/address`,
                  state: { data, edit: true, id, lite: true, business },
                })
              : history.push({
                  pathname: `/${id}/confirm`,
                  state: { data, id, lite: true, business },
                })
          }
          label="Continuar"
          mt="10px"
          mb="10px"
          py="14px"
          height="auto"
          raised
        />
      </MainContainer>
    </>
  );
};
