import * as React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Collapse } from 'react-collapse';
import {
  Container,
  Tab,
  WrapperInput,
  WrapperHeader,
  WrapperError,
} from './style';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { items } from '../../containers/HelpLiteContainer/items';
import useReactRouter from 'use-react-router';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';

interface ComponentSwipeableProps {
  children: React.ReactNode;
  header: React.ReactNode;
  completed: boolean;
  visible: boolean;
  error: boolean;
  guide: string;
}

export const SwipeableAddress: React.FC<ComponentSwipeableProps> = ({
  children,
  header,
  completed,
  visible,
  error,
  guide,
}): JSX.Element => {
  const { history } = useReactRouter();
  const matchesPhone = useMediaQuery('(max-width:780px)');
  const [vs, setVs] = React.useState(visible);
  let newHeight = vs ? '65%' : '10%';

  if (!matchesPhone && newHeight === '10%') {
    newHeight = '65%';
  }

  function handlerDown() {
    setVs(!vs);
  }

  function handlerUp() {
    setVs(!vs);
  }

  const handlers = useSwipeable({
    onSwipedUp: () => matchesPhone && handlerDown(),
    onSwipedDown: () => matchesPhone && handlerUp(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Container
      {...handlers}
      style={{
        height: completed ? '16%' : newHeight,
        position: matchesPhone ? 'absolute' : 'static',
      }}
    >
      {matchesPhone && <Tab />}
      {error && (
        <WrapperError>
          <Chip
            variant="outlined"
            size="small"
            label="¿No encuentras tu dirección?"
            color="primary"
            icon={<HelpIcon />}
            onClick={() =>
              history.push({
                pathname: `/${guide}/general-help`,
                state: {
                  lite: true,
                  idGuide: guide,
                  business: 'SD',
                  ...items['SD'][0],
                },
              })
            }
            clickable
          />
        </WrapperError>
      )}
      <Collapse
        isOpened={true}
        style={{ width: '100%', backgroundColor: 'green' }}
      >
        {newHeight === '65%' ? (
          <WrapperHeader
            style={{
              position: matchesPhone ? 'absolute' : 'static',
              height: !matchesPhone && '100%',
            }}
          >
            <WrapperInput>{header}</WrapperInput>
            {children}
          </WrapperHeader>
        ) : (
          <WrapperHeader
            onClick={() => handlerUp()}
            style={{
              position: matchesPhone ? 'absolute' : 'static',
              height: !matchesPhone && '100%',
            }}
          >
            <WrapperInput>{header}</WrapperInput>
          </WrapperHeader>
        )}
      </Collapse>
    </Container>
  );
};
