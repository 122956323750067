import styled from 'styled-components';
import {
  space,
  layout,
  display,
  flexbox,
  overflow,
  SpaceProps,
  LayoutProps,
  DisplayProps,
  FlexboxProps,
  OverflowProps,
} from 'styled-system';
import { theme } from 'styled-tools';

export type ContainerProps = SpaceProps &
  LayoutProps &
  DisplayProps &
  OverflowProps &
  FlexboxProps;

export const Container = styled.div<ContainerProps>`
  font-family: ${theme('fonts.body')};
  box-sizing: border-box;

  ${display}
  ${flexbox}
  ${space}
  ${layout}
  ${overflow}
`;

Container.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
};
