import * as React from 'react';
import axios from 'axios';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { useApolloClient } from 'react-apollo';

import {
  Input,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '../../components';
import { SearchTrackingDocument } from '../../graphql/generated';
import { GOOGLE_INTEGRATION_KEY, TRACKING_BASESIVO_URL } from '../../config';
import firebase from 'firebase';

export interface SearchContainerForm {
  value: any;
}

export const SearchContainer = (): JSX.Element => {
  const client = useApolloClient();
  const { history } = useReactRouter();
  const [showDialog, setShowDialog] = React.useState(false);
  const { register, handleSubmit, formState, errors } = useForm<
    SearchContainerForm
  >();

  async function apiBackup(value: any) {
    const data = `{  getBasesivoTracking(filter: {guide_ivoy: "${value}"}) {    id    reference    trackingBusinessType {      name    }    trackingContact {      email      phone    }    trackingClient {      email      firstName      lastName      cellPhone     fullHistory    }    originalAddress    trackingAddress {      fullAddress      externalNumber      internalNumber      neighborhood      municipality      street      zipCode      latitude      longitude    }    trackingHistory {      statusId      statusName      statusDescription      createdAt      updatedAt    }  }}`;
    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };
    await axios
      .post(
        TRACKING_BASESIVO_URL,
        {
          query: data,
        },
        config,
      )
      .then((response: any) => {
        const { data } = response.data;
        if (
          !data ||
          (data?.getBasesivoTracking.trackingHistory.length == 1 &&
            data?.getBasesivoTracking.trackingHistory[0].statusId == 1 && data?.getBasesivoTracking?.trackingClient?.fullHistory == false)
        ) {
          setShowDialog(true);
        } else {
          history.push(`/guide/${value}`);
        }
      })
      .catch((err: any) => {
        console.log(err);

        firebase
          .analytics()
          .logEvent('search_container_catch', { guide: value });
      });
  }

  const onSubmit = async ({ value }: SearchContainerForm): Promise<void> => {
    try {
      await apiBackup(value);
      const {
        data: { trackings },
      } = await client.query({
        query: SearchTrackingDocument,
        variables: !isNaN(value)
          ? { orderId: Number(value) }
          : { search: value },
      });

      if (trackings.length) {
        const [tracking] = trackings;

        return history.push(`/${tracking.id}`);
      }

      setShowDialog(true);
    } catch (error) {
      console.log('error', error);

      setShowDialog(true);
    }

    return Promise.resolve();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        style={{ paddingLeft: '10%', paddingRight: '10%' }}
      >
        <input
          autoComplete="false"
          type="text"
          style={{
            display: 'none',
          }}
        />
        <Input
          invalid={!!errors.value}
          name="value"
          inputRef={register({ required: true, minLength: 5 })}
          label="Escribe tu número de guía"
          placeholder="Ej: 37096"
          helpText={{
            persistent: !!errors.value,
            validationMsg: true,
            children: 'Escribe al menos cinco (5) caracteres',
          }}
        />
        {/*
            // @ts-ignore TS2589 */}
        <Button
          type="submit"
          label={formState.isSubmitting ? 'Rastreando...' : 'Iniciar Rastreo'}
          mt="10px"
          py="14px"
          height="auto"
          raised
        />
      </form>

      <Dialog open={showDialog} onClose={(): void => setShowDialog(false)}>
        <DialogTitle>Guía no encontrada...</DialogTitle>
        <DialogContent>
          Lo sentimos no existe ningún envío con ese número de guía. Por favor contacte al portal donde realizó su compra para mayor información.
        </DialogContent>
        <DialogActions>
          <DialogButton action="close" isDefaultAction>
            Aceptar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
