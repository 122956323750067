import gql from 'graphql-tag';

export default gql`
  type CurrentStatus {
    title: String!
    message: String!
    showMap: Boolean!
  }

  type TimelineItem {
    id: ID!
    title: String
    message: String!
    status: TimelineItemStatus!
    order: Int!
  }

  enum TimelineItemStatus {
    DONE
    CURRENT
    PENDING
  }

  extend type Tracking {
    currentStatus: CurrentStatus!
    timeline: [TimelineItem!]!
  }
`;
