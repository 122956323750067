import * as React from 'react';
import { Timeline, TimelineItem } from '../../components/Timeline';
import { Box, Text } from 'rebass/styled-components';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
//import { statuses } from './status';
import Button from '@material-ui/core/Button';
import useReactRouter from 'use-react-router';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { items } from '../HelpLiteContainer/items';
import themeShein from '../../theme/themeShein';
import theme from '../../theme';
import { Badge } from '../../components/Badge';
import {
  STYLEFORDELIVERIESBADGE,
  SHORT_TEXT_FORMAT,
} from '../../components/Badge/style';
import { Alert } from '../../components';

interface GuideLiteProps {
  data: any;
  infoUrl: any;
  guide?: string;
}

export const GuideLiteContainer: React.FC<GuideLiteProps> = ({
  data,
  infoUrl,
  guide,
}: GuideLiteProps): JSX.Element => {
  const { history } = useReactRouter();
  const domain = localStorage.getItem('domain');
  const dataUrl = infoUrl.pathname.split('/');
  const matchesDeskGrid = useMediaQuery('(min-width:655px)');
  const newStatus: any[] = [];
  const [statuses, setStatuses] = React.useState([
    {
      statusId: 1,
      statusName: 'EN_PROCESO',
      statusDescription: 'Paquete creado',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 3,
      process: 1,
      statusName: 'EN_CAMINO',
      statusDescription: 'Envío recolectado',
      checkPoint: 'PU',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 3,
      process: 2,
      statusName: 'EN_CAMINO',
      statusDescription: 'Tu paquete está en camino',
      checkPoint: 'WC',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 5,
      statusName: 'MESAJERO_CERCA',
      statusDescription: '¡Alístate! El mensajero ya está cerca',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 6,
      statusName: 'PAQUETE_ENTREGADO',
      statusDescription: 'Envío entregado',
      color: '#22c55e',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 7,
      statusName: 'PAQUETE_NO_ENTREGADO',
      statusDescription: 'Tu paquete no pudo ser entregado',
      inCount: 1,
      color: '#fcd34d',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 7,
      statusName: 'PAQUETE_NO_ENTREGADO',
      statusDescription: 'Tu paquete no pudo ser entregado por segunda ocasión',
      inCount: 2,
      color: '#fcd34d',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 7,
      statusName: 'PAQUETE_NO_ENTREGADO',
      statusDescription: 'Tu paquete no pudo ser entregado por tercera ocasión',
      inCount: 3,
      color: '#fcd34d',
      textColor: '#000',
      createdAt: '',
    },
    {
      statusId: 15,
      statusName: 'ENTREGA_CANCELADA',
      statusDescription: 'Envío retornado a origen',
      color: '#DC3545',
      createdAt: '',
      checkPoint: 'RT',
      textColor: '#FFFFFF',
    },
    {
      statusId: 10,
      process: 1,
      statusName: 'PAQUETE_EN_ALMACEN',
      statusDescription: 'Envío recolectado',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
      checkPoint: 'PU',
    },
    {
      statusId: 10,
      process: 2,
      statusName: 'PAQUETE_EN_ALMACEN',
      statusDescription: 'Tu paquete se encuentra en instalación ivoy',
      color: '#933190',
      textColor: '#000',
      createdAt: '',
      checkPoint: 'PU',
    },
    {
      statusId: 17,
      statusName: 'PAQUETE_POR_DEVOLVER',
      statusDescription: 'Tu paquete se encuentra listo para devolver',
      color: '#DC3545',
      textColor: '#FFFFFF',
      createdAt: '',
    },
    {
      statusId: 99,
      statusName: 'PAQUETE_EN_AUDITORIA',
      statusDescription: 'Paquete en revisión',
      color: '#DC3545',
      textColor: '#FFFFFF',
      createdAt: '',
    },
  ]);

  console.log('data', data)

  //@ts-ignore
  let newStatuses = [];
  let countId = 1;
  let countId10 = 0;

  for (const index in data.trackingHistory) {
    const packageHistory = data.trackingHistory[index];
    if (packageHistory !== null) {
      // eslint-disable @typescript-eslint/camelcase
      if (packageHistory.statusId !== null) {
        let finded;
        if (packageHistory.statusId == 3) {
          finded = statuses.find(
            (st) =>
              st.statusId == packageHistory.statusId &&
              st?.process == packageHistory.process,
          );
        } else if (packageHistory.statusId == 7) {
          finded = statuses.find(
            (st) =>
              st.statusId == packageHistory.statusId && st?.inCount == countId,
          );
          countId++;
        } else if (packageHistory.statusId == 10) {
          if (packageHistory.process == 1) {
            const findedId = newStatuses.find(
              (st) => st.statusId == 3 && st?.process == 1,
            );
            if (!findedId && !countId10) {
              finded = statuses.find((st) => st.statusId == 10);
            }
            countId10++;
          } else {
            finded = statuses.find(
              (st) => st.statusId == 10 && st?.process == 2,
            );
          }

        } else {
          finded = statuses.find(
            (st) => st.statusId == packageHistory.statusId,
          );
        }

        if (finded) {
          newStatuses.push({
            ...finded,
            createdAt: packageHistory.createdAt,
          });
        }
      }
    }
  }

  const existInitialStatus = newStatuses.find(
    (st) => st.statusId == 3 && st.process == 1,
  );
  if (existInitialStatus) {
    newStatuses = newStatuses.filter((st) => st.statusId !== 10);
  }

  return newStatuses.length ? (
    <Box height="100%">
      <Card className="CardGray">
        <Box
          style={{
            display: 'flex',
            padding: '10px 5px',
            flexDirection: !matchesDeskGrid ? 'column' : 'row',
          }}
        >
          {data.reference !== dataUrl[2] && (
            <Grid item sm={12} md={6} lg={6}>
              <Text
                paddingBottom={3}
                fontWeight="bold"
                fontSize="20px"
                color={domain === 'ivoy' ? '#000' : themeShein.colors.gray}
              >
                Seguimiento
              </Text>

              <Text
                paddingRight={2}
                paddingBottom={3}
                fontWeight={300}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {guide}
              </Text>

              <Text
                paddingRight={2}
                paddingBottom={3}
                fontWeight={300}
                fontSize={14}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {data.trackingClient.firstName} {data.trackingClient.lastName}
              </Text>
              {newStatuses[newStatuses.length - 1]?.statusDescription && (
                <Badge
                  color={newStatuses[newStatuses.length - 1]?.color}
                  text={newStatuses[newStatuses.length - 1]?.statusDescription}
                  variant="contained"
                  textColorHex={newStatuses[newStatuses.length - 1]?.textColor}
                />
              )}
            </Grid>
          )}
          <Grid
            item
            sm={12}
            md={data.reference ? 12 : 6}
            lg={data.reference ? 12 : 6}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Text
                paddingRight={2}
                paddingBottom={3}
                fontWeight={100}
                fontSize={15}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#9578d3',
                  textTransform: 'capitalize',
                }}
              >
                {data.trackingBusinessType.name.replace('_', ' ')}
              </Text>
            </Box>
          </Grid>
        </Box>
      </Card>
      <Box padding={2} />
      <Card>
        <Text
          paddingRight={2}
          marginBottom={3}
          fontSize={19}
          fontWeight={300}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
          }}
        >
          Historial de envío
        </Text>
        <Timeline>
          {newStatuses.map((status: any) => (
            <TimelineItem
              key={status.statusId}
              status={status.createdAt}
              message={status.statusDescription}
              time={status.createdAt}
              color={status.color}
              checkPoint={status?.checkPoint}
              type
              {...status}
            />
          ))}
        </Timeline>
      </Card>
    </Box>
  ) : (
    <Alert
      title={!data ? 'Guía no encontrada' : 'No hay nada por acá'}
      subtitle="¿En qué te podemos ayudar?"
      actions={
        <>
          {/*
            // @ts-ignore TS2589 */}
          <Button onClick={(): void => history.push('/')} mt={2} outlined>
            Rastrear otra guía
          </Button>
        </>
      }
    />
  );
};
