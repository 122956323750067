import styled from 'styled-components';
import { theme } from 'styled-tools';

export const ApproxTime = styled.div`
  background-color: ${theme('colors.primary')};
  color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 45px;
  line-height: 0.9;
  justify-content: center;
  align-items: center;
`;

export const PrincipalTimeText = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const DivTimeText = styled.div`
  font-size: 12px;
`;

export const Pointer = styled.div`
  position: absolute;
  background-color: ${theme('colors.white')};
  padding: 3px;
  border: 5px solid ${theme('colors.primary')};
  border-radius: 50%;
  margin-top: 45px;
  margin-left: 25px;
`;
