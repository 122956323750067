import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from '@rmwc/button';
import styled from 'styled-components';
import { space, layout, LayoutProps, SpaceProps } from 'styled-system';

import '@material/button/dist/mdc.button.css';

export type ButtonsProps = LayoutProps & SpaceProps & MaterialButtonProps;

export const Button = styled(MaterialButton)<ButtonsProps>`
  && {
    ${layout}
    ${space}
  }
`;
/*eslint-disable @typescript-eslint/ban-ts-ignore*/
//@ts-ignore
Button.defaultProps = {
  width: '100%',
};
