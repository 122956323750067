import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { theme, prop } from 'styled-tools';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4%;
  padding-bottom: 0;
  justify-content: flex-start;
  align-items: center;
`;

export const PictureContainer = styled(Box)`
  width: 8vh;
  height: 8vh;
  border-radius: 4vh;
  margin-right: 4%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${prop('src')});
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 300;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  color: ${theme('colors.dustyGray')};
`;
