export const items = {
  SD: [
    {
      id: 0,
      title: 'Mi dirección es incorrecta',
      name: '',
      description:
        'Para solicitar cambio o corrección de tus datos necesitamos: ',
      items:
        'Nombre completo, número de guía iVoy, domicilio correcto, teléfono de contacto.',
      input: true,
    },
    {
      id: 1,
      title: 'Mi dirección es incorrecta',
      name: '',
      description:
        'Para solicitar cambio o corrección de tus datos necesitamos: ',
      items:
        'Nombre completo, número de guía iVoy, domicilio correcto, teléfono de contacto.',
      input: true,
    },
    {
      id: 2,
      title: 'No estaré en casa',
      name: '',
      description:
        'Para solicitar cambio o corrección de tus datos necesitamos: ',
      items:
        'Nombre completo, número de guía iVoy, domicilio correcto, teléfono de contacto.',
      input: true,
    },
    {
      id: 3,
      title: 'Mi paquete fue devuelto.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 4,
      title: 'Mi paquete está incompleto y/o dañado.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 5,
      title: 'Queja de mensajero.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 6,
      title: 'No reconozco la entrega.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 7,
      title: 'Demora de entrega.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
  ],
  OD: [
    {
      id: 0,
      title: 'Tengo cargos no reconocidos.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 1,
      title: 'Queja de mensajeros.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 2,
      title: 'Daño o pérdida de mi paquete.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 3,
      title: 'No reconozco la entrega.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
    {
      id: 4,
      title: 'No reconozco el cargo.',
      description:
        '¿Tuviste algún problema con tu entrega? Dejanos tus datos: ',
      items: 'Nombre completo, número de guía iVoy, teléfono de contacto.',
      input: true,
    },
  ],
};
