import * as React from 'react';
import { Layout, SearchContainer } from '../../containers';
import { Logo, LogoShein, Box, MadeWithLove } from '../../components';
import Ivoy from '../../images/logo.svg';

export const HomePage: React.FC = (): JSX.Element => {
  const domain = localStorage.getItem('domain');
  return (
    <Layout minHeight="calc(100vh - 128px)" overflow="none">
      {domain !== 'ivoy' ? <LogoShein src={Ivoy} /> : <Logo />}
      <Box flex={1} paddingTop={3}>
        <SearchContainer />
      </Box>

      <Box marginTop={3}>
        <MadeWithLove />
      </Box>
    </Layout>
  );
};
