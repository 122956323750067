import styled from 'styled-components';
import { variant } from 'styled-system';
import { theme, prop } from 'styled-tools';
import { UL, LI } from 'styled-system-html';
import { Text, Box } from 'rebass/styled-components';

export const List = styled(UL)`
  list-style: none;
  position: relative;
  padding: 0 0 0 8px;
  margin: 0;

  &:before {
    content: '';
    width: 1px;
    height: calc(100% - 3px);
    position: absolute;
    top: 3px;
    left: 8px;
    border-left: 2px solid ${theme('colors.alto')};
  }
`;

export const Image = styled(Box)`
  margin-top: 10px;
  width: 250px;
  height: 150px;
  background-color: #f3f3f3;
  background-image: url(${prop('src')});
  background-size: 100% 100%;
`;

const timelineItemStyle = variant({
  key: 'timelineItems',
  prop: 'variant',
});

export const Item = styled(LI)`
  position: relative;
  padding-left: 16px;
  margin-bottom: 25px;
  opacity: ${prop('status')};
  fontweight: 500;
  &::before {
    content: '';
    left: -5px;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    top: 2px;
    display: block;
    position: absolute;
    background: ${prop('colorCircle')};
    top: '3px';
  }
`;

// Item.defaultProps = {
//   variant: 'current',
// };

export const Title = Text;

export const Description = styled(Text).attrs({
  fontSize: 2,
  fontWeight: 200,
})`
  color: ${theme('colors.text')};
`;
