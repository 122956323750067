import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  padding: 7%;
  transition: 0.5s;
`;

export const Tab = styled.div`
  position: absolute;
  top: 10px;
  height: 5px;
  width: 10%;
  background-color: gray;
  right: 45%;
  left: 45%;
  border-radius: 10px;
  transition: 0.1s;
`;

export const WrapperHeader = styled.div`
  display: flex;
  left: 20px;
  right: 20px;
  padding-top: 5px;
  align-items: center;
  flex-direction: column;
`;

export const WrapperInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;

export const WrapperError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
