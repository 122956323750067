import get from 'lodash.get';

import {
  Tracking as TrackingType,
  CurrentStatus,
  TimelineItem,
  TimelineItemStatus,
} from '../generated';
import {
  pendingStatuses,
  currentStatuses,
  CurrentTimelineStatus,
} from '../../data';

export const Tracking = {
  currentStatus: (tracking: TrackingType): CurrentStatus => {
    const trackingLastStatusName = get(tracking, 'lastStatus.name');
    const trackingBusinessTypeName = get(tracking, 'businessType.name');
    const currentStatus = currentStatuses.find(
      (currentStatus: CurrentTimelineStatus): boolean => {
        const isBusinessType = currentStatus.businessType.includes(
          trackingBusinessTypeName,
        );
        const isStatus = currentStatus.showInStatus === trackingLastStatusName;

        return isBusinessType && isStatus;
      },
    );
    const showMapCompareBase = ['*', trackingBusinessTypeName];
    const showMapInBusiness = get(currentStatus, 'showMapInBusiness', []);
    const showMapCompareArrays = [showMapCompareBase, showMapInBusiness];
    const showMapIntersection = showMapCompareArrays.reduce((a, b): string[] =>
      a.filter((c: any): boolean => b.includes(c)),
    );
    const showMap = showMapIntersection.length > 0;

    return {
      __typename: 'CurrentStatus',
      title: currentStatus ? currentStatus.title : 'Entrega en proceso',
      message: currentStatus
        ? currentStatus.message
        : 'Estamos preparando todo para que tengas la mejor experiencia',
      showMap,
    };
  },
  timeline: (tracking: TrackingType): TimelineItem[] => {
    const timeline: TimelineItem[] = [];
    const points = get(tracking, 'points');
    const trackingLastStatusName = get(tracking, 'lastStatus.name');
    const trackingBusinessTypeName = get(tracking, 'businessType.name');
    const businessCompareItems = ['*', trackingBusinessTypeName];

    if (points && points.length) {
      /*
     doneStatuses
      .filter((doneStatus): boolean =>
        doneStatus.businessType.some((businessType): boolean =>
          businessCompareItems.includes(businessType),
        ),
      )
      .some((doneStatus, doneStatusIndex): boolean => {
        if (doneStatus.showFromStatus === trackingLastStatusName) {
          return true;
        }

        timeline.push({
          __typename: 'TimelineItem',
          id: `timeline-done-${doneStatusIndex}`,
          status: TimelineItemStatus.Pending,
          order: doneStatusIndex + 1,
          title: null,
          ...doneStatus,
        });

        return false;
      });
      */
      pendingStatuses
        .filter((pendingStatus): boolean =>
          pendingStatus.businessType.some((businessType): boolean =>
            businessCompareItems.includes(businessType),
          ),
        )
        .reverse()
        .some((reversePending, reversePendingIndex): boolean => {
          if (reversePending.showUntilstatus === trackingLastStatusName) {
            return true;
          }

          timeline.push({
            ...reversePending,
            __typename: 'TimelineItem',
            title: null,
            id: `timeline-pending-${reversePendingIndex}`,
            status: TimelineItemStatus.Pending,
            order: reversePending.order + 10,
          });

          return false;
        });
    } else {
      timeline.push({
        __typename: 'TimelineItem',
        title: null,
        id: 'default-1',
        status: TimelineItemStatus.Current,
        order: 1,
        message: 'Iniciaremos con el envío',
      });
    }

    return timeline.sort((a, b): number => a.order - b.order);
  },
};
