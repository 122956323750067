import styled from 'styled-components';
import {
  space,
  layout,
  display,
  flexbox,
  DisplayProps,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
} from 'styled-system';
import { prop } from 'styled-tools';
import { Box } from 'rebass/styled-components';

import { ReactComponent } from '../../images/logo.svg';

export const Logo = styled(ReactComponent)<
  LayoutProps & SpaceProps & DisplayProps & FlexboxProps
>`
  ${display}
  ${flexbox}
  ${space}
  ${layout}
`;

export const LogoShein = styled(Box)`
  width: 140px;
  height: 150px;
  align-self: center;
  margin: auto;
  background-image: url(${prop('src')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;

Logo.defaultProps = {
  width: 140,
  height: '100%',
  margin: 'auto',
};
