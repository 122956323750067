import * as React from 'react';
import { Container, Text } from './style';
interface ItemsListProps {
  item: string;
  action?: any;
  color?: string;
}

export const ItemsList: React.FC<ItemsListProps> = ({
  item,
  action,
  color,
}: ItemsListProps): JSX.Element => {
  return (
    <Container style={{ backgroundColor: color }} onClick={action}>
      <Text fontSize={2}>{item}</Text>
    </Container>
  );
};
