import * as React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Collapse } from 'react-collapse';
import {
  Container,
  Tab,
  ContainerNotification,
  WrapperItems,
  WrapperClose,
  WrapperHeader,
  WrapperItemsButton,
} from './style';
import { ButtonsHelp } from '../ButtonsHelp';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

interface ComponentSwipeableProps {
  children: React.ReactNode;
  header: React.ReactNode;
  completed: boolean;
  notification?: React.ReactNode;
  visible: boolean;
  tracking?: any;
}

export const ComponentSwipeable: React.FC<ComponentSwipeableProps> = ({
  children,
  header,
  completed,
  notification,
  visible,
  tracking,
}): JSX.Element => {
  const [vs, setVs] = React.useState(visible);
  const matchesPhone = useMediaQuery('(max-width:780px)');
  const newHeight = vs || !matchesPhone ? '0' : '30%';

  function handlerDown() {
    setVs(!vs);
  }

  function handlerUp() {
    setVs(!vs);
  }

  const handlers = useSwipeable({
    onSwipedUp: () => handlerDown(),
    onSwipedDown: () => handlerUp(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <Container
        {...handlers}
        style={{
          height: completed ? newHeight : '95%',
          display: newHeight === '0' ? 'none' : 'flex',
        }}
        onClick={() => handlerDown()}
      >
        {
          /*eslint-disable @typescript-eslint/ban-ts-ignore*/
          //@ts-ignore
          newHeight === '90%' || !completed ? null : (
            <ContainerNotification>{notification}</ContainerNotification>
          )
        }
        {completed ? <Tab /> : null}
        <Collapse
          isOpened={true}
          style={{ width: '100%', backgroundColor: 'green' }}
        >
          <WrapperHeader>
            {newHeight === '0' || !completed ? null : header}
          </WrapperHeader>
        </Collapse>
      </Container>
      {newHeight === '0' && (
        <WrapperItemsButton
          style={{
            position: matchesPhone ? 'absolute' : 'static',
            bottom: matchesPhone && 0,
            left: matchesPhone && 0,
            right: matchesPhone && 0,
          }}
        >
          <WrapperItems>
            {!visible && matchesPhone && (
              <WrapperClose onClick={() => handlerUp()}>X</WrapperClose>
            )}
            {children}
          </WrapperItems>
          <ButtonsHelp id={tracking[1]} data={tracking[0]} />
        </WrapperItemsButton>
      )}
    </>
  );
};
