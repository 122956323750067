import * as React from 'react';
import { Container, TextCard, Image } from './style';

export interface CardNotificationProps {
  title: string;
}

export const CardNotification: React.FC<CardNotificationProps> = ({
  title,
}: CardNotificationProps): JSX.Element => {
  return (
    <Container>
      <Image fill={'white'} />
      <TextCard>{title}</TextCard>
    </Container>
  );
};
