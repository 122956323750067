import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { Box } from 'rebass/styled-components';
import { prop } from 'styled-tools';

export const AppBarComponent = styled(AppBar)`
  background-color: white !important;
  z-index: 2000 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
`;

export const Icon = styled(Box)`
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${prop('src')});
  margin-right: 2%;
  cursor: pointer;
`;
