import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #f3f3f3',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '35%',
    },
  }),
);

interface TransitionsModalProps {
  title?: string;
  children: React.ReactNode;
  active: boolean;
  handleClose: any;
}

export const TransitionsModal: React.FC<TransitionsModalProps> = ({
  children,
  title,
  active,
  handleClose,
}: TransitionsModalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <div className={classes.paper}>
            <div>{title}</div>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
