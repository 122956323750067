import * as React from 'react';
import { Container, Header, ItemsList, AppBar } from '../../components';
import useReactRouter from 'use-react-router';
import { items } from './items';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { OptimizelyFeature } from '@optimizely/react-sdk';

interface HelpLiteContainerProps {
  data: any;
  infoUrl: any;
  business: string;
}

export const HelpLiteContainer: React.FC<HelpLiteContainerProps> = ({
  data,
  infoUrl,
  business,
}: HelpLiteContainerProps): JSX.Element => {
  const { history } = useReactRouter();
  const dataUrl = infoUrl.pathname.split('/');
  const matchesPhone = useMediaQuery('(max-width:780px)');

  return (
    <>
      {!matchesPhone && (
        <AppBar
          title="Realiza el seguimiento de tu paquete"
          iconClick={() => history.push('/')}
          guide={dataUrl[1]}
        />
      )}
      <Container header={<Header text="Necesito ayuda" icon />}>
        {items[business].map((itemsList: any) => (
          <OptimizelyFeature
            feature="validation-address-customer-notification"
            key={itemsList.id}
          >
            {(isEnabled: any, variables: any) => {
              if (
                (isEnabled && itemsList.id === 0) ||
                (!isEnabled && itemsList.id === 1) ||
                (itemsList.id !== 0 && itemsList.id !== 1)
              ) {
                return (
                  <ItemsList
                    key={itemsList.id}
                    item={itemsList.title}
                    action={() => {
                      itemsList.id === 0 && business === 'SD'
                        ? history.push({
                            pathname: `/${dataUrl[1]}/address`,
                            state: {
                              id: dataUrl[1],
                              edit: false,
                              data,
                              business,
                            },
                          })
                        : history.push({
                            pathname: `/${dataUrl[1]}/general-help`,
                            state: {
                              ...itemsList,
                              lite: true,
                              idGuide: dataUrl[1],
                              business,
                              data,
                            },
                          });
                    }}
                  />
                );
              }
            }}
          </OptimizelyFeature>
        ))}
      </Container>
    </>
  );
};
