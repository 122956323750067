import * as React from 'react';
import {
  Header,
  Container,
  CodeInput,
  Dialog,
  DialogActions,
  DialogButton,
  DialogTitle,
  DialogContent,
  AppBar,
} from '../../components';
import { Text } from 'rebass/styled-components';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import { CLIENT_URL, GOOGLE_INTEGRATION_URL } from '../../config';
import axios from 'axios';
import { items } from '../../containers/HelpLiteContainer/items';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import firebase from 'firebase';

export const CodeConfirmPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const [newData, setNewData] = React.useState();
  const [error, setError] = React.useState(false);
  const [lite, setLite] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [number, setNumber] = React.useState();
  const [business, setBusiness] = React.useState();
  const [alternFlow, setAlternFlow] = React.useState(false);
  const [generalCount, setGeneralCount] = React.useState();
  const countCode = localStorage.getItem('countCode');
  const { history } = useReactRouter();
  const dataUrl = location.pathname.split('/');
  const parseCount = parseInt(countCode);

  const [val1, setVal1] = React.useState('');
  const [val2, setVal2] = React.useState('');
  const [val3, setVal3] = React.useState('');
  const [val4, setVal4] = React.useState('');
  const [val5, setVal5] = React.useState('');
  const [val6, setVal6] = React.useState('');

  React.useEffect(() => {
    const generalCount = localStorage.setItem('generalCount', 'true');
    // @ts-ignore
    setGeneralCount(generalCount);
    return () => {
      localStorage.setItem('generalCount', null);
    };
  }, [generalCount]);

  if (location.state !== undefined && !newData && !number) {
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    //@ts-ignore

    const { data, method, lite, business } = location.state;
    setNewData(data);
    setNumber(method);
    setLite(lite);
    setBusiness(business);
  } else if (location.state === undefined) {
    history.push({
      pathname: `/${dataUrl[1]}/`,
    });
  }

  function validate() {
    setLoading(true);
    const code = `${val1}${val2}${val3}${val4}${val5}${val6}`;
    if (lite) {
      const query = `{\n  validateToken(guide: "${dataUrl[1]}", token: ${code}) {\n    validate\n expired\n  }\n}`;
      axios
        .post(GOOGLE_INTEGRATION_URL, {
          query,
        })
        .then((response: any) => {
          const { validateToken } = response.data.data;
          if (validateToken.validate && !validateToken.expired) {
            localStorage.setItem(
              'token-address',
              String(response.data.validate),
            );
            history.push({
              pathname: `/${dataUrl[1]}/address`,
              state: {
                data: newData,
                edit: true,
                id: dataUrl[1],
                lite: true,
                business,
              },
            });
          } else {
            setError(true);
            if (parseCount >= 2 && !alternFlow) {
              setTimeout(() => {
                setAlternFlow(true);
              }, 2000);
            }
            localStorage.setItem(
              'countCode',
              countCode ? `${parseCount + 1}` : '1',
            );
          }
        })
        .catch((error) => {
          firebase
            .analytics()
            .logEvent('validate_token_catch', { guide: dataUrl[1] });
          setError(true);
          localStorage.setItem(
            'countCode',
            countCode ? `${parseCount + 1}` : '1',
          );
          if (parseCount >= 2 && !alternFlow) {
            setTimeout(() => {
              setAlternFlow(true);
            }, 2000);
          }
        });
    } else {
      const dataApi = {
        id: dataUrl[1],
        code,
      };
      axios({
        method: 'post',
        url: `${CLIENT_URL}verify-code`,
        data: dataApi,
      })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('token-address', String(response.data.token));
            history.push({
              pathname: `/${dataUrl[1]}/address`,
              state: { data: newData, edit: true, id: dataUrl[1], business },
            });
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);

          firebase
            .analytics()
            .logEvent('verify_code_catch', { guide: dataUrl[1] });
        });
    }
  }

  const matchesPhone = useMediaQuery('(max-width:780px)');

  return (
    <>
      {!matchesPhone && (
        <AppBar
          title="Información de tu paquete"
          iconClick={() => history.push('/')}
          guide={dataUrl[1]}
        />
      )}
      <Container header={<Header icon text="Confirmar código" />}>
        <Text
          fontSize={20}
          fontWeight={400}
          alignSelf="center"
          padding="15%"
          paddingBottom="0"
          textAlign="center"
        >
          Hemos enviado el código a
        </Text>
        <Text
          fontSize={18}
          fontWeight={400}
          alignSelf="center"
          textAlign="center"
          paddingBottom="10%"
          color="purple"
        >
          {number && number}
        </Text>
        <CodeInput
          action={() => validate()}
          state={loading}
          val1={val1}
          setVal1={(e: any) => setVal1(e.target.value)}
          val2={val2}
          setVal2={(e: any) => setVal2(e.target.value)}
          val3={val3}
          setVal3={(e: any) => setVal3(e.target.value)}
          val4={val4}
          setVal4={(e: any) => setVal4(e.target.value)}
          val5={val5}
          setVal5={(e: any) => setVal5(e.target.value)}
          val6={val6}
          setVal6={(e: any) => setVal6(e.target.value)}
        />
        <Dialog open={alternFlow} onClose={(): void => setAlternFlow(false)}>
          <DialogTitle>Alerta</DialogTitle>
          <DialogContent>
            Vemos que has intentado validar el problema que tuviste...
          </DialogContent>
          <DialogActions>
            <DialogButton
              action="close"
              onClick={() => setAlternFlow(false)}
              isDefaultAction
            >
              Volver
            </DialogButton>
            <DialogButton
              action="close"
              onClick={() => {
                localStorage.setItem('generalCount', null);
                history.push({
                  pathname: `/${dataUrl[1]}/general-help`,
                  state: {
                    lite: true,
                    idGuide: dataUrl[2],
                    business: 'SD',
                    ...items['SD'][0],
                  },
                });
              }}
              isDefaultAction
            >
              Flujo alterno
            </DialogButton>
          </DialogActions>
        </Dialog>
        <Dialog open={error} onClose={(): void => setError(false)}>
          <DialogTitle>Disculpanos</DialogTitle>
          <DialogContent>
            No ha sido posible verificar el código, intentalo de nuevo
          </DialogContent>
          <DialogActions>
            <DialogButton
              action="close"
              onClick={() => setLoading(false)}
              isDefaultAction
            >
              Volver
            </DialogButton>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};
