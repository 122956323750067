import * as React from 'react';

import { Box, Container, Heading } from './style';
import { ReactComponent as DeliveryIcon } from '../../images/delivery.svg';

export interface AlertProps {
  title: string;
  subtitle: string;
  actions?: React.ReactNode;
  image?: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({
  title,
  subtitle,
  actions,
  image,
}: AlertProps): JSX.Element => (
  <Container>
    {React.isValidElement(image) && (
      <Box marginTop={6} marginBottom={3}>
        {React.cloneElement(image, {
          //@ts-ignore
          width: '180px',
          height: 'auto',
        })}
      </Box>
    )}

    <Box marginTop={3}>
      <Heading fontSize={5}>{title}</Heading>
      <Heading fontSize={2} paddingTop={2}>
        {subtitle}
      </Heading>
    </Box>

    {actions && (
      <Box marginTop={4} width="100%">
        {actions}
      </Box>
    )}
  </Container>
);

Alert.defaultProps = {
  actions: [],
  image: <DeliveryIcon />,
};
