import * as React from 'react';
import { Container } from './style';

interface ScrollDivProps {
  children: React.ReactNode;
  isAddress: boolean;
  heightResponsive?: number;
}

export const ScrollDiv: React.FC<ScrollDivProps> = ({
  children,
  isAddress,
  heightResponsive,
}: ScrollDivProps): JSX.Element => (
  <Container
    name="Scroll"
    style={{
      height: isAddress ? heightResponsive : 500,
      marginTop: isAddress && 4,
      width: 820,
      margin: 'auto',
    }}
  >
    {children}
  </Container>
);
