import * as React from 'react';

import { Container, ContainerPadding } from './style';

export interface ThemedAppProps {
  children: React.ReactNode;
  withPadding?: boolean;
}

export const Layout: React.FC<ThemedAppProps> = ({
  children,
  withPadding,
}: ThemedAppProps): JSX.Element => {
  return withPadding ? (
    <ContainerPadding>{children}</ContainerPadding>
  ) : (
    <Container>{children}</Container>
  );
};

Layout.defaultProps = {
  withPadding: true,
};
