import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme('colors.dustyGray')};
  opacity: 0.5;
  margin-top: 2%;
  margin-bottom: 2%;
`;
