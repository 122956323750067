import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  background-color: ${theme('colors.white')};
  transition: 0.3s;
  border: 1px solid ${theme('colors.dustyGray')};
  border-bottom: 0;
  box-shadow: 0 -5px 5px -5px ${theme('colors.text')};
  font-weight: bold;
  z-index: 1300;
`;

export const SubContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Tab = styled.div`
  position: absolute;
  top: 10px;
  height: 5px;
  width: 10%;
  background-color: gray;
  right: 45%;
  left: 45%;
  border-radius: 10px;
`;
