import * as React from 'react';
import { Text, Link } from 'rebass/styled-components';

export const MadeWithLove: React.FC = (): JSX.Element => {
  return (
    <>
      <Text textAlign="center" fontSize={2}>
        Hecho con ♥ en México
      </Text>

      <Link
        href="https://ivoy.mx?utm_source=tracking.ivoy.mx"
        target="_blank"
        rel="noopener"
      >
        <Text textAlign="center" color="text" fontSize={2}>
          ¿Quieres hacer envíos con iVoy?
        </Text>
      </Link>
    </>
  );
};
