import styled from 'styled-components';
import { theme, prop } from 'styled-tools';
import { Text, Box } from 'rebass/styled-components';

export const WrapperColumn = styled.div`
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
`;

export const Subtitle = styled(Text)`
  color: ${theme('colors.gray')};
  font-size: 12px;
  padding-bottom: 5px;
`;

export const WrapperRow = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackFloat = styled(Box)`
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${prop('src')});
  cursor: pointer;
  margin-left: 3%;
`;

export const ContainerHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
