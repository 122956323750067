import * as React from 'react';
import { List, Item, Title, Description, Image } from './style';
import moment from 'moment';
import Tooltip from '../Tooltip';
import { CHECKPOINTS } from '../Badge/style';
import { InfoOutlined } from '@material-ui/icons';

export interface TimelineItemProps {
  children: React.ReactNode;
  time: string;
  message: string;
  status: boolean;
  evidence: string;
  type: boolean;
  color?: string;
  checkPoint?: string;
}

export const TimelineItem: React.FC<Partial<TimelineItemProps>> = ({
  time,
  message,
  status,
  evidence,
  type,
  color,
  checkPoint,
}: Partial<TimelineItemProps>): JSX.Element => {
  return (
    <Item status={status ? '1' : '0.3'} colorCircle={color}>
      {checkPoint ? (
        <React.Fragment>
          <Title
            fontWeight={400}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {message}
            <Tooltip text={checkPoint}>
              <InfoOutlined fontSize="inherit" />
            </Tooltip>
          </Title>
          {time && (
            <Description>
              {`${moment(time).format('MMM')} ${moment(time)
                .utc()
                .format('D')}, ${moment(time).utc().format('YYYY')}, ${
                type ? time.slice(11, 16) : moment(time).format('HH:mm')
              }`}
            </Description>
          )}
          {/* <Description>{message}</Description> */}
          {evidence ? <Image src={evidence} /> : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Title fontWeight={400}>{message}</Title>
          {time && (
            <Description>
              {`${moment(time).format('MMM')} ${moment(time)
                .utc()
                .format('D')}, ${moment(time).utc().format('YYYY')}, ${
                type ? time.slice(11, 16) : moment(time).format('HH:mm')
              }`}
            </Description>
          )}
          {/* <Description>{message}</Description> */}
          {evidence ? <Image src={evidence} /> : null}
        </React.Fragment>
      )}
    </Item>
  );
};

export interface TimelineProps {
  children: React.ReactNode;
}

export const Timeline: React.FC<TimelineProps> = ({
  children,
}: TimelineProps): JSX.Element => {
  return <List>{children}</List>;
};
