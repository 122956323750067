import * as React from 'react';
import { ItemsList, Header, Container } from '../../components';
import useReactRouter from 'use-react-router';
import { GetIssuesDocument } from '../../graphql/generated';
import { useApolloClient } from 'react-apollo';

interface ScreenHelpProps {
  headerText: string;
  id: string;
  data: any;
  business: string;
}

function reducer(state: any, action: any): any {
  if (action.type === 'GET_ISSUES') {
    return action.payload;
  } else {
    return state;
  }
}

export const ScreenHelp: React.FC<ScreenHelpProps> = ({
  id,
  headerText,
  data,
  business,
}: ScreenHelpProps): JSX.Element => {
  const { history } = useReactRouter();
  const client = useApolloClient();
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  // @ts-ignore
  const initialState = { loading: true, data: null, error: null };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect((): void => {
    async function getIssuesQuery(): Promise<void> {
      const payload = await client.query({
        query: GetIssuesDocument,
      });

      dispatch({ type: 'GET_ISSUES', payload });
    }

    getIssuesQuery();
  }, [client]);

  if (state.data) {
    const { issues } = state.data;
    return (
      <Container header={<Header text={headerText} icon />}>
        {issues.map((issues: any) => {
          return (
            <ItemsList
              key={issues.name}
              item={issues.name}
              action={() =>
                issues.name === 'Mi dirección esta mal'
                  ? history.push({
                      pathname: `/${id}/address`,
                      state: { data, edit: false, id, business },
                    })
                  : history.push({
                      pathname: `/${id}/general-help`,
                      state: { ...issues, lite: false, business },
                    })
              }
            />
          );
        })}
      </Container>
    );
  }

  if (state.error) {
    console.error(state.error.message);
  }

  return <></>;
};
