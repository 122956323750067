/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  Json: any,
  Long: any,
};

export type AggregateIssue = {
  __typename?: 'AggregateIssue',
  count: Scalars['Int'],
};

export type AggregateTracking = {
  __typename?: 'AggregateTracking',
  count: Scalars['Int'],
};

export type AggregateTrackingAddress = {
  __typename?: 'AggregateTrackingAddress',
  count: Scalars['Int'],
};

export type AggregateTrackingAlert = {
  __typename?: 'AggregateTrackingAlert',
  count: Scalars['Int'],
};

export type AggregateTrackingBusinessType = {
  __typename?: 'AggregateTrackingBusinessType',
  count: Scalars['Int'],
};

export type AggregateTrackingHistoryStatus = {
  __typename?: 'AggregateTrackingHistoryStatus',
  count: Scalars['Int'],
};

export type AggregateTrackingIssue = {
  __typename?: 'AggregateTrackingIssue',
  count: Scalars['Int'],
};

export type AggregateTrackingLocation = {
  __typename?: 'AggregateTrackingLocation',
  count: Scalars['Int'],
};

export type AggregateTrackingPoint = {
  __typename?: 'AggregateTrackingPoint',
  count: Scalars['Int'],
};

export type AggregateTrackingReceiver = {
  __typename?: 'AggregateTrackingReceiver',
  count: Scalars['Int'],
};

export type AggregateTrackingStatus = {
  __typename?: 'AggregateTrackingStatus',
  count: Scalars['Int'],
};

export type AggregateTrackingValidationCode = {
  __typename?: 'AggregateTrackingValidationCode',
  count: Scalars['Int'],
};

export type BatchPayload = {
  __typename?: 'BatchPayload',
  count: Scalars['Long'],
};

export type CurrentStatus = {
  __typename?: 'CurrentStatus',
  title: Scalars['String'],
  message: Scalars['String'],
  showMap: Scalars['Boolean'],
};


export type Issue = Node & {
  __typename?: 'Issue',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
  input?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type IssueConnection = {
  __typename?: 'IssueConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<IssueEdge>>,
  aggregate: AggregateIssue,
};

export type IssueCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  description: Scalars['String'],
  input?: Maybe<Scalars['Boolean']>,
};

export type IssueCreateManyInput = {
  create?: Maybe<Array<IssueCreateInput>>,
  connect?: Maybe<Array<IssueWhereUniqueInput>>,
};

export type IssueEdge = {
  __typename?: 'IssueEdge',
  node: Issue,
  cursor: Scalars['String'],
};

export enum IssueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InputAsc = 'input_ASC',
  InputDesc = 'input_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type IssuePreviousValues = {
  __typename?: 'IssuePreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
  input?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type IssueScalarWhereInput = {
  AND?: Maybe<Array<IssueScalarWhereInput>>,
  OR?: Maybe<Array<IssueScalarWhereInput>>,
  NOT?: Maybe<Array<IssueScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
  input_not?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type IssueSubscriptionPayload = {
  __typename?: 'IssueSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Issue>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<IssuePreviousValues>,
};

export type IssueSubscriptionWhereInput = {
  AND?: Maybe<Array<IssueSubscriptionWhereInput>>,
  OR?: Maybe<Array<IssueSubscriptionWhereInput>>,
  NOT?: Maybe<Array<IssueSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<IssueWhereInput>,
};

export type IssueUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
};

export type IssueUpdateInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
};

export type IssueUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
};

export type IssueUpdateManyInput = {
  create?: Maybe<Array<IssueCreateInput>>,
  connect?: Maybe<Array<IssueWhereUniqueInput>>,
  set?: Maybe<Array<IssueWhereUniqueInput>>,
  disconnect?: Maybe<Array<IssueWhereUniqueInput>>,
  delete?: Maybe<Array<IssueWhereUniqueInput>>,
  update?: Maybe<Array<IssueUpdateWithWhereUniqueNestedInput>>,
  updateMany?: Maybe<Array<IssueUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<IssueScalarWhereInput>>,
  upsert?: Maybe<Array<IssueUpsertWithWhereUniqueNestedInput>>,
};

export type IssueUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
};

export type IssueUpdateManyWithWhereNestedInput = {
  where: IssueScalarWhereInput,
  data: IssueUpdateManyDataInput,
};

export type IssueUpdateWithWhereUniqueNestedInput = {
  where: IssueWhereUniqueInput,
  data: IssueUpdateDataInput,
};

export type IssueUpsertWithWhereUniqueNestedInput = {
  where: IssueWhereUniqueInput,
  update: IssueUpdateDataInput,
  create: IssueCreateInput,
};

export type IssueWhereInput = {
  AND?: Maybe<Array<IssueWhereInput>>,
  OR?: Maybe<Array<IssueWhereInput>>,
  NOT?: Maybe<Array<IssueWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
  input?: Maybe<Scalars['Boolean']>,
  input_not?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type IssueWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};



export type Mutation = {
  __typename?: 'Mutation',
  createTrackingAlert: TrackingAlert,
  createTrackingHistoryStatus: TrackingHistoryStatus,
  createTrackingIssue: TrackingIssue,
  createTrackingPoint: TrackingPoint,
  createTrackingLocation: TrackingLocation,
  createTrackingValidationCode: TrackingValidationCode,
  createIssue: Issue,
  createTrackingAddress: TrackingAddress,
  createTracking: Tracking,
  createTrackingReceiver: TrackingReceiver,
  createTrackingBusinessType: TrackingBusinessType,
  createTrackingStatus: TrackingStatus,
  updateTrackingAlert?: Maybe<TrackingAlert>,
  updateTrackingHistoryStatus?: Maybe<TrackingHistoryStatus>,
  updateTrackingIssue?: Maybe<TrackingIssue>,
  updateTrackingPoint?: Maybe<TrackingPoint>,
  updateTrackingLocation?: Maybe<TrackingLocation>,
  updateTrackingValidationCode?: Maybe<TrackingValidationCode>,
  updateIssue?: Maybe<Issue>,
  updateTrackingAddress?: Maybe<TrackingAddress>,
  updateTracking?: Maybe<Tracking>,
  updateTrackingReceiver?: Maybe<TrackingReceiver>,
  updateTrackingBusinessType?: Maybe<TrackingBusinessType>,
  updateTrackingStatus?: Maybe<TrackingStatus>,
  deleteTrackingAlert?: Maybe<TrackingAlert>,
  deleteTrackingHistoryStatus?: Maybe<TrackingHistoryStatus>,
  deleteTrackingIssue?: Maybe<TrackingIssue>,
  deleteTrackingPoint?: Maybe<TrackingPoint>,
  deleteTrackingLocation?: Maybe<TrackingLocation>,
  deleteTrackingValidationCode?: Maybe<TrackingValidationCode>,
  deleteIssue?: Maybe<Issue>,
  deleteTrackingAddress?: Maybe<TrackingAddress>,
  deleteTracking?: Maybe<Tracking>,
  deleteTrackingReceiver?: Maybe<TrackingReceiver>,
  deleteTrackingBusinessType?: Maybe<TrackingBusinessType>,
  deleteTrackingStatus?: Maybe<TrackingStatus>,
  upsertTrackingAlert: TrackingAlert,
  upsertTrackingHistoryStatus: TrackingHistoryStatus,
  upsertTrackingIssue: TrackingIssue,
  upsertTrackingPoint: TrackingPoint,
  upsertTrackingLocation: TrackingLocation,
  upsertTrackingValidationCode: TrackingValidationCode,
  upsertIssue: Issue,
  upsertTrackingAddress: TrackingAddress,
  upsertTracking: Tracking,
  upsertTrackingReceiver: TrackingReceiver,
  upsertTrackingBusinessType: TrackingBusinessType,
  upsertTrackingStatus: TrackingStatus,
  updateManyTrackingAlerts: BatchPayload,
  updateManyTrackingHistoryStatuses: BatchPayload,
  updateManyTrackingIssues: BatchPayload,
  updateManyTrackingPoints: BatchPayload,
  updateManyTrackingLocations: BatchPayload,
  updateManyTrackingValidationCodes: BatchPayload,
  updateManyIssues: BatchPayload,
  updateManyTrackingAddresses: BatchPayload,
  updateManyTrackings: BatchPayload,
  updateManyTrackingReceivers: BatchPayload,
  updateManyTrackingBusinessTypes: BatchPayload,
  updateManyTrackingStatuses: BatchPayload,
  deleteManyTrackingAlerts: BatchPayload,
  deleteManyTrackingHistoryStatuses: BatchPayload,
  deleteManyTrackingIssues: BatchPayload,
  deleteManyTrackingPoints: BatchPayload,
  deleteManyTrackingLocations: BatchPayload,
  deleteManyTrackingValidationCodes: BatchPayload,
  deleteManyIssues: BatchPayload,
  deleteManyTrackingAddresses: BatchPayload,
  deleteManyTrackings: BatchPayload,
  deleteManyTrackingReceivers: BatchPayload,
  deleteManyTrackingBusinessTypes: BatchPayload,
  deleteManyTrackingStatuses: BatchPayload,
};


export type MutationCreateTrackingAlertArgs = {
  data: TrackingAlertCreateInput
};


export type MutationCreateTrackingHistoryStatusArgs = {
  data: TrackingHistoryStatusCreateInput
};


export type MutationCreateTrackingIssueArgs = {
  data: TrackingIssueCreateInput
};


export type MutationCreateTrackingPointArgs = {
  data: TrackingPointCreateInput
};


export type MutationCreateTrackingLocationArgs = {
  data: TrackingLocationCreateInput
};


export type MutationCreateTrackingValidationCodeArgs = {
  data: TrackingValidationCodeCreateInput
};


export type MutationCreateIssueArgs = {
  data: IssueCreateInput
};


export type MutationCreateTrackingAddressArgs = {
  data: TrackingAddressCreateInput
};


export type MutationCreateTrackingArgs = {
  data: TrackingCreateInput
};


export type MutationCreateTrackingReceiverArgs = {
  data: TrackingReceiverCreateInput
};


export type MutationCreateTrackingBusinessTypeArgs = {
  data: TrackingBusinessTypeCreateInput
};


export type MutationCreateTrackingStatusArgs = {
  data: TrackingStatusCreateInput
};


export type MutationUpdateTrackingAlertArgs = {
  data: TrackingAlertUpdateInput,
  where: TrackingAlertWhereUniqueInput
};


export type MutationUpdateTrackingHistoryStatusArgs = {
  data: TrackingHistoryStatusUpdateInput,
  where: TrackingHistoryStatusWhereUniqueInput
};


export type MutationUpdateTrackingIssueArgs = {
  data: TrackingIssueUpdateInput,
  where: TrackingIssueWhereUniqueInput
};


export type MutationUpdateTrackingPointArgs = {
  data: TrackingPointUpdateInput,
  where: TrackingPointWhereUniqueInput
};


export type MutationUpdateTrackingLocationArgs = {
  data: TrackingLocationUpdateInput,
  where: TrackingLocationWhereUniqueInput
};


export type MutationUpdateTrackingValidationCodeArgs = {
  data: TrackingValidationCodeUpdateInput,
  where: TrackingValidationCodeWhereUniqueInput
};


export type MutationUpdateIssueArgs = {
  data: IssueUpdateInput,
  where: IssueWhereUniqueInput
};


export type MutationUpdateTrackingAddressArgs = {
  data: TrackingAddressUpdateInput,
  where: TrackingAddressWhereUniqueInput
};


export type MutationUpdateTrackingArgs = {
  data: TrackingUpdateInput,
  where: TrackingWhereUniqueInput
};


export type MutationUpdateTrackingReceiverArgs = {
  data: TrackingReceiverUpdateInput,
  where: TrackingReceiverWhereUniqueInput
};


export type MutationUpdateTrackingBusinessTypeArgs = {
  data: TrackingBusinessTypeUpdateInput,
  where: TrackingBusinessTypeWhereUniqueInput
};


export type MutationUpdateTrackingStatusArgs = {
  data: TrackingStatusUpdateInput,
  where: TrackingStatusWhereUniqueInput
};


export type MutationDeleteTrackingAlertArgs = {
  where: TrackingAlertWhereUniqueInput
};


export type MutationDeleteTrackingHistoryStatusArgs = {
  where: TrackingHistoryStatusWhereUniqueInput
};


export type MutationDeleteTrackingIssueArgs = {
  where: TrackingIssueWhereUniqueInput
};


export type MutationDeleteTrackingPointArgs = {
  where: TrackingPointWhereUniqueInput
};


export type MutationDeleteTrackingLocationArgs = {
  where: TrackingLocationWhereUniqueInput
};


export type MutationDeleteTrackingValidationCodeArgs = {
  where: TrackingValidationCodeWhereUniqueInput
};


export type MutationDeleteIssueArgs = {
  where: IssueWhereUniqueInput
};


export type MutationDeleteTrackingAddressArgs = {
  where: TrackingAddressWhereUniqueInput
};


export type MutationDeleteTrackingArgs = {
  where: TrackingWhereUniqueInput
};


export type MutationDeleteTrackingReceiverArgs = {
  where: TrackingReceiverWhereUniqueInput
};


export type MutationDeleteTrackingBusinessTypeArgs = {
  where: TrackingBusinessTypeWhereUniqueInput
};


export type MutationDeleteTrackingStatusArgs = {
  where: TrackingStatusWhereUniqueInput
};


export type MutationUpsertTrackingAlertArgs = {
  where: TrackingAlertWhereUniqueInput,
  create: TrackingAlertCreateInput,
  update: TrackingAlertUpdateInput
};


export type MutationUpsertTrackingHistoryStatusArgs = {
  where: TrackingHistoryStatusWhereUniqueInput,
  create: TrackingHistoryStatusCreateInput,
  update: TrackingHistoryStatusUpdateInput
};


export type MutationUpsertTrackingIssueArgs = {
  where: TrackingIssueWhereUniqueInput,
  create: TrackingIssueCreateInput,
  update: TrackingIssueUpdateInput
};


export type MutationUpsertTrackingPointArgs = {
  where: TrackingPointWhereUniqueInput,
  create: TrackingPointCreateInput,
  update: TrackingPointUpdateInput
};


export type MutationUpsertTrackingLocationArgs = {
  where: TrackingLocationWhereUniqueInput,
  create: TrackingLocationCreateInput,
  update: TrackingLocationUpdateInput
};


export type MutationUpsertTrackingValidationCodeArgs = {
  where: TrackingValidationCodeWhereUniqueInput,
  create: TrackingValidationCodeCreateInput,
  update: TrackingValidationCodeUpdateInput
};


export type MutationUpsertIssueArgs = {
  where: IssueWhereUniqueInput,
  create: IssueCreateInput,
  update: IssueUpdateInput
};


export type MutationUpsertTrackingAddressArgs = {
  where: TrackingAddressWhereUniqueInput,
  create: TrackingAddressCreateInput,
  update: TrackingAddressUpdateInput
};


export type MutationUpsertTrackingArgs = {
  where: TrackingWhereUniqueInput,
  create: TrackingCreateInput,
  update: TrackingUpdateInput
};


export type MutationUpsertTrackingReceiverArgs = {
  where: TrackingReceiverWhereUniqueInput,
  create: TrackingReceiverCreateInput,
  update: TrackingReceiverUpdateInput
};


export type MutationUpsertTrackingBusinessTypeArgs = {
  where: TrackingBusinessTypeWhereUniqueInput,
  create: TrackingBusinessTypeCreateInput,
  update: TrackingBusinessTypeUpdateInput
};


export type MutationUpsertTrackingStatusArgs = {
  where: TrackingStatusWhereUniqueInput,
  create: TrackingStatusCreateInput,
  update: TrackingStatusUpdateInput
};


export type MutationUpdateManyTrackingAlertsArgs = {
  data: TrackingAlertUpdateManyMutationInput,
  where?: Maybe<TrackingAlertWhereInput>
};


export type MutationUpdateManyTrackingHistoryStatusesArgs = {
  data: TrackingHistoryStatusUpdateManyMutationInput,
  where?: Maybe<TrackingHistoryStatusWhereInput>
};


export type MutationUpdateManyTrackingIssuesArgs = {
  data: TrackingIssueUpdateManyMutationInput,
  where?: Maybe<TrackingIssueWhereInput>
};


export type MutationUpdateManyTrackingPointsArgs = {
  data: TrackingPointUpdateManyMutationInput,
  where?: Maybe<TrackingPointWhereInput>
};


export type MutationUpdateManyTrackingLocationsArgs = {
  data: TrackingLocationUpdateManyMutationInput,
  where?: Maybe<TrackingLocationWhereInput>
};


export type MutationUpdateManyTrackingValidationCodesArgs = {
  data: TrackingValidationCodeUpdateManyMutationInput,
  where?: Maybe<TrackingValidationCodeWhereInput>
};


export type MutationUpdateManyIssuesArgs = {
  data: IssueUpdateManyMutationInput,
  where?: Maybe<IssueWhereInput>
};


export type MutationUpdateManyTrackingAddressesArgs = {
  data: TrackingAddressUpdateManyMutationInput,
  where?: Maybe<TrackingAddressWhereInput>
};


export type MutationUpdateManyTrackingsArgs = {
  data: TrackingUpdateManyMutationInput,
  where?: Maybe<TrackingWhereInput>
};


export type MutationUpdateManyTrackingReceiversArgs = {
  data: TrackingReceiverUpdateManyMutationInput,
  where?: Maybe<TrackingReceiverWhereInput>
};


export type MutationUpdateManyTrackingBusinessTypesArgs = {
  data: TrackingBusinessTypeUpdateManyMutationInput,
  where?: Maybe<TrackingBusinessTypeWhereInput>
};


export type MutationUpdateManyTrackingStatusesArgs = {
  data: TrackingStatusUpdateManyMutationInput,
  where?: Maybe<TrackingStatusWhereInput>
};


export type MutationDeleteManyTrackingAlertsArgs = {
  where?: Maybe<TrackingAlertWhereInput>
};


export type MutationDeleteManyTrackingHistoryStatusesArgs = {
  where?: Maybe<TrackingHistoryStatusWhereInput>
};


export type MutationDeleteManyTrackingIssuesArgs = {
  where?: Maybe<TrackingIssueWhereInput>
};


export type MutationDeleteManyTrackingPointsArgs = {
  where?: Maybe<TrackingPointWhereInput>
};


export type MutationDeleteManyTrackingLocationsArgs = {
  where?: Maybe<TrackingLocationWhereInput>
};


export type MutationDeleteManyTrackingValidationCodesArgs = {
  where?: Maybe<TrackingValidationCodeWhereInput>
};


export type MutationDeleteManyIssuesArgs = {
  where?: Maybe<IssueWhereInput>
};


export type MutationDeleteManyTrackingAddressesArgs = {
  where?: Maybe<TrackingAddressWhereInput>
};


export type MutationDeleteManyTrackingsArgs = {
  where?: Maybe<TrackingWhereInput>
};


export type MutationDeleteManyTrackingReceiversArgs = {
  where?: Maybe<TrackingReceiverWhereInput>
};


export type MutationDeleteManyTrackingBusinessTypesArgs = {
  where?: Maybe<TrackingBusinessTypeWhereInput>
};


export type MutationDeleteManyTrackingStatusesArgs = {
  where?: Maybe<TrackingStatusWhereInput>
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type Node = {
  id: Scalars['ID'],
};

export type PageInfo = {
  __typename?: 'PageInfo',
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
  endCursor?: Maybe<Scalars['String']>,
};

export type Query = {
  __typename?: 'Query',
  trackingAlerts: Array<Maybe<TrackingAlert>>,
  trackingHistoryStatuses: Array<Maybe<TrackingHistoryStatus>>,
  trackingIssues: Array<Maybe<TrackingIssue>>,
  trackingPoints: Array<Maybe<TrackingPoint>>,
  trackingLocations: Array<Maybe<TrackingLocation>>,
  trackingValidationCodes: Array<Maybe<TrackingValidationCode>>,
  issues: Array<Maybe<Issue>>,
  trackingAddresses: Array<Maybe<TrackingAddress>>,
  trackings: Array<Maybe<Tracking>>,
  trackingReceivers: Array<Maybe<TrackingReceiver>>,
  trackingBusinessTypes: Array<Maybe<TrackingBusinessType>>,
  trackingStatuses: Array<Maybe<TrackingStatus>>,
  trackingAlert?: Maybe<TrackingAlert>,
  trackingHistoryStatus?: Maybe<TrackingHistoryStatus>,
  trackingIssue?: Maybe<TrackingIssue>,
  trackingPoint?: Maybe<TrackingPoint>,
  trackingLocation?: Maybe<TrackingLocation>,
  trackingValidationCode?: Maybe<TrackingValidationCode>,
  issue?: Maybe<Issue>,
  trackingAddress?: Maybe<TrackingAddress>,
  tracking?: Maybe<Tracking>,
  trackingReceiver?: Maybe<TrackingReceiver>,
  trackingBusinessType?: Maybe<TrackingBusinessType>,
  trackingStatus?: Maybe<TrackingStatus>,
  trackingAlertsConnection: TrackingAlertConnection,
  trackingHistoryStatusesConnection: TrackingHistoryStatusConnection,
  trackingIssuesConnection: TrackingIssueConnection,
  trackingPointsConnection: TrackingPointConnection,
  trackingLocationsConnection: TrackingLocationConnection,
  trackingValidationCodesConnection: TrackingValidationCodeConnection,
  issuesConnection: IssueConnection,
  trackingAddressesConnection: TrackingAddressConnection,
  trackingsConnection: TrackingConnection,
  trackingReceiversConnection: TrackingReceiverConnection,
  trackingBusinessTypesConnection: TrackingBusinessTypeConnection,
  trackingStatusesConnection: TrackingStatusConnection,
  node?: Maybe<Node>,
};


export type QueryTrackingAlertsArgs = {
  where?: Maybe<TrackingAlertWhereInput>,
  orderBy?: Maybe<TrackingAlertOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingHistoryStatusesArgs = {
  where?: Maybe<TrackingHistoryStatusWhereInput>,
  orderBy?: Maybe<TrackingHistoryStatusOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingIssuesArgs = {
  where?: Maybe<TrackingIssueWhereInput>,
  orderBy?: Maybe<TrackingIssueOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingPointsArgs = {
  where?: Maybe<TrackingPointWhereInput>,
  orderBy?: Maybe<TrackingPointOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingLocationsArgs = {
  where?: Maybe<TrackingLocationWhereInput>,
  orderBy?: Maybe<TrackingLocationOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingValidationCodesArgs = {
  where?: Maybe<TrackingValidationCodeWhereInput>,
  orderBy?: Maybe<TrackingValidationCodeOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryIssuesArgs = {
  where?: Maybe<IssueWhereInput>,
  orderBy?: Maybe<IssueOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingAddressesArgs = {
  where?: Maybe<TrackingAddressWhereInput>,
  orderBy?: Maybe<TrackingAddressOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingsArgs = {
  where?: Maybe<TrackingWhereInput>,
  orderBy?: Maybe<TrackingOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingReceiversArgs = {
  where?: Maybe<TrackingReceiverWhereInput>,
  orderBy?: Maybe<TrackingReceiverOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingBusinessTypesArgs = {
  where?: Maybe<TrackingBusinessTypeWhereInput>,
  orderBy?: Maybe<TrackingBusinessTypeOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingStatusesArgs = {
  where?: Maybe<TrackingStatusWhereInput>,
  orderBy?: Maybe<TrackingStatusOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingAlertArgs = {
  where: TrackingAlertWhereUniqueInput
};


export type QueryTrackingHistoryStatusArgs = {
  where: TrackingHistoryStatusWhereUniqueInput
};


export type QueryTrackingIssueArgs = {
  where: TrackingIssueWhereUniqueInput
};


export type QueryTrackingPointArgs = {
  where: TrackingPointWhereUniqueInput
};


export type QueryTrackingLocationArgs = {
  where: TrackingLocationWhereUniqueInput
};


export type QueryTrackingValidationCodeArgs = {
  where: TrackingValidationCodeWhereUniqueInput
};


export type QueryIssueArgs = {
  where: IssueWhereUniqueInput
};


export type QueryTrackingAddressArgs = {
  where: TrackingAddressWhereUniqueInput
};


export type QueryTrackingArgs = {
  where: TrackingWhereUniqueInput
};


export type QueryTrackingReceiverArgs = {
  where: TrackingReceiverWhereUniqueInput
};


export type QueryTrackingBusinessTypeArgs = {
  where: TrackingBusinessTypeWhereUniqueInput
};


export type QueryTrackingStatusArgs = {
  where: TrackingStatusWhereUniqueInput
};


export type QueryTrackingAlertsConnectionArgs = {
  where?: Maybe<TrackingAlertWhereInput>,
  orderBy?: Maybe<TrackingAlertOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingHistoryStatusesConnectionArgs = {
  where?: Maybe<TrackingHistoryStatusWhereInput>,
  orderBy?: Maybe<TrackingHistoryStatusOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingIssuesConnectionArgs = {
  where?: Maybe<TrackingIssueWhereInput>,
  orderBy?: Maybe<TrackingIssueOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingPointsConnectionArgs = {
  where?: Maybe<TrackingPointWhereInput>,
  orderBy?: Maybe<TrackingPointOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingLocationsConnectionArgs = {
  where?: Maybe<TrackingLocationWhereInput>,
  orderBy?: Maybe<TrackingLocationOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingValidationCodesConnectionArgs = {
  where?: Maybe<TrackingValidationCodeWhereInput>,
  orderBy?: Maybe<TrackingValidationCodeOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryIssuesConnectionArgs = {
  where?: Maybe<IssueWhereInput>,
  orderBy?: Maybe<IssueOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingAddressesConnectionArgs = {
  where?: Maybe<TrackingAddressWhereInput>,
  orderBy?: Maybe<TrackingAddressOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingsConnectionArgs = {
  where?: Maybe<TrackingWhereInput>,
  orderBy?: Maybe<TrackingOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingReceiversConnectionArgs = {
  where?: Maybe<TrackingReceiverWhereInput>,
  orderBy?: Maybe<TrackingReceiverOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingBusinessTypesConnectionArgs = {
  where?: Maybe<TrackingBusinessTypeWhereInput>,
  orderBy?: Maybe<TrackingBusinessTypeOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTrackingStatusesConnectionArgs = {
  where?: Maybe<TrackingStatusWhereInput>,
  orderBy?: Maybe<TrackingStatusOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};

export type Subscription = {
  __typename?: 'Subscription',
  trackingAlert?: Maybe<TrackingAlertSubscriptionPayload>,
  trackingHistoryStatus?: Maybe<TrackingHistoryStatusSubscriptionPayload>,
  trackingIssue?: Maybe<TrackingIssueSubscriptionPayload>,
  trackingPoint?: Maybe<TrackingPointSubscriptionPayload>,
  trackingLocation?: Maybe<TrackingLocationSubscriptionPayload>,
  trackingValidationCode?: Maybe<TrackingValidationCodeSubscriptionPayload>,
  issue?: Maybe<IssueSubscriptionPayload>,
  trackingAddress?: Maybe<TrackingAddressSubscriptionPayload>,
  tracking?: Maybe<TrackingSubscriptionPayload>,
  trackingReceiver?: Maybe<TrackingReceiverSubscriptionPayload>,
  trackingBusinessType?: Maybe<TrackingBusinessTypeSubscriptionPayload>,
  trackingStatus?: Maybe<TrackingStatusSubscriptionPayload>,
};


export type SubscriptionTrackingAlertArgs = {
  where?: Maybe<TrackingAlertSubscriptionWhereInput>
};


export type SubscriptionTrackingHistoryStatusArgs = {
  where?: Maybe<TrackingHistoryStatusSubscriptionWhereInput>
};


export type SubscriptionTrackingIssueArgs = {
  where?: Maybe<TrackingIssueSubscriptionWhereInput>
};


export type SubscriptionTrackingPointArgs = {
  where?: Maybe<TrackingPointSubscriptionWhereInput>
};


export type SubscriptionTrackingLocationArgs = {
  where?: Maybe<TrackingLocationSubscriptionWhereInput>
};


export type SubscriptionTrackingValidationCodeArgs = {
  where?: Maybe<TrackingValidationCodeSubscriptionWhereInput>
};


export type SubscriptionIssueArgs = {
  where?: Maybe<IssueSubscriptionWhereInput>
};


export type SubscriptionTrackingAddressArgs = {
  where?: Maybe<TrackingAddressSubscriptionWhereInput>
};


export type SubscriptionTrackingArgs = {
  where?: Maybe<TrackingSubscriptionWhereInput>
};


export type SubscriptionTrackingReceiverArgs = {
  where?: Maybe<TrackingReceiverSubscriptionWhereInput>
};


export type SubscriptionTrackingBusinessTypeArgs = {
  where?: Maybe<TrackingBusinessTypeSubscriptionWhereInput>
};


export type SubscriptionTrackingStatusArgs = {
  where?: Maybe<TrackingStatusSubscriptionWhereInput>
};

export type TimelineItem = {
  __typename?: 'TimelineItem',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  status: TimelineItemStatus,
  order: Scalars['Int'],
};

export enum TimelineItemStatus {
  Done = 'DONE',
  Current = 'CURRENT',
  Pending = 'PENDING'
}

export type Tracking = Node & {
  __typename?: 'Tracking',
  alerts?: Maybe<Array<TrackingAlert>>,
  businessType?: Maybe<TrackingBusinessType>,
  children?: Maybe<Array<Tracking>>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  currentStatus: CurrentStatus,
  finished?: Maybe<Scalars['Boolean']>,
  guide?: Maybe<Scalars['String']>,
  history?: Maybe<Array<TrackingHistoryStatus>>,
  id: Scalars['ID'],
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocation?: Maybe<TrackingLocation>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  lastStatus?: Maybe<TrackingStatus>,
  location?: Maybe<Scalars['String']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  orderId?: Maybe<Scalars['Int']>,
  originalAddress?: Maybe<Scalars['String']>,
  parent?: Maybe<Tracking>,
  points?: Maybe<Array<TrackingPoint>>,
  shorterUrl?: Maybe<Scalars['String']>,
  timeline: Array<TimelineItem>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};


export type TrackingAlertsArgs = {
  where?: Maybe<TrackingAlertWhereInput>,
  orderBy?: Maybe<TrackingAlertOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TrackingChildrenArgs = {
  where?: Maybe<TrackingWhereInput>,
  orderBy?: Maybe<TrackingOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TrackingHistoryArgs = {
  where?: Maybe<TrackingHistoryStatusWhereInput>,
  orderBy?: Maybe<TrackingHistoryStatusOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TrackingPointsArgs = {
  where?: Maybe<TrackingPointWhereInput>,
  orderBy?: Maybe<TrackingPointOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TrackingAddress = Node & {
  __typename?: 'TrackingAddress',
  id: Scalars['ID'],
  point?: Maybe<Array<TrackingPoint>>,
  address: Scalars['String'],
  addressObject?: Maybe<Scalars['Json']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  comments: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};


export type TrackingAddressPointArgs = {
  where?: Maybe<TrackingPointWhereInput>,
  orderBy?: Maybe<TrackingPointOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TrackingAddressConnection = {
  __typename?: 'TrackingAddressConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingAddressEdge>>,
  aggregate: AggregateTrackingAddress,
};

export type TrackingAddressCreateInput = {
  id?: Maybe<Scalars['ID']>,
  address: Scalars['String'],
  addressObject?: Maybe<Scalars['Json']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  comments: Scalars['String'],
  point?: Maybe<TrackingPointCreateManyWithoutAddressesInput>,
};

export type TrackingAddressCreateManyWithoutPointInput = {
  create?: Maybe<Array<TrackingAddressCreateWithoutPointInput>>,
  connect?: Maybe<Array<TrackingAddressWhereUniqueInput>>,
};

export type TrackingAddressCreateOneInput = {
  create?: Maybe<TrackingAddressCreateInput>,
  connect?: Maybe<TrackingAddressWhereUniqueInput>,
};

export type TrackingAddressCreateWithoutPointInput = {
  id?: Maybe<Scalars['ID']>,
  address: Scalars['String'],
  addressObject?: Maybe<Scalars['Json']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  comments: Scalars['String'],
};

export type TrackingAddressEdge = {
  __typename?: 'TrackingAddressEdge',
  node: TrackingAddress,
  cursor: Scalars['String'],
};

export enum TrackingAddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressObjectAsc = 'addressObject_ASC',
  AddressObjectDesc = 'addressObject_DESC',
  LatitudeAsc = 'latitude_ASC',
  LatitudeDesc = 'latitude_DESC',
  LongitudeAsc = 'longitude_ASC',
  LongitudeDesc = 'longitude_DESC',
  CommentsAsc = 'comments_ASC',
  CommentsDesc = 'comments_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingAddressPreviousValues = {
  __typename?: 'TrackingAddressPreviousValues',
  id: Scalars['ID'],
  address: Scalars['String'],
  addressObject?: Maybe<Scalars['Json']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  comments: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingAddressScalarWhereInput = {
  AND?: Maybe<Array<TrackingAddressScalarWhereInput>>,
  OR?: Maybe<Array<TrackingAddressScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingAddressScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  address?: Maybe<Scalars['String']>,
  address_not?: Maybe<Scalars['String']>,
  address_in?: Maybe<Array<Scalars['String']>>,
  address_not_in?: Maybe<Array<Scalars['String']>>,
  address_lt?: Maybe<Scalars['String']>,
  address_lte?: Maybe<Scalars['String']>,
  address_gt?: Maybe<Scalars['String']>,
  address_gte?: Maybe<Scalars['String']>,
  address_contains?: Maybe<Scalars['String']>,
  address_not_contains?: Maybe<Scalars['String']>,
  address_starts_with?: Maybe<Scalars['String']>,
  address_not_starts_with?: Maybe<Scalars['String']>,
  address_ends_with?: Maybe<Scalars['String']>,
  address_not_ends_with?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  latitude_not?: Maybe<Scalars['Float']>,
  latitude_in?: Maybe<Array<Scalars['Float']>>,
  latitude_not_in?: Maybe<Array<Scalars['Float']>>,
  latitude_lt?: Maybe<Scalars['Float']>,
  latitude_lte?: Maybe<Scalars['Float']>,
  latitude_gt?: Maybe<Scalars['Float']>,
  latitude_gte?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  longitude_not?: Maybe<Scalars['Float']>,
  longitude_in?: Maybe<Array<Scalars['Float']>>,
  longitude_not_in?: Maybe<Array<Scalars['Float']>>,
  longitude_lt?: Maybe<Scalars['Float']>,
  longitude_lte?: Maybe<Scalars['Float']>,
  longitude_gt?: Maybe<Scalars['Float']>,
  longitude_gte?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
  comments_not?: Maybe<Scalars['String']>,
  comments_in?: Maybe<Array<Scalars['String']>>,
  comments_not_in?: Maybe<Array<Scalars['String']>>,
  comments_lt?: Maybe<Scalars['String']>,
  comments_lte?: Maybe<Scalars['String']>,
  comments_gt?: Maybe<Scalars['String']>,
  comments_gte?: Maybe<Scalars['String']>,
  comments_contains?: Maybe<Scalars['String']>,
  comments_not_contains?: Maybe<Scalars['String']>,
  comments_starts_with?: Maybe<Scalars['String']>,
  comments_not_starts_with?: Maybe<Scalars['String']>,
  comments_ends_with?: Maybe<Scalars['String']>,
  comments_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingAddressSubscriptionPayload = {
  __typename?: 'TrackingAddressSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingAddress>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingAddressPreviousValues>,
};

export type TrackingAddressSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingAddressSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingAddressSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingAddressSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingAddressWhereInput>,
};

export type TrackingAddressUpdateDataInput = {
  address?: Maybe<Scalars['String']>,
  addressObject?: Maybe<Scalars['Json']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
  point?: Maybe<TrackingPointUpdateManyWithoutAddressesInput>,
};

export type TrackingAddressUpdateInput = {
  address?: Maybe<Scalars['String']>,
  addressObject?: Maybe<Scalars['Json']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
  point?: Maybe<TrackingPointUpdateManyWithoutAddressesInput>,
};

export type TrackingAddressUpdateManyDataInput = {
  address?: Maybe<Scalars['String']>,
  addressObject?: Maybe<Scalars['Json']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
};

export type TrackingAddressUpdateManyMutationInput = {
  address?: Maybe<Scalars['String']>,
  addressObject?: Maybe<Scalars['Json']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
};

export type TrackingAddressUpdateManyWithoutPointInput = {
  create?: Maybe<Array<TrackingAddressCreateWithoutPointInput>>,
  connect?: Maybe<Array<TrackingAddressWhereUniqueInput>>,
  set?: Maybe<Array<TrackingAddressWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingAddressWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingAddressWhereUniqueInput>>,
  update?: Maybe<Array<TrackingAddressUpdateWithWhereUniqueWithoutPointInput>>,
  updateMany?: Maybe<Array<TrackingAddressUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingAddressScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingAddressUpsertWithWhereUniqueWithoutPointInput>>,
};

export type TrackingAddressUpdateManyWithWhereNestedInput = {
  where: TrackingAddressScalarWhereInput,
  data: TrackingAddressUpdateManyDataInput,
};

export type TrackingAddressUpdateOneInput = {
  create?: Maybe<TrackingAddressCreateInput>,
  connect?: Maybe<TrackingAddressWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingAddressUpdateDataInput>,
  upsert?: Maybe<TrackingAddressUpsertNestedInput>,
};

export type TrackingAddressUpdateWithoutPointDataInput = {
  address?: Maybe<Scalars['String']>,
  addressObject?: Maybe<Scalars['Json']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
};

export type TrackingAddressUpdateWithWhereUniqueWithoutPointInput = {
  where: TrackingAddressWhereUniqueInput,
  data: TrackingAddressUpdateWithoutPointDataInput,
};

export type TrackingAddressUpsertNestedInput = {
  update: TrackingAddressUpdateDataInput,
  create: TrackingAddressCreateInput,
};

export type TrackingAddressUpsertWithWhereUniqueWithoutPointInput = {
  where: TrackingAddressWhereUniqueInput,
  update: TrackingAddressUpdateWithoutPointDataInput,
  create: TrackingAddressCreateWithoutPointInput,
};

export type TrackingAddressWhereInput = {
  AND?: Maybe<Array<TrackingAddressWhereInput>>,
  OR?: Maybe<Array<TrackingAddressWhereInput>>,
  NOT?: Maybe<Array<TrackingAddressWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  address?: Maybe<Scalars['String']>,
  address_not?: Maybe<Scalars['String']>,
  address_in?: Maybe<Array<Scalars['String']>>,
  address_not_in?: Maybe<Array<Scalars['String']>>,
  address_lt?: Maybe<Scalars['String']>,
  address_lte?: Maybe<Scalars['String']>,
  address_gt?: Maybe<Scalars['String']>,
  address_gte?: Maybe<Scalars['String']>,
  address_contains?: Maybe<Scalars['String']>,
  address_not_contains?: Maybe<Scalars['String']>,
  address_starts_with?: Maybe<Scalars['String']>,
  address_not_starts_with?: Maybe<Scalars['String']>,
  address_ends_with?: Maybe<Scalars['String']>,
  address_not_ends_with?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  latitude_not?: Maybe<Scalars['Float']>,
  latitude_in?: Maybe<Array<Scalars['Float']>>,
  latitude_not_in?: Maybe<Array<Scalars['Float']>>,
  latitude_lt?: Maybe<Scalars['Float']>,
  latitude_lte?: Maybe<Scalars['Float']>,
  latitude_gt?: Maybe<Scalars['Float']>,
  latitude_gte?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  longitude_not?: Maybe<Scalars['Float']>,
  longitude_in?: Maybe<Array<Scalars['Float']>>,
  longitude_not_in?: Maybe<Array<Scalars['Float']>>,
  longitude_lt?: Maybe<Scalars['Float']>,
  longitude_lte?: Maybe<Scalars['Float']>,
  longitude_gt?: Maybe<Scalars['Float']>,
  longitude_gte?: Maybe<Scalars['Float']>,
  comments?: Maybe<Scalars['String']>,
  comments_not?: Maybe<Scalars['String']>,
  comments_in?: Maybe<Array<Scalars['String']>>,
  comments_not_in?: Maybe<Array<Scalars['String']>>,
  comments_lt?: Maybe<Scalars['String']>,
  comments_lte?: Maybe<Scalars['String']>,
  comments_gt?: Maybe<Scalars['String']>,
  comments_gte?: Maybe<Scalars['String']>,
  comments_contains?: Maybe<Scalars['String']>,
  comments_not_contains?: Maybe<Scalars['String']>,
  comments_starts_with?: Maybe<Scalars['String']>,
  comments_not_starts_with?: Maybe<Scalars['String']>,
  comments_ends_with?: Maybe<Scalars['String']>,
  comments_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  point_every?: Maybe<TrackingPointWhereInput>,
  point_some?: Maybe<TrackingPointWhereInput>,
  point_none?: Maybe<TrackingPointWhereInput>,
};

export type TrackingAddressWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingAlert = Node & {
  __typename?: 'TrackingAlert',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  description: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingAlertConnection = {
  __typename?: 'TrackingAlertConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingAlertEdge>>,
  aggregate: AggregateTrackingAlert,
};

export type TrackingAlertCreateInput = {
  id?: Maybe<Scalars['ID']>,
  description: Scalars['String'],
  tracking?: Maybe<TrackingCreateOneWithoutAlertsInput>,
};

export type TrackingAlertCreateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingAlertCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingAlertWhereUniqueInput>>,
};

export type TrackingAlertCreateWithoutTrackingInput = {
  id?: Maybe<Scalars['ID']>,
  description: Scalars['String'],
};

export type TrackingAlertEdge = {
  __typename?: 'TrackingAlertEdge',
  node: TrackingAlert,
  cursor: Scalars['String'],
};

export enum TrackingAlertOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingAlertPreviousValues = {
  __typename?: 'TrackingAlertPreviousValues',
  id: Scalars['ID'],
  description: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingAlertScalarWhereInput = {
  AND?: Maybe<Array<TrackingAlertScalarWhereInput>>,
  OR?: Maybe<Array<TrackingAlertScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingAlertScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingAlertSubscriptionPayload = {
  __typename?: 'TrackingAlertSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingAlert>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingAlertPreviousValues>,
};

export type TrackingAlertSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingAlertSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingAlertSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingAlertSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingAlertWhereInput>,
};

export type TrackingAlertUpdateInput = {
  description?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneWithoutAlertsInput>,
};

export type TrackingAlertUpdateManyDataInput = {
  description?: Maybe<Scalars['String']>,
};

export type TrackingAlertUpdateManyMutationInput = {
  description?: Maybe<Scalars['String']>,
};

export type TrackingAlertUpdateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingAlertCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingAlertWhereUniqueInput>>,
  set?: Maybe<Array<TrackingAlertWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingAlertWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingAlertWhereUniqueInput>>,
  update?: Maybe<Array<TrackingAlertUpdateWithWhereUniqueWithoutTrackingInput>>,
  updateMany?: Maybe<Array<TrackingAlertUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingAlertScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingAlertUpsertWithWhereUniqueWithoutTrackingInput>>,
};

export type TrackingAlertUpdateManyWithWhereNestedInput = {
  where: TrackingAlertScalarWhereInput,
  data: TrackingAlertUpdateManyDataInput,
};

export type TrackingAlertUpdateWithoutTrackingDataInput = {
  description?: Maybe<Scalars['String']>,
};

export type TrackingAlertUpdateWithWhereUniqueWithoutTrackingInput = {
  where: TrackingAlertWhereUniqueInput,
  data: TrackingAlertUpdateWithoutTrackingDataInput,
};

export type TrackingAlertUpsertWithWhereUniqueWithoutTrackingInput = {
  where: TrackingAlertWhereUniqueInput,
  update: TrackingAlertUpdateWithoutTrackingDataInput,
  create: TrackingAlertCreateWithoutTrackingInput,
};

export type TrackingAlertWhereInput = {
  AND?: Maybe<Array<TrackingAlertWhereInput>>,
  OR?: Maybe<Array<TrackingAlertWhereInput>>,
  NOT?: Maybe<Array<TrackingAlertWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
};

export type TrackingAlertWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingBusinessType = Node & {
  __typename?: 'TrackingBusinessType',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
};

export type TrackingBusinessTypeConnection = {
  __typename?: 'TrackingBusinessTypeConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingBusinessTypeEdge>>,
  aggregate: AggregateTrackingBusinessType,
};

export type TrackingBusinessTypeCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  description: Scalars['String'],
};

export type TrackingBusinessTypeCreateOneInput = {
  create?: Maybe<TrackingBusinessTypeCreateInput>,
  connect?: Maybe<TrackingBusinessTypeWhereUniqueInput>,
};

export type TrackingBusinessTypeEdge = {
  __typename?: 'TrackingBusinessTypeEdge',
  node: TrackingBusinessType,
  cursor: Scalars['String'],
};

export enum TrackingBusinessTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export type TrackingBusinessTypePreviousValues = {
  __typename?: 'TrackingBusinessTypePreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
};

export type TrackingBusinessTypeSubscriptionPayload = {
  __typename?: 'TrackingBusinessTypeSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingBusinessType>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingBusinessTypePreviousValues>,
};

export type TrackingBusinessTypeSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingBusinessTypeSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingBusinessTypeSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingBusinessTypeSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingBusinessTypeWhereInput>,
};

export type TrackingBusinessTypeUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type TrackingBusinessTypeUpdateInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type TrackingBusinessTypeUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type TrackingBusinessTypeUpdateOneInput = {
  create?: Maybe<TrackingBusinessTypeCreateInput>,
  connect?: Maybe<TrackingBusinessTypeWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingBusinessTypeUpdateDataInput>,
  upsert?: Maybe<TrackingBusinessTypeUpsertNestedInput>,
};

export type TrackingBusinessTypeUpsertNestedInput = {
  update: TrackingBusinessTypeUpdateDataInput,
  create: TrackingBusinessTypeCreateInput,
};

export type TrackingBusinessTypeWhereInput = {
  AND?: Maybe<Array<TrackingBusinessTypeWhereInput>>,
  OR?: Maybe<Array<TrackingBusinessTypeWhereInput>>,
  NOT?: Maybe<Array<TrackingBusinessTypeWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
};

export type TrackingBusinessTypeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingConnection = {
  __typename?: 'TrackingConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingEdge>>,
  aggregate: AggregateTracking,
};

export type TrackingCreateInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateManyWithoutLastStatusInput = {
  create?: Maybe<Array<TrackingCreateWithoutLastStatusInput>>,
  connect?: Maybe<Array<TrackingWhereUniqueInput>>,
};

export type TrackingCreateManyWithoutParentInput = {
  create?: Maybe<Array<TrackingCreateWithoutParentInput>>,
  connect?: Maybe<Array<TrackingWhereUniqueInput>>,
};

export type TrackingCreateOneInput = {
  create?: Maybe<TrackingCreateInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateOneWithoutAlertsInput = {
  create?: Maybe<TrackingCreateWithoutAlertsInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateOneWithoutChildrenInput = {
  create?: Maybe<TrackingCreateWithoutChildrenInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateOneWithoutHistoryInput = {
  create?: Maybe<TrackingCreateWithoutHistoryInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateOneWithoutLastLocationInput = {
  create?: Maybe<TrackingCreateWithoutLastLocationInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateOneWithoutPointsInput = {
  create?: Maybe<TrackingCreateWithoutPointsInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
};

export type TrackingCreateWithoutAlertsInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateWithoutChildrenInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateWithoutHistoryInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateWithoutLastLocationInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateWithoutLastStatusInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
};

export type TrackingCreateWithoutParentInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  points?: Maybe<TrackingPointCreateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingCreateWithoutPointsInput = {
  id?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationCreateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeCreateOneInput>,
  parent?: Maybe<TrackingCreateOneWithoutChildrenInput>,
  children?: Maybe<TrackingCreateManyWithoutParentInput>,
  history?: Maybe<TrackingHistoryStatusCreateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertCreateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusCreateOneWithoutTrackingInput>,
};

export type TrackingEdge = {
  __typename?: 'TrackingEdge',
  node: Tracking,
  cursor: Scalars['String'],
};

export type TrackingHistoryStatus = Node & {
  __typename?: 'TrackingHistoryStatus',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  status?: Maybe<TrackingStatus>,
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingHistoryStatusConnection = {
  __typename?: 'TrackingHistoryStatusConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingHistoryStatusEdge>>,
  aggregate: AggregateTrackingHistoryStatus,
};

export type TrackingHistoryStatusCreateInput = {
  id?: Maybe<Scalars['ID']>,
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingCreateOneWithoutHistoryInput>,
  status?: Maybe<TrackingStatusCreateOneInput>,
};

export type TrackingHistoryStatusCreateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingHistoryStatusCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingHistoryStatusWhereUniqueInput>>,
};

export type TrackingHistoryStatusCreateWithoutTrackingInput = {
  id?: Maybe<Scalars['ID']>,
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  status?: Maybe<TrackingStatusCreateOneInput>,
};

export type TrackingHistoryStatusEdge = {
  __typename?: 'TrackingHistoryStatusEdge',
  node: TrackingHistoryStatus,
  cursor: Scalars['String'],
};

export enum TrackingHistoryStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  UniqueHashAsc = 'uniqueHash_ASC',
  UniqueHashDesc = 'uniqueHash_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type TrackingHistoryStatusPreviousValues = {
  __typename?: 'TrackingHistoryStatusPreviousValues',
  id: Scalars['ID'],
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingHistoryStatusScalarWhereInput = {
  AND?: Maybe<Array<TrackingHistoryStatusScalarWhereInput>>,
  OR?: Maybe<Array<TrackingHistoryStatusScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingHistoryStatusScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  order?: Maybe<Scalars['Int']>,
  order_not?: Maybe<Scalars['Int']>,
  order_in?: Maybe<Array<Scalars['Int']>>,
  order_not_in?: Maybe<Array<Scalars['Int']>>,
  order_lt?: Maybe<Scalars['Int']>,
  order_lte?: Maybe<Scalars['Int']>,
  order_gt?: Maybe<Scalars['Int']>,
  order_gte?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  uniqueHash_not?: Maybe<Scalars['String']>,
  uniqueHash_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_not_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_lt?: Maybe<Scalars['String']>,
  uniqueHash_lte?: Maybe<Scalars['String']>,
  uniqueHash_gt?: Maybe<Scalars['String']>,
  uniqueHash_gte?: Maybe<Scalars['String']>,
  uniqueHash_contains?: Maybe<Scalars['String']>,
  uniqueHash_not_contains?: Maybe<Scalars['String']>,
  uniqueHash_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_not_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_ends_with?: Maybe<Scalars['String']>,
  uniqueHash_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingHistoryStatusSubscriptionPayload = {
  __typename?: 'TrackingHistoryStatusSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingHistoryStatus>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingHistoryStatusPreviousValues>,
};

export type TrackingHistoryStatusSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingHistoryStatusSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingHistoryStatusSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingHistoryStatusSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingHistoryStatusWhereInput>,
};

export type TrackingHistoryStatusUpdateInput = {
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneWithoutHistoryInput>,
  status?: Maybe<TrackingStatusUpdateOneInput>,
};

export type TrackingHistoryStatusUpdateManyDataInput = {
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingHistoryStatusUpdateManyMutationInput = {
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingHistoryStatusUpdateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingHistoryStatusCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingHistoryStatusWhereUniqueInput>>,
  set?: Maybe<Array<TrackingHistoryStatusWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingHistoryStatusWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingHistoryStatusWhereUniqueInput>>,
  update?: Maybe<Array<TrackingHistoryStatusUpdateWithWhereUniqueWithoutTrackingInput>>,
  updateMany?: Maybe<Array<TrackingHistoryStatusUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingHistoryStatusScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingHistoryStatusUpsertWithWhereUniqueWithoutTrackingInput>>,
};

export type TrackingHistoryStatusUpdateManyWithWhereNestedInput = {
  where: TrackingHistoryStatusScalarWhereInput,
  data: TrackingHistoryStatusUpdateManyDataInput,
};

export type TrackingHistoryStatusUpdateWithoutTrackingDataInput = {
  order?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  status?: Maybe<TrackingStatusUpdateOneInput>,
};

export type TrackingHistoryStatusUpdateWithWhereUniqueWithoutTrackingInput = {
  where: TrackingHistoryStatusWhereUniqueInput,
  data: TrackingHistoryStatusUpdateWithoutTrackingDataInput,
};

export type TrackingHistoryStatusUpsertWithWhereUniqueWithoutTrackingInput = {
  where: TrackingHistoryStatusWhereUniqueInput,
  update: TrackingHistoryStatusUpdateWithoutTrackingDataInput,
  create: TrackingHistoryStatusCreateWithoutTrackingInput,
};

export type TrackingHistoryStatusWhereInput = {
  AND?: Maybe<Array<TrackingHistoryStatusWhereInput>>,
  OR?: Maybe<Array<TrackingHistoryStatusWhereInput>>,
  NOT?: Maybe<Array<TrackingHistoryStatusWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  order?: Maybe<Scalars['Int']>,
  order_not?: Maybe<Scalars['Int']>,
  order_in?: Maybe<Array<Scalars['Int']>>,
  order_not_in?: Maybe<Array<Scalars['Int']>>,
  order_lt?: Maybe<Scalars['Int']>,
  order_lte?: Maybe<Scalars['Int']>,
  order_gt?: Maybe<Scalars['Int']>,
  order_gte?: Maybe<Scalars['Int']>,
  uniqueHash?: Maybe<Scalars['String']>,
  uniqueHash_not?: Maybe<Scalars['String']>,
  uniqueHash_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_not_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_lt?: Maybe<Scalars['String']>,
  uniqueHash_lte?: Maybe<Scalars['String']>,
  uniqueHash_gt?: Maybe<Scalars['String']>,
  uniqueHash_gte?: Maybe<Scalars['String']>,
  uniqueHash_contains?: Maybe<Scalars['String']>,
  uniqueHash_not_contains?: Maybe<Scalars['String']>,
  uniqueHash_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_not_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_ends_with?: Maybe<Scalars['String']>,
  uniqueHash_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
  status?: Maybe<TrackingStatusWhereInput>,
};

export type TrackingHistoryStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingIssue = Node & {
  __typename?: 'TrackingIssue',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  issue?: Maybe<Array<Issue>>,
  comments: Scalars['String'],
  status?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};


export type TrackingIssueIssueArgs = {
  where?: Maybe<IssueWhereInput>,
  orderBy?: Maybe<IssueOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TrackingIssueConnection = {
  __typename?: 'TrackingIssueConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingIssueEdge>>,
  aggregate: AggregateTrackingIssue,
};

export type TrackingIssueCreateInput = {
  id?: Maybe<Scalars['ID']>,
  comments: Scalars['String'],
  status?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingCreateOneInput>,
  issue?: Maybe<IssueCreateManyInput>,
};

export type TrackingIssueEdge = {
  __typename?: 'TrackingIssueEdge',
  node: TrackingIssue,
  cursor: Scalars['String'],
};

export enum TrackingIssueOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CommentsAsc = 'comments_ASC',
  CommentsDesc = 'comments_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingIssuePreviousValues = {
  __typename?: 'TrackingIssuePreviousValues',
  id: Scalars['ID'],
  comments: Scalars['String'],
  status?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingIssueSubscriptionPayload = {
  __typename?: 'TrackingIssueSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingIssue>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingIssuePreviousValues>,
};

export type TrackingIssueSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingIssueSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingIssueSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingIssueSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingIssueWhereInput>,
};

export type TrackingIssueUpdateInput = {
  comments?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneInput>,
  issue?: Maybe<IssueUpdateManyInput>,
};

export type TrackingIssueUpdateManyMutationInput = {
  comments?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
};

export type TrackingIssueWhereInput = {
  AND?: Maybe<Array<TrackingIssueWhereInput>>,
  OR?: Maybe<Array<TrackingIssueWhereInput>>,
  NOT?: Maybe<Array<TrackingIssueWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  comments?: Maybe<Scalars['String']>,
  comments_not?: Maybe<Scalars['String']>,
  comments_in?: Maybe<Array<Scalars['String']>>,
  comments_not_in?: Maybe<Array<Scalars['String']>>,
  comments_lt?: Maybe<Scalars['String']>,
  comments_lte?: Maybe<Scalars['String']>,
  comments_gt?: Maybe<Scalars['String']>,
  comments_gte?: Maybe<Scalars['String']>,
  comments_contains?: Maybe<Scalars['String']>,
  comments_not_contains?: Maybe<Scalars['String']>,
  comments_starts_with?: Maybe<Scalars['String']>,
  comments_not_starts_with?: Maybe<Scalars['String']>,
  comments_ends_with?: Maybe<Scalars['String']>,
  comments_not_ends_with?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_not?: Maybe<Scalars['String']>,
  status_in?: Maybe<Array<Scalars['String']>>,
  status_not_in?: Maybe<Array<Scalars['String']>>,
  status_lt?: Maybe<Scalars['String']>,
  status_lte?: Maybe<Scalars['String']>,
  status_gt?: Maybe<Scalars['String']>,
  status_gte?: Maybe<Scalars['String']>,
  status_contains?: Maybe<Scalars['String']>,
  status_not_contains?: Maybe<Scalars['String']>,
  status_starts_with?: Maybe<Scalars['String']>,
  status_not_starts_with?: Maybe<Scalars['String']>,
  status_ends_with?: Maybe<Scalars['String']>,
  status_not_ends_with?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  ticket_not?: Maybe<Scalars['String']>,
  ticket_in?: Maybe<Array<Scalars['String']>>,
  ticket_not_in?: Maybe<Array<Scalars['String']>>,
  ticket_lt?: Maybe<Scalars['String']>,
  ticket_lte?: Maybe<Scalars['String']>,
  ticket_gt?: Maybe<Scalars['String']>,
  ticket_gte?: Maybe<Scalars['String']>,
  ticket_contains?: Maybe<Scalars['String']>,
  ticket_not_contains?: Maybe<Scalars['String']>,
  ticket_starts_with?: Maybe<Scalars['String']>,
  ticket_not_starts_with?: Maybe<Scalars['String']>,
  ticket_ends_with?: Maybe<Scalars['String']>,
  ticket_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
  issue_every?: Maybe<IssueWhereInput>,
  issue_some?: Maybe<IssueWhereInput>,
  issue_none?: Maybe<IssueWhereInput>,
};

export type TrackingIssueWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingLocation = Node & {
  __typename?: 'TrackingLocation',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  messenger?: Maybe<Scalars['String']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  orientation?: Maybe<Scalars['Float']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingLocationConnection = {
  __typename?: 'TrackingLocationConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingLocationEdge>>,
  aggregate: AggregateTrackingLocation,
};

export type TrackingLocationCreateInput = {
  id?: Maybe<Scalars['ID']>,
  messenger?: Maybe<Scalars['String']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  orientation?: Maybe<Scalars['Float']>,
  tracking?: Maybe<TrackingCreateOneWithoutLastLocationInput>,
};

export type TrackingLocationCreateOneWithoutTrackingInput = {
  create?: Maybe<TrackingLocationCreateWithoutTrackingInput>,
  connect?: Maybe<TrackingLocationWhereUniqueInput>,
};

export type TrackingLocationCreateWithoutTrackingInput = {
  id?: Maybe<Scalars['ID']>,
  messenger?: Maybe<Scalars['String']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  orientation?: Maybe<Scalars['Float']>,
};

export type TrackingLocationEdge = {
  __typename?: 'TrackingLocationEdge',
  node: TrackingLocation,
  cursor: Scalars['String'],
};

export enum TrackingLocationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessengerAsc = 'messenger_ASC',
  MessengerDesc = 'messenger_DESC',
  LatitudeAsc = 'latitude_ASC',
  LatitudeDesc = 'latitude_DESC',
  LongitudeAsc = 'longitude_ASC',
  LongitudeDesc = 'longitude_DESC',
  OrientationAsc = 'orientation_ASC',
  OrientationDesc = 'orientation_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type TrackingLocationPreviousValues = {
  __typename?: 'TrackingLocationPreviousValues',
  id: Scalars['ID'],
  messenger?: Maybe<Scalars['String']>,
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  orientation?: Maybe<Scalars['Float']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingLocationSubscriptionPayload = {
  __typename?: 'TrackingLocationSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingLocation>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingLocationPreviousValues>,
};

export type TrackingLocationSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingLocationSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingLocationSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingLocationSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingLocationWhereInput>,
};

export type TrackingLocationUpdateInput = {
  messenger?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  orientation?: Maybe<Scalars['Float']>,
  tracking?: Maybe<TrackingUpdateOneWithoutLastLocationInput>,
};

export type TrackingLocationUpdateManyMutationInput = {
  messenger?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  orientation?: Maybe<Scalars['Float']>,
};

export type TrackingLocationUpdateOneWithoutTrackingInput = {
  create?: Maybe<TrackingLocationCreateWithoutTrackingInput>,
  connect?: Maybe<TrackingLocationWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingLocationUpdateWithoutTrackingDataInput>,
  upsert?: Maybe<TrackingLocationUpsertWithoutTrackingInput>,
};

export type TrackingLocationUpdateWithoutTrackingDataInput = {
  messenger?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  orientation?: Maybe<Scalars['Float']>,
};

export type TrackingLocationUpsertWithoutTrackingInput = {
  update: TrackingLocationUpdateWithoutTrackingDataInput,
  create: TrackingLocationCreateWithoutTrackingInput,
};

export type TrackingLocationWhereInput = {
  AND?: Maybe<Array<TrackingLocationWhereInput>>,
  OR?: Maybe<Array<TrackingLocationWhereInput>>,
  NOT?: Maybe<Array<TrackingLocationWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  messenger?: Maybe<Scalars['String']>,
  messenger_not?: Maybe<Scalars['String']>,
  messenger_in?: Maybe<Array<Scalars['String']>>,
  messenger_not_in?: Maybe<Array<Scalars['String']>>,
  messenger_lt?: Maybe<Scalars['String']>,
  messenger_lte?: Maybe<Scalars['String']>,
  messenger_gt?: Maybe<Scalars['String']>,
  messenger_gte?: Maybe<Scalars['String']>,
  messenger_contains?: Maybe<Scalars['String']>,
  messenger_not_contains?: Maybe<Scalars['String']>,
  messenger_starts_with?: Maybe<Scalars['String']>,
  messenger_not_starts_with?: Maybe<Scalars['String']>,
  messenger_ends_with?: Maybe<Scalars['String']>,
  messenger_not_ends_with?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  latitude_not?: Maybe<Scalars['Float']>,
  latitude_in?: Maybe<Array<Scalars['Float']>>,
  latitude_not_in?: Maybe<Array<Scalars['Float']>>,
  latitude_lt?: Maybe<Scalars['Float']>,
  latitude_lte?: Maybe<Scalars['Float']>,
  latitude_gt?: Maybe<Scalars['Float']>,
  latitude_gte?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  longitude_not?: Maybe<Scalars['Float']>,
  longitude_in?: Maybe<Array<Scalars['Float']>>,
  longitude_not_in?: Maybe<Array<Scalars['Float']>>,
  longitude_lt?: Maybe<Scalars['Float']>,
  longitude_lte?: Maybe<Scalars['Float']>,
  longitude_gt?: Maybe<Scalars['Float']>,
  longitude_gte?: Maybe<Scalars['Float']>,
  orientation?: Maybe<Scalars['Float']>,
  orientation_not?: Maybe<Scalars['Float']>,
  orientation_in?: Maybe<Array<Scalars['Float']>>,
  orientation_not_in?: Maybe<Array<Scalars['Float']>>,
  orientation_lt?: Maybe<Scalars['Float']>,
  orientation_lte?: Maybe<Scalars['Float']>,
  orientation_gt?: Maybe<Scalars['Float']>,
  orientation_gte?: Maybe<Scalars['Float']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
};

export type TrackingLocationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum TrackingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  GuideAsc = 'guide_ASC',
  GuideDesc = 'guide_DESC',
  OrderIdAsc = 'orderId_ASC',
  OrderIdDesc = 'orderId_DESC',
  MessengerAsc = 'messenger_ASC',
  MessengerDesc = 'messenger_DESC',
  MessengerObjAsc = 'messengerObj_ASC',
  MessengerObjDesc = 'messengerObj_DESC',
  ClientAsc = 'client_ASC',
  ClientDesc = 'client_DESC',
  ClientObjAsc = 'clientObj_ASC',
  ClientObjDesc = 'clientObj_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  LastAddressAsc = 'lastAddress_ASC',
  LastAddressDesc = 'lastAddress_DESC',
  LastLocationRawAsc = 'lastLocationRaw_ASC',
  LastLocationRawDesc = 'lastLocationRaw_DESC',
  ShorterUrlAsc = 'shorterUrl_ASC',
  ShorterUrlDesc = 'shorterUrl_DESC',
  FinishedAsc = 'finished_ASC',
  FinishedDesc = 'finished_DESC',
  OriginalAddressAsc = 'originalAddress_ASC',
  OriginalAddressDesc = 'originalAddress_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingPoint = Node & {
  __typename?: 'TrackingPoint',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  position: Scalars['Int'],
  orderPointId: Scalars['String'],
  eta: Scalars['Int'],
  timeCompleted: Scalars['Int'],
  lastReceiver?: Maybe<TrackingReceiver>,
  receivers?: Maybe<Array<TrackingReceiver>>,
  lastAddress?: Maybe<TrackingAddress>,
  addresses?: Maybe<Array<TrackingAddress>>,
  isFinished?: Maybe<Scalars['Boolean']>,
  status?: Maybe<TrackingStatus>,
  uniqueHash?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};


export type TrackingPointReceiversArgs = {
  where?: Maybe<TrackingReceiverWhereInput>,
  orderBy?: Maybe<TrackingReceiverOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TrackingPointAddressesArgs = {
  where?: Maybe<TrackingAddressWhereInput>,
  orderBy?: Maybe<TrackingAddressOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TrackingPointConnection = {
  __typename?: 'TrackingPointConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingPointEdge>>,
  aggregate: AggregateTrackingPoint,
};

export type TrackingPointCreateInput = {
  id?: Maybe<Scalars['ID']>,
  position: Scalars['Int'],
  orderPointId: Scalars['String'],
  eta: Scalars['Int'],
  timeCompleted: Scalars['Int'],
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingCreateOneWithoutPointsInput>,
  lastReceiver?: Maybe<TrackingReceiverCreateOneInput>,
  receivers?: Maybe<TrackingReceiverCreateManyInput>,
  lastAddress?: Maybe<TrackingAddressCreateOneInput>,
  addresses?: Maybe<TrackingAddressCreateManyWithoutPointInput>,
  status?: Maybe<TrackingStatusCreateOneInput>,
};

export type TrackingPointCreateManyWithoutAddressesInput = {
  create?: Maybe<Array<TrackingPointCreateWithoutAddressesInput>>,
  connect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
};

export type TrackingPointCreateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingPointCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
};

export type TrackingPointCreateWithoutAddressesInput = {
  id?: Maybe<Scalars['ID']>,
  position: Scalars['Int'],
  orderPointId: Scalars['String'],
  eta: Scalars['Int'],
  timeCompleted: Scalars['Int'],
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingCreateOneWithoutPointsInput>,
  lastReceiver?: Maybe<TrackingReceiverCreateOneInput>,
  receivers?: Maybe<TrackingReceiverCreateManyInput>,
  lastAddress?: Maybe<TrackingAddressCreateOneInput>,
  status?: Maybe<TrackingStatusCreateOneInput>,
};

export type TrackingPointCreateWithoutTrackingInput = {
  id?: Maybe<Scalars['ID']>,
  position: Scalars['Int'],
  orderPointId: Scalars['String'],
  eta: Scalars['Int'],
  timeCompleted: Scalars['Int'],
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  lastReceiver?: Maybe<TrackingReceiverCreateOneInput>,
  receivers?: Maybe<TrackingReceiverCreateManyInput>,
  lastAddress?: Maybe<TrackingAddressCreateOneInput>,
  addresses?: Maybe<TrackingAddressCreateManyWithoutPointInput>,
  status?: Maybe<TrackingStatusCreateOneInput>,
};

export type TrackingPointEdge = {
  __typename?: 'TrackingPointEdge',
  node: TrackingPoint,
  cursor: Scalars['String'],
};

export enum TrackingPointOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  OrderPointIdAsc = 'orderPointId_ASC',
  OrderPointIdDesc = 'orderPointId_DESC',
  EtaAsc = 'eta_ASC',
  EtaDesc = 'eta_DESC',
  TimeCompletedAsc = 'timeCompleted_ASC',
  TimeCompletedDesc = 'timeCompleted_DESC',
  IsFinishedAsc = 'isFinished_ASC',
  IsFinishedDesc = 'isFinished_DESC',
  UniqueHashAsc = 'uniqueHash_ASC',
  UniqueHashDesc = 'uniqueHash_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingPointPreviousValues = {
  __typename?: 'TrackingPointPreviousValues',
  id: Scalars['ID'],
  position: Scalars['Int'],
  orderPointId: Scalars['String'],
  eta: Scalars['Int'],
  timeCompleted: Scalars['Int'],
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingPointScalarWhereInput = {
  AND?: Maybe<Array<TrackingPointScalarWhereInput>>,
  OR?: Maybe<Array<TrackingPointScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingPointScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  position?: Maybe<Scalars['Int']>,
  position_not?: Maybe<Scalars['Int']>,
  position_in?: Maybe<Array<Scalars['Int']>>,
  position_not_in?: Maybe<Array<Scalars['Int']>>,
  position_lt?: Maybe<Scalars['Int']>,
  position_lte?: Maybe<Scalars['Int']>,
  position_gt?: Maybe<Scalars['Int']>,
  position_gte?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  orderPointId_not?: Maybe<Scalars['String']>,
  orderPointId_in?: Maybe<Array<Scalars['String']>>,
  orderPointId_not_in?: Maybe<Array<Scalars['String']>>,
  orderPointId_lt?: Maybe<Scalars['String']>,
  orderPointId_lte?: Maybe<Scalars['String']>,
  orderPointId_gt?: Maybe<Scalars['String']>,
  orderPointId_gte?: Maybe<Scalars['String']>,
  orderPointId_contains?: Maybe<Scalars['String']>,
  orderPointId_not_contains?: Maybe<Scalars['String']>,
  orderPointId_starts_with?: Maybe<Scalars['String']>,
  orderPointId_not_starts_with?: Maybe<Scalars['String']>,
  orderPointId_ends_with?: Maybe<Scalars['String']>,
  orderPointId_not_ends_with?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  eta_not?: Maybe<Scalars['Int']>,
  eta_in?: Maybe<Array<Scalars['Int']>>,
  eta_not_in?: Maybe<Array<Scalars['Int']>>,
  eta_lt?: Maybe<Scalars['Int']>,
  eta_lte?: Maybe<Scalars['Int']>,
  eta_gt?: Maybe<Scalars['Int']>,
  eta_gte?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  timeCompleted_not?: Maybe<Scalars['Int']>,
  timeCompleted_in?: Maybe<Array<Scalars['Int']>>,
  timeCompleted_not_in?: Maybe<Array<Scalars['Int']>>,
  timeCompleted_lt?: Maybe<Scalars['Int']>,
  timeCompleted_lte?: Maybe<Scalars['Int']>,
  timeCompleted_gt?: Maybe<Scalars['Int']>,
  timeCompleted_gte?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  isFinished_not?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  uniqueHash_not?: Maybe<Scalars['String']>,
  uniqueHash_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_not_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_lt?: Maybe<Scalars['String']>,
  uniqueHash_lte?: Maybe<Scalars['String']>,
  uniqueHash_gt?: Maybe<Scalars['String']>,
  uniqueHash_gte?: Maybe<Scalars['String']>,
  uniqueHash_contains?: Maybe<Scalars['String']>,
  uniqueHash_not_contains?: Maybe<Scalars['String']>,
  uniqueHash_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_not_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_ends_with?: Maybe<Scalars['String']>,
  uniqueHash_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingPointSubscriptionPayload = {
  __typename?: 'TrackingPointSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingPoint>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingPointPreviousValues>,
};

export type TrackingPointSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingPointSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingPointSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingPointSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingPointWhereInput>,
};

export type TrackingPointUpdateInput = {
  position?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneWithoutPointsInput>,
  lastReceiver?: Maybe<TrackingReceiverUpdateOneInput>,
  receivers?: Maybe<TrackingReceiverUpdateManyInput>,
  lastAddress?: Maybe<TrackingAddressUpdateOneInput>,
  addresses?: Maybe<TrackingAddressUpdateManyWithoutPointInput>,
  status?: Maybe<TrackingStatusUpdateOneInput>,
};

export type TrackingPointUpdateManyDataInput = {
  position?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingPointUpdateManyMutationInput = {
  position?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingPointUpdateManyWithoutAddressesInput = {
  create?: Maybe<Array<TrackingPointCreateWithoutAddressesInput>>,
  connect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  set?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  update?: Maybe<Array<TrackingPointUpdateWithWhereUniqueWithoutAddressesInput>>,
  updateMany?: Maybe<Array<TrackingPointUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingPointScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingPointUpsertWithWhereUniqueWithoutAddressesInput>>,
};

export type TrackingPointUpdateManyWithoutTrackingInput = {
  create?: Maybe<Array<TrackingPointCreateWithoutTrackingInput>>,
  connect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  set?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingPointWhereUniqueInput>>,
  update?: Maybe<Array<TrackingPointUpdateWithWhereUniqueWithoutTrackingInput>>,
  updateMany?: Maybe<Array<TrackingPointUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingPointScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingPointUpsertWithWhereUniqueWithoutTrackingInput>>,
};

export type TrackingPointUpdateManyWithWhereNestedInput = {
  where: TrackingPointScalarWhereInput,
  data: TrackingPointUpdateManyDataInput,
};

export type TrackingPointUpdateWithoutAddressesDataInput = {
  position?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneWithoutPointsInput>,
  lastReceiver?: Maybe<TrackingReceiverUpdateOneInput>,
  receivers?: Maybe<TrackingReceiverUpdateManyInput>,
  lastAddress?: Maybe<TrackingAddressUpdateOneInput>,
  status?: Maybe<TrackingStatusUpdateOneInput>,
};

export type TrackingPointUpdateWithoutTrackingDataInput = {
  position?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  lastReceiver?: Maybe<TrackingReceiverUpdateOneInput>,
  receivers?: Maybe<TrackingReceiverUpdateManyInput>,
  lastAddress?: Maybe<TrackingAddressUpdateOneInput>,
  addresses?: Maybe<TrackingAddressUpdateManyWithoutPointInput>,
  status?: Maybe<TrackingStatusUpdateOneInput>,
};

export type TrackingPointUpdateWithWhereUniqueWithoutAddressesInput = {
  where: TrackingPointWhereUniqueInput,
  data: TrackingPointUpdateWithoutAddressesDataInput,
};

export type TrackingPointUpdateWithWhereUniqueWithoutTrackingInput = {
  where: TrackingPointWhereUniqueInput,
  data: TrackingPointUpdateWithoutTrackingDataInput,
};

export type TrackingPointUpsertWithWhereUniqueWithoutAddressesInput = {
  where: TrackingPointWhereUniqueInput,
  update: TrackingPointUpdateWithoutAddressesDataInput,
  create: TrackingPointCreateWithoutAddressesInput,
};

export type TrackingPointUpsertWithWhereUniqueWithoutTrackingInput = {
  where: TrackingPointWhereUniqueInput,
  update: TrackingPointUpdateWithoutTrackingDataInput,
  create: TrackingPointCreateWithoutTrackingInput,
};

export type TrackingPointWhereInput = {
  AND?: Maybe<Array<TrackingPointWhereInput>>,
  OR?: Maybe<Array<TrackingPointWhereInput>>,
  NOT?: Maybe<Array<TrackingPointWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  position?: Maybe<Scalars['Int']>,
  position_not?: Maybe<Scalars['Int']>,
  position_in?: Maybe<Array<Scalars['Int']>>,
  position_not_in?: Maybe<Array<Scalars['Int']>>,
  position_lt?: Maybe<Scalars['Int']>,
  position_lte?: Maybe<Scalars['Int']>,
  position_gt?: Maybe<Scalars['Int']>,
  position_gte?: Maybe<Scalars['Int']>,
  orderPointId?: Maybe<Scalars['String']>,
  orderPointId_not?: Maybe<Scalars['String']>,
  orderPointId_in?: Maybe<Array<Scalars['String']>>,
  orderPointId_not_in?: Maybe<Array<Scalars['String']>>,
  orderPointId_lt?: Maybe<Scalars['String']>,
  orderPointId_lte?: Maybe<Scalars['String']>,
  orderPointId_gt?: Maybe<Scalars['String']>,
  orderPointId_gte?: Maybe<Scalars['String']>,
  orderPointId_contains?: Maybe<Scalars['String']>,
  orderPointId_not_contains?: Maybe<Scalars['String']>,
  orderPointId_starts_with?: Maybe<Scalars['String']>,
  orderPointId_not_starts_with?: Maybe<Scalars['String']>,
  orderPointId_ends_with?: Maybe<Scalars['String']>,
  orderPointId_not_ends_with?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['Int']>,
  eta_not?: Maybe<Scalars['Int']>,
  eta_in?: Maybe<Array<Scalars['Int']>>,
  eta_not_in?: Maybe<Array<Scalars['Int']>>,
  eta_lt?: Maybe<Scalars['Int']>,
  eta_lte?: Maybe<Scalars['Int']>,
  eta_gt?: Maybe<Scalars['Int']>,
  eta_gte?: Maybe<Scalars['Int']>,
  timeCompleted?: Maybe<Scalars['Int']>,
  timeCompleted_not?: Maybe<Scalars['Int']>,
  timeCompleted_in?: Maybe<Array<Scalars['Int']>>,
  timeCompleted_not_in?: Maybe<Array<Scalars['Int']>>,
  timeCompleted_lt?: Maybe<Scalars['Int']>,
  timeCompleted_lte?: Maybe<Scalars['Int']>,
  timeCompleted_gt?: Maybe<Scalars['Int']>,
  timeCompleted_gte?: Maybe<Scalars['Int']>,
  isFinished?: Maybe<Scalars['Boolean']>,
  isFinished_not?: Maybe<Scalars['Boolean']>,
  uniqueHash?: Maybe<Scalars['String']>,
  uniqueHash_not?: Maybe<Scalars['String']>,
  uniqueHash_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_not_in?: Maybe<Array<Scalars['String']>>,
  uniqueHash_lt?: Maybe<Scalars['String']>,
  uniqueHash_lte?: Maybe<Scalars['String']>,
  uniqueHash_gt?: Maybe<Scalars['String']>,
  uniqueHash_gte?: Maybe<Scalars['String']>,
  uniqueHash_contains?: Maybe<Scalars['String']>,
  uniqueHash_not_contains?: Maybe<Scalars['String']>,
  uniqueHash_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_not_starts_with?: Maybe<Scalars['String']>,
  uniqueHash_ends_with?: Maybe<Scalars['String']>,
  uniqueHash_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
  lastReceiver?: Maybe<TrackingReceiverWhereInput>,
  receivers_every?: Maybe<TrackingReceiverWhereInput>,
  receivers_some?: Maybe<TrackingReceiverWhereInput>,
  receivers_none?: Maybe<TrackingReceiverWhereInput>,
  lastAddress?: Maybe<TrackingAddressWhereInput>,
  addresses_every?: Maybe<TrackingAddressWhereInput>,
  addresses_some?: Maybe<TrackingAddressWhereInput>,
  addresses_none?: Maybe<TrackingAddressWhereInput>,
  status?: Maybe<TrackingStatusWhereInput>,
};

export type TrackingPointWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  orderPointId?: Maybe<Scalars['String']>,
  uniqueHash?: Maybe<Scalars['String']>,
};

export type TrackingPreviousValues = {
  __typename?: 'TrackingPreviousValues',
  id: Scalars['ID'],
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingReceiver = Node & {
  __typename?: 'TrackingReceiver',
  id: Scalars['ID'],
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingReceiverConnection = {
  __typename?: 'TrackingReceiverConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingReceiverEdge>>,
  aggregate: AggregateTrackingReceiver,
};

export type TrackingReceiverCreateInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type TrackingReceiverCreateManyInput = {
  create?: Maybe<Array<TrackingReceiverCreateInput>>,
  connect?: Maybe<Array<TrackingReceiverWhereUniqueInput>>,
};

export type TrackingReceiverCreateOneInput = {
  create?: Maybe<TrackingReceiverCreateInput>,
  connect?: Maybe<TrackingReceiverWhereUniqueInput>,
};

export type TrackingReceiverEdge = {
  __typename?: 'TrackingReceiverEdge',
  node: TrackingReceiver,
  cursor: Scalars['String'],
};

export enum TrackingReceiverOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingReceiverPreviousValues = {
  __typename?: 'TrackingReceiverPreviousValues',
  id: Scalars['ID'],
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingReceiverScalarWhereInput = {
  AND?: Maybe<Array<TrackingReceiverScalarWhereInput>>,
  OR?: Maybe<Array<TrackingReceiverScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingReceiverScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  phone_not?: Maybe<Scalars['String']>,
  phone_in?: Maybe<Array<Scalars['String']>>,
  phone_not_in?: Maybe<Array<Scalars['String']>>,
  phone_lt?: Maybe<Scalars['String']>,
  phone_lte?: Maybe<Scalars['String']>,
  phone_gt?: Maybe<Scalars['String']>,
  phone_gte?: Maybe<Scalars['String']>,
  phone_contains?: Maybe<Scalars['String']>,
  phone_not_contains?: Maybe<Scalars['String']>,
  phone_starts_with?: Maybe<Scalars['String']>,
  phone_not_starts_with?: Maybe<Scalars['String']>,
  phone_ends_with?: Maybe<Scalars['String']>,
  phone_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_not?: Maybe<Scalars['String']>,
  email_in?: Maybe<Array<Scalars['String']>>,
  email_not_in?: Maybe<Array<Scalars['String']>>,
  email_lt?: Maybe<Scalars['String']>,
  email_lte?: Maybe<Scalars['String']>,
  email_gt?: Maybe<Scalars['String']>,
  email_gte?: Maybe<Scalars['String']>,
  email_contains?: Maybe<Scalars['String']>,
  email_not_contains?: Maybe<Scalars['String']>,
  email_starts_with?: Maybe<Scalars['String']>,
  email_not_starts_with?: Maybe<Scalars['String']>,
  email_ends_with?: Maybe<Scalars['String']>,
  email_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingReceiverSubscriptionPayload = {
  __typename?: 'TrackingReceiverSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingReceiver>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingReceiverPreviousValues>,
};

export type TrackingReceiverSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingReceiverSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingReceiverSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingReceiverSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingReceiverWhereInput>,
};

export type TrackingReceiverUpdateDataInput = {
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type TrackingReceiverUpdateInput = {
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type TrackingReceiverUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type TrackingReceiverUpdateManyInput = {
  create?: Maybe<Array<TrackingReceiverCreateInput>>,
  connect?: Maybe<Array<TrackingReceiverWhereUniqueInput>>,
  set?: Maybe<Array<TrackingReceiverWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingReceiverWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingReceiverWhereUniqueInput>>,
  update?: Maybe<Array<TrackingReceiverUpdateWithWhereUniqueNestedInput>>,
  updateMany?: Maybe<Array<TrackingReceiverUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingReceiverScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingReceiverUpsertWithWhereUniqueNestedInput>>,
};

export type TrackingReceiverUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type TrackingReceiverUpdateManyWithWhereNestedInput = {
  where: TrackingReceiverScalarWhereInput,
  data: TrackingReceiverUpdateManyDataInput,
};

export type TrackingReceiverUpdateOneInput = {
  create?: Maybe<TrackingReceiverCreateInput>,
  connect?: Maybe<TrackingReceiverWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingReceiverUpdateDataInput>,
  upsert?: Maybe<TrackingReceiverUpsertNestedInput>,
};

export type TrackingReceiverUpdateWithWhereUniqueNestedInput = {
  where: TrackingReceiverWhereUniqueInput,
  data: TrackingReceiverUpdateDataInput,
};

export type TrackingReceiverUpsertNestedInput = {
  update: TrackingReceiverUpdateDataInput,
  create: TrackingReceiverCreateInput,
};

export type TrackingReceiverUpsertWithWhereUniqueNestedInput = {
  where: TrackingReceiverWhereUniqueInput,
  update: TrackingReceiverUpdateDataInput,
  create: TrackingReceiverCreateInput,
};

export type TrackingReceiverWhereInput = {
  AND?: Maybe<Array<TrackingReceiverWhereInput>>,
  OR?: Maybe<Array<TrackingReceiverWhereInput>>,
  NOT?: Maybe<Array<TrackingReceiverWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  phone_not?: Maybe<Scalars['String']>,
  phone_in?: Maybe<Array<Scalars['String']>>,
  phone_not_in?: Maybe<Array<Scalars['String']>>,
  phone_lt?: Maybe<Scalars['String']>,
  phone_lte?: Maybe<Scalars['String']>,
  phone_gt?: Maybe<Scalars['String']>,
  phone_gte?: Maybe<Scalars['String']>,
  phone_contains?: Maybe<Scalars['String']>,
  phone_not_contains?: Maybe<Scalars['String']>,
  phone_starts_with?: Maybe<Scalars['String']>,
  phone_not_starts_with?: Maybe<Scalars['String']>,
  phone_ends_with?: Maybe<Scalars['String']>,
  phone_not_ends_with?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_not?: Maybe<Scalars['String']>,
  email_in?: Maybe<Array<Scalars['String']>>,
  email_not_in?: Maybe<Array<Scalars['String']>>,
  email_lt?: Maybe<Scalars['String']>,
  email_lte?: Maybe<Scalars['String']>,
  email_gt?: Maybe<Scalars['String']>,
  email_gte?: Maybe<Scalars['String']>,
  email_contains?: Maybe<Scalars['String']>,
  email_not_contains?: Maybe<Scalars['String']>,
  email_starts_with?: Maybe<Scalars['String']>,
  email_not_starts_with?: Maybe<Scalars['String']>,
  email_ends_with?: Maybe<Scalars['String']>,
  email_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingReceiverWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingScalarWhereInput = {
  AND?: Maybe<Array<TrackingScalarWhereInput>>,
  OR?: Maybe<Array<TrackingScalarWhereInput>>,
  NOT?: Maybe<Array<TrackingScalarWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  guide_not?: Maybe<Scalars['String']>,
  guide_in?: Maybe<Array<Scalars['String']>>,
  guide_not_in?: Maybe<Array<Scalars['String']>>,
  guide_lt?: Maybe<Scalars['String']>,
  guide_lte?: Maybe<Scalars['String']>,
  guide_gt?: Maybe<Scalars['String']>,
  guide_gte?: Maybe<Scalars['String']>,
  guide_contains?: Maybe<Scalars['String']>,
  guide_not_contains?: Maybe<Scalars['String']>,
  guide_starts_with?: Maybe<Scalars['String']>,
  guide_not_starts_with?: Maybe<Scalars['String']>,
  guide_ends_with?: Maybe<Scalars['String']>,
  guide_not_ends_with?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  orderId_not?: Maybe<Scalars['Int']>,
  orderId_in?: Maybe<Array<Scalars['Int']>>,
  orderId_not_in?: Maybe<Array<Scalars['Int']>>,
  orderId_lt?: Maybe<Scalars['Int']>,
  orderId_lte?: Maybe<Scalars['Int']>,
  orderId_gt?: Maybe<Scalars['Int']>,
  orderId_gte?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messenger_not?: Maybe<Scalars['String']>,
  messenger_in?: Maybe<Array<Scalars['String']>>,
  messenger_not_in?: Maybe<Array<Scalars['String']>>,
  messenger_lt?: Maybe<Scalars['String']>,
  messenger_lte?: Maybe<Scalars['String']>,
  messenger_gt?: Maybe<Scalars['String']>,
  messenger_gte?: Maybe<Scalars['String']>,
  messenger_contains?: Maybe<Scalars['String']>,
  messenger_not_contains?: Maybe<Scalars['String']>,
  messenger_starts_with?: Maybe<Scalars['String']>,
  messenger_not_starts_with?: Maybe<Scalars['String']>,
  messenger_ends_with?: Maybe<Scalars['String']>,
  messenger_not_ends_with?: Maybe<Scalars['String']>,
  client?: Maybe<Scalars['String']>,
  client_not?: Maybe<Scalars['String']>,
  client_in?: Maybe<Array<Scalars['String']>>,
  client_not_in?: Maybe<Array<Scalars['String']>>,
  client_lt?: Maybe<Scalars['String']>,
  client_lte?: Maybe<Scalars['String']>,
  client_gt?: Maybe<Scalars['String']>,
  client_gte?: Maybe<Scalars['String']>,
  client_contains?: Maybe<Scalars['String']>,
  client_not_contains?: Maybe<Scalars['String']>,
  client_starts_with?: Maybe<Scalars['String']>,
  client_not_starts_with?: Maybe<Scalars['String']>,
  client_ends_with?: Maybe<Scalars['String']>,
  client_not_ends_with?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  location_not?: Maybe<Scalars['String']>,
  location_in?: Maybe<Array<Scalars['String']>>,
  location_not_in?: Maybe<Array<Scalars['String']>>,
  location_lt?: Maybe<Scalars['String']>,
  location_lte?: Maybe<Scalars['String']>,
  location_gt?: Maybe<Scalars['String']>,
  location_gte?: Maybe<Scalars['String']>,
  location_contains?: Maybe<Scalars['String']>,
  location_not_contains?: Maybe<Scalars['String']>,
  location_starts_with?: Maybe<Scalars['String']>,
  location_not_starts_with?: Maybe<Scalars['String']>,
  location_ends_with?: Maybe<Scalars['String']>,
  location_not_ends_with?: Maybe<Scalars['String']>,
  shorterUrl?: Maybe<Scalars['String']>,
  shorterUrl_not?: Maybe<Scalars['String']>,
  shorterUrl_in?: Maybe<Array<Scalars['String']>>,
  shorterUrl_not_in?: Maybe<Array<Scalars['String']>>,
  shorterUrl_lt?: Maybe<Scalars['String']>,
  shorterUrl_lte?: Maybe<Scalars['String']>,
  shorterUrl_gt?: Maybe<Scalars['String']>,
  shorterUrl_gte?: Maybe<Scalars['String']>,
  shorterUrl_contains?: Maybe<Scalars['String']>,
  shorterUrl_not_contains?: Maybe<Scalars['String']>,
  shorterUrl_starts_with?: Maybe<Scalars['String']>,
  shorterUrl_not_starts_with?: Maybe<Scalars['String']>,
  shorterUrl_ends_with?: Maybe<Scalars['String']>,
  shorterUrl_not_ends_with?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  finished_not?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  originalAddress_not?: Maybe<Scalars['String']>,
  originalAddress_in?: Maybe<Array<Scalars['String']>>,
  originalAddress_not_in?: Maybe<Array<Scalars['String']>>,
  originalAddress_lt?: Maybe<Scalars['String']>,
  originalAddress_lte?: Maybe<Scalars['String']>,
  originalAddress_gt?: Maybe<Scalars['String']>,
  originalAddress_gte?: Maybe<Scalars['String']>,
  originalAddress_contains?: Maybe<Scalars['String']>,
  originalAddress_not_contains?: Maybe<Scalars['String']>,
  originalAddress_starts_with?: Maybe<Scalars['String']>,
  originalAddress_not_starts_with?: Maybe<Scalars['String']>,
  originalAddress_ends_with?: Maybe<Scalars['String']>,
  originalAddress_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
};

export type TrackingStatus = Node & {
  __typename?: 'TrackingStatus',
  id: Scalars['ID'],
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description: Scalars['String'],
  settings: Scalars['String'],
  order: Scalars['Int'],
  tracking?: Maybe<Array<Tracking>>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};


export type TrackingStatusTrackingArgs = {
  where?: Maybe<TrackingWhereInput>,
  orderBy?: Maybe<TrackingOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TrackingStatusConnection = {
  __typename?: 'TrackingStatusConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingStatusEdge>>,
  aggregate: AggregateTrackingStatus,
};

export type TrackingStatusCreateInput = {
  id?: Maybe<Scalars['ID']>,
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description: Scalars['String'],
  settings: Scalars['String'],
  order: Scalars['Int'],
  tracking?: Maybe<TrackingCreateManyWithoutLastStatusInput>,
};

export type TrackingStatusCreateOneInput = {
  create?: Maybe<TrackingStatusCreateInput>,
  connect?: Maybe<TrackingStatusWhereUniqueInput>,
};

export type TrackingStatusCreateOneWithoutTrackingInput = {
  create?: Maybe<TrackingStatusCreateWithoutTrackingInput>,
  connect?: Maybe<TrackingStatusWhereUniqueInput>,
};

export type TrackingStatusCreateWithoutTrackingInput = {
  id?: Maybe<Scalars['ID']>,
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description: Scalars['String'],
  settings: Scalars['String'],
  order: Scalars['Int'],
};

export type TrackingStatusEdge = {
  __typename?: 'TrackingStatusEdge',
  node: TrackingStatus,
  cursor: Scalars['String'],
};

export enum TrackingStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ActiveTitleAsc = 'activeTitle_ASC',
  ActiveTitleDesc = 'activeTitle_DESC',
  ActiveSubtitleAsc = 'activeSubtitle_ASC',
  ActiveSubtitleDesc = 'activeSubtitle_DESC',
  ActiveDescriptionAsc = 'activeDescription_ASC',
  ActiveDescriptionDesc = 'activeDescription_DESC',
  InactiveDescriptionAsc = 'inactiveDescription_ASC',
  InactiveDescriptionDesc = 'inactiveDescription_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SettingsAsc = 'settings_ASC',
  SettingsDesc = 'settings_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TrackingStatusPreviousValues = {
  __typename?: 'TrackingStatusPreviousValues',
  id: Scalars['ID'],
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description: Scalars['String'],
  settings: Scalars['String'],
  order: Scalars['Int'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingStatusSubscriptionPayload = {
  __typename?: 'TrackingStatusSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingStatus>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingStatusPreviousValues>,
};

export type TrackingStatusSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingStatusSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingStatusSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingStatusSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingStatusWhereInput>,
};

export type TrackingStatusUpdateDataInput = {
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  tracking?: Maybe<TrackingUpdateManyWithoutLastStatusInput>,
};

export type TrackingStatusUpdateInput = {
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  tracking?: Maybe<TrackingUpdateManyWithoutLastStatusInput>,
};

export type TrackingStatusUpdateManyMutationInput = {
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
};

export type TrackingStatusUpdateOneInput = {
  create?: Maybe<TrackingStatusCreateInput>,
  connect?: Maybe<TrackingStatusWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingStatusUpdateDataInput>,
  upsert?: Maybe<TrackingStatusUpsertNestedInput>,
};

export type TrackingStatusUpdateOneWithoutTrackingInput = {
  create?: Maybe<TrackingStatusCreateWithoutTrackingInput>,
  connect?: Maybe<TrackingStatusWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingStatusUpdateWithoutTrackingDataInput>,
  upsert?: Maybe<TrackingStatusUpsertWithoutTrackingInput>,
};

export type TrackingStatusUpdateWithoutTrackingDataInput = {
  activeTitle?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
};

export type TrackingStatusUpsertNestedInput = {
  update: TrackingStatusUpdateDataInput,
  create: TrackingStatusCreateInput,
};

export type TrackingStatusUpsertWithoutTrackingInput = {
  update: TrackingStatusUpdateWithoutTrackingDataInput,
  create: TrackingStatusCreateWithoutTrackingInput,
};

export type TrackingStatusWhereInput = {
  AND?: Maybe<Array<TrackingStatusWhereInput>>,
  OR?: Maybe<Array<TrackingStatusWhereInput>>,
  NOT?: Maybe<Array<TrackingStatusWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  activeTitle?: Maybe<Scalars['String']>,
  activeTitle_not?: Maybe<Scalars['String']>,
  activeTitle_in?: Maybe<Array<Scalars['String']>>,
  activeTitle_not_in?: Maybe<Array<Scalars['String']>>,
  activeTitle_lt?: Maybe<Scalars['String']>,
  activeTitle_lte?: Maybe<Scalars['String']>,
  activeTitle_gt?: Maybe<Scalars['String']>,
  activeTitle_gte?: Maybe<Scalars['String']>,
  activeTitle_contains?: Maybe<Scalars['String']>,
  activeTitle_not_contains?: Maybe<Scalars['String']>,
  activeTitle_starts_with?: Maybe<Scalars['String']>,
  activeTitle_not_starts_with?: Maybe<Scalars['String']>,
  activeTitle_ends_with?: Maybe<Scalars['String']>,
  activeTitle_not_ends_with?: Maybe<Scalars['String']>,
  activeSubtitle?: Maybe<Scalars['String']>,
  activeSubtitle_not?: Maybe<Scalars['String']>,
  activeSubtitle_in?: Maybe<Array<Scalars['String']>>,
  activeSubtitle_not_in?: Maybe<Array<Scalars['String']>>,
  activeSubtitle_lt?: Maybe<Scalars['String']>,
  activeSubtitle_lte?: Maybe<Scalars['String']>,
  activeSubtitle_gt?: Maybe<Scalars['String']>,
  activeSubtitle_gte?: Maybe<Scalars['String']>,
  activeSubtitle_contains?: Maybe<Scalars['String']>,
  activeSubtitle_not_contains?: Maybe<Scalars['String']>,
  activeSubtitle_starts_with?: Maybe<Scalars['String']>,
  activeSubtitle_not_starts_with?: Maybe<Scalars['String']>,
  activeSubtitle_ends_with?: Maybe<Scalars['String']>,
  activeSubtitle_not_ends_with?: Maybe<Scalars['String']>,
  activeDescription?: Maybe<Scalars['String']>,
  activeDescription_not?: Maybe<Scalars['String']>,
  activeDescription_in?: Maybe<Array<Scalars['String']>>,
  activeDescription_not_in?: Maybe<Array<Scalars['String']>>,
  activeDescription_lt?: Maybe<Scalars['String']>,
  activeDescription_lte?: Maybe<Scalars['String']>,
  activeDescription_gt?: Maybe<Scalars['String']>,
  activeDescription_gte?: Maybe<Scalars['String']>,
  activeDescription_contains?: Maybe<Scalars['String']>,
  activeDescription_not_contains?: Maybe<Scalars['String']>,
  activeDescription_starts_with?: Maybe<Scalars['String']>,
  activeDescription_not_starts_with?: Maybe<Scalars['String']>,
  activeDescription_ends_with?: Maybe<Scalars['String']>,
  activeDescription_not_ends_with?: Maybe<Scalars['String']>,
  inactiveDescription?: Maybe<Scalars['String']>,
  inactiveDescription_not?: Maybe<Scalars['String']>,
  inactiveDescription_in?: Maybe<Array<Scalars['String']>>,
  inactiveDescription_not_in?: Maybe<Array<Scalars['String']>>,
  inactiveDescription_lt?: Maybe<Scalars['String']>,
  inactiveDescription_lte?: Maybe<Scalars['String']>,
  inactiveDescription_gt?: Maybe<Scalars['String']>,
  inactiveDescription_gte?: Maybe<Scalars['String']>,
  inactiveDescription_contains?: Maybe<Scalars['String']>,
  inactiveDescription_not_contains?: Maybe<Scalars['String']>,
  inactiveDescription_starts_with?: Maybe<Scalars['String']>,
  inactiveDescription_not_starts_with?: Maybe<Scalars['String']>,
  inactiveDescription_ends_with?: Maybe<Scalars['String']>,
  inactiveDescription_not_ends_with?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  description_not?: Maybe<Scalars['String']>,
  description_in?: Maybe<Array<Scalars['String']>>,
  description_not_in?: Maybe<Array<Scalars['String']>>,
  description_lt?: Maybe<Scalars['String']>,
  description_lte?: Maybe<Scalars['String']>,
  description_gt?: Maybe<Scalars['String']>,
  description_gte?: Maybe<Scalars['String']>,
  description_contains?: Maybe<Scalars['String']>,
  description_not_contains?: Maybe<Scalars['String']>,
  description_starts_with?: Maybe<Scalars['String']>,
  description_not_starts_with?: Maybe<Scalars['String']>,
  description_ends_with?: Maybe<Scalars['String']>,
  description_not_ends_with?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  settings_not?: Maybe<Scalars['String']>,
  settings_in?: Maybe<Array<Scalars['String']>>,
  settings_not_in?: Maybe<Array<Scalars['String']>>,
  settings_lt?: Maybe<Scalars['String']>,
  settings_lte?: Maybe<Scalars['String']>,
  settings_gt?: Maybe<Scalars['String']>,
  settings_gte?: Maybe<Scalars['String']>,
  settings_contains?: Maybe<Scalars['String']>,
  settings_not_contains?: Maybe<Scalars['String']>,
  settings_starts_with?: Maybe<Scalars['String']>,
  settings_not_starts_with?: Maybe<Scalars['String']>,
  settings_ends_with?: Maybe<Scalars['String']>,
  settings_not_ends_with?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  order_not?: Maybe<Scalars['Int']>,
  order_in?: Maybe<Array<Scalars['Int']>>,
  order_not_in?: Maybe<Array<Scalars['Int']>>,
  order_lt?: Maybe<Scalars['Int']>,
  order_lte?: Maybe<Scalars['Int']>,
  order_gt?: Maybe<Scalars['Int']>,
  order_gte?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  tracking_every?: Maybe<TrackingWhereInput>,
  tracking_some?: Maybe<TrackingWhereInput>,
  tracking_none?: Maybe<TrackingWhereInput>,
};

export type TrackingStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingSubscriptionPayload = {
  __typename?: 'TrackingSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<Tracking>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingPreviousValues>,
};

export type TrackingSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingWhereInput>,
};

export type TrackingUpdateDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateManyDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
};

export type TrackingUpdateManyMutationInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
};

export type TrackingUpdateManyWithoutLastStatusInput = {
  create?: Maybe<Array<TrackingCreateWithoutLastStatusInput>>,
  connect?: Maybe<Array<TrackingWhereUniqueInput>>,
  set?: Maybe<Array<TrackingWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingWhereUniqueInput>>,
  update?: Maybe<Array<TrackingUpdateWithWhereUniqueWithoutLastStatusInput>>,
  updateMany?: Maybe<Array<TrackingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingUpsertWithWhereUniqueWithoutLastStatusInput>>,
};

export type TrackingUpdateManyWithoutParentInput = {
  create?: Maybe<Array<TrackingCreateWithoutParentInput>>,
  connect?: Maybe<Array<TrackingWhereUniqueInput>>,
  set?: Maybe<Array<TrackingWhereUniqueInput>>,
  disconnect?: Maybe<Array<TrackingWhereUniqueInput>>,
  delete?: Maybe<Array<TrackingWhereUniqueInput>>,
  update?: Maybe<Array<TrackingUpdateWithWhereUniqueWithoutParentInput>>,
  updateMany?: Maybe<Array<TrackingUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<TrackingScalarWhereInput>>,
  upsert?: Maybe<Array<TrackingUpsertWithWhereUniqueWithoutParentInput>>,
};

export type TrackingUpdateManyWithWhereNestedInput = {
  where: TrackingScalarWhereInput,
  data: TrackingUpdateManyDataInput,
};

export type TrackingUpdateOneInput = {
  create?: Maybe<TrackingCreateInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateDataInput>,
  upsert?: Maybe<TrackingUpsertNestedInput>,
};

export type TrackingUpdateOneWithoutAlertsInput = {
  create?: Maybe<TrackingCreateWithoutAlertsInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateWithoutAlertsDataInput>,
  upsert?: Maybe<TrackingUpsertWithoutAlertsInput>,
};

export type TrackingUpdateOneWithoutChildrenInput = {
  create?: Maybe<TrackingCreateWithoutChildrenInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateWithoutChildrenDataInput>,
  upsert?: Maybe<TrackingUpsertWithoutChildrenInput>,
};

export type TrackingUpdateOneWithoutHistoryInput = {
  create?: Maybe<TrackingCreateWithoutHistoryInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateWithoutHistoryDataInput>,
  upsert?: Maybe<TrackingUpsertWithoutHistoryInput>,
};

export type TrackingUpdateOneWithoutLastLocationInput = {
  create?: Maybe<TrackingCreateWithoutLastLocationInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateWithoutLastLocationDataInput>,
  upsert?: Maybe<TrackingUpsertWithoutLastLocationInput>,
};

export type TrackingUpdateOneWithoutPointsInput = {
  create?: Maybe<TrackingCreateWithoutPointsInput>,
  connect?: Maybe<TrackingWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<TrackingUpdateWithoutPointsDataInput>,
  upsert?: Maybe<TrackingUpsertWithoutPointsInput>,
};

export type TrackingUpdateWithoutAlertsDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithoutChildrenDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithoutHistoryDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithoutLastLocationDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithoutLastStatusDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
};

export type TrackingUpdateWithoutParentDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  points?: Maybe<TrackingPointUpdateManyWithoutTrackingInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithoutPointsDataInput = {
  guide?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messengerObj?: Maybe<Scalars['Json']>,
  client?: Maybe<Scalars['String']>,
  clientObj?: Maybe<Scalars['Json']>,
  location?: Maybe<Scalars['String']>,
  lastAddress?: Maybe<Scalars['Json']>,
  lastLocationRaw?: Maybe<Scalars['Json']>,
  shorterUrl?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  lastLocation?: Maybe<TrackingLocationUpdateOneWithoutTrackingInput>,
  businessType?: Maybe<TrackingBusinessTypeUpdateOneInput>,
  parent?: Maybe<TrackingUpdateOneWithoutChildrenInput>,
  children?: Maybe<TrackingUpdateManyWithoutParentInput>,
  history?: Maybe<TrackingHistoryStatusUpdateManyWithoutTrackingInput>,
  alerts?: Maybe<TrackingAlertUpdateManyWithoutTrackingInput>,
  lastStatus?: Maybe<TrackingStatusUpdateOneWithoutTrackingInput>,
};

export type TrackingUpdateWithWhereUniqueWithoutLastStatusInput = {
  where: TrackingWhereUniqueInput,
  data: TrackingUpdateWithoutLastStatusDataInput,
};

export type TrackingUpdateWithWhereUniqueWithoutParentInput = {
  where: TrackingWhereUniqueInput,
  data: TrackingUpdateWithoutParentDataInput,
};

export type TrackingUpsertNestedInput = {
  update: TrackingUpdateDataInput,
  create: TrackingCreateInput,
};

export type TrackingUpsertWithoutAlertsInput = {
  update: TrackingUpdateWithoutAlertsDataInput,
  create: TrackingCreateWithoutAlertsInput,
};

export type TrackingUpsertWithoutChildrenInput = {
  update: TrackingUpdateWithoutChildrenDataInput,
  create: TrackingCreateWithoutChildrenInput,
};

export type TrackingUpsertWithoutHistoryInput = {
  update: TrackingUpdateWithoutHistoryDataInput,
  create: TrackingCreateWithoutHistoryInput,
};

export type TrackingUpsertWithoutLastLocationInput = {
  update: TrackingUpdateWithoutLastLocationDataInput,
  create: TrackingCreateWithoutLastLocationInput,
};

export type TrackingUpsertWithoutPointsInput = {
  update: TrackingUpdateWithoutPointsDataInput,
  create: TrackingCreateWithoutPointsInput,
};

export type TrackingUpsertWithWhereUniqueWithoutLastStatusInput = {
  where: TrackingWhereUniqueInput,
  update: TrackingUpdateWithoutLastStatusDataInput,
  create: TrackingCreateWithoutLastStatusInput,
};

export type TrackingUpsertWithWhereUniqueWithoutParentInput = {
  where: TrackingWhereUniqueInput,
  update: TrackingUpdateWithoutParentDataInput,
  create: TrackingCreateWithoutParentInput,
};

export type TrackingValidationCode = Node & {
  __typename?: 'TrackingValidationCode',
  id: Scalars['ID'],
  tracking?: Maybe<Tracking>,
  code?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingValidationCodeConnection = {
  __typename?: 'TrackingValidationCodeConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<TrackingValidationCodeEdge>>,
  aggregate: AggregateTrackingValidationCode,
};

export type TrackingValidationCodeCreateInput = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingCreateOneInput>,
};

export type TrackingValidationCodeEdge = {
  __typename?: 'TrackingValidationCodeEdge',
  node: TrackingValidationCode,
  cursor: Scalars['String'],
};

export enum TrackingValidationCodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  TokenAsc = 'token_ASC',
  TokenDesc = 'token_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type TrackingValidationCodePreviousValues = {
  __typename?: 'TrackingValidationCodePreviousValues',
  id: Scalars['ID'],
  code?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
};

export type TrackingValidationCodeSubscriptionPayload = {
  __typename?: 'TrackingValidationCodeSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<TrackingValidationCode>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<TrackingValidationCodePreviousValues>,
};

export type TrackingValidationCodeSubscriptionWhereInput = {
  AND?: Maybe<Array<TrackingValidationCodeSubscriptionWhereInput>>,
  OR?: Maybe<Array<TrackingValidationCodeSubscriptionWhereInput>>,
  NOT?: Maybe<Array<TrackingValidationCodeSubscriptionWhereInput>>,
  mutation_in?: Maybe<Array<MutationType>>,
  updatedFields_contains?: Maybe<Scalars['String']>,
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>,
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<TrackingValidationCodeWhereInput>,
};

export type TrackingValidationCodeUpdateInput = {
  code?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  tracking?: Maybe<TrackingUpdateOneInput>,
};

export type TrackingValidationCodeUpdateManyMutationInput = {
  code?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
};

export type TrackingValidationCodeWhereInput = {
  AND?: Maybe<Array<TrackingValidationCodeWhereInput>>,
  OR?: Maybe<Array<TrackingValidationCodeWhereInput>>,
  NOT?: Maybe<Array<TrackingValidationCodeWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['Int']>,
  code_not?: Maybe<Scalars['Int']>,
  code_in?: Maybe<Array<Scalars['Int']>>,
  code_not_in?: Maybe<Array<Scalars['Int']>>,
  code_lt?: Maybe<Scalars['Int']>,
  code_lte?: Maybe<Scalars['Int']>,
  code_gt?: Maybe<Scalars['Int']>,
  code_gte?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  token_not?: Maybe<Scalars['String']>,
  token_in?: Maybe<Array<Scalars['String']>>,
  token_not_in?: Maybe<Array<Scalars['String']>>,
  token_lt?: Maybe<Scalars['String']>,
  token_lte?: Maybe<Scalars['String']>,
  token_gt?: Maybe<Scalars['String']>,
  token_gte?: Maybe<Scalars['String']>,
  token_contains?: Maybe<Scalars['String']>,
  token_not_contains?: Maybe<Scalars['String']>,
  token_starts_with?: Maybe<Scalars['String']>,
  token_not_starts_with?: Maybe<Scalars['String']>,
  token_ends_with?: Maybe<Scalars['String']>,
  token_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  tracking?: Maybe<TrackingWhereInput>,
};

export type TrackingValidationCodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TrackingWhereInput = {
  AND?: Maybe<Array<TrackingWhereInput>>,
  OR?: Maybe<Array<TrackingWhereInput>>,
  NOT?: Maybe<Array<TrackingWhereInput>>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  guide?: Maybe<Scalars['String']>,
  guide_not?: Maybe<Scalars['String']>,
  guide_in?: Maybe<Array<Scalars['String']>>,
  guide_not_in?: Maybe<Array<Scalars['String']>>,
  guide_lt?: Maybe<Scalars['String']>,
  guide_lte?: Maybe<Scalars['String']>,
  guide_gt?: Maybe<Scalars['String']>,
  guide_gte?: Maybe<Scalars['String']>,
  guide_contains?: Maybe<Scalars['String']>,
  guide_not_contains?: Maybe<Scalars['String']>,
  guide_starts_with?: Maybe<Scalars['String']>,
  guide_not_starts_with?: Maybe<Scalars['String']>,
  guide_ends_with?: Maybe<Scalars['String']>,
  guide_not_ends_with?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  orderId_not?: Maybe<Scalars['Int']>,
  orderId_in?: Maybe<Array<Scalars['Int']>>,
  orderId_not_in?: Maybe<Array<Scalars['Int']>>,
  orderId_lt?: Maybe<Scalars['Int']>,
  orderId_lte?: Maybe<Scalars['Int']>,
  orderId_gt?: Maybe<Scalars['Int']>,
  orderId_gte?: Maybe<Scalars['Int']>,
  messenger?: Maybe<Scalars['String']>,
  messenger_not?: Maybe<Scalars['String']>,
  messenger_in?: Maybe<Array<Scalars['String']>>,
  messenger_not_in?: Maybe<Array<Scalars['String']>>,
  messenger_lt?: Maybe<Scalars['String']>,
  messenger_lte?: Maybe<Scalars['String']>,
  messenger_gt?: Maybe<Scalars['String']>,
  messenger_gte?: Maybe<Scalars['String']>,
  messenger_contains?: Maybe<Scalars['String']>,
  messenger_not_contains?: Maybe<Scalars['String']>,
  messenger_starts_with?: Maybe<Scalars['String']>,
  messenger_not_starts_with?: Maybe<Scalars['String']>,
  messenger_ends_with?: Maybe<Scalars['String']>,
  messenger_not_ends_with?: Maybe<Scalars['String']>,
  client?: Maybe<Scalars['String']>,
  client_not?: Maybe<Scalars['String']>,
  client_in?: Maybe<Array<Scalars['String']>>,
  client_not_in?: Maybe<Array<Scalars['String']>>,
  client_lt?: Maybe<Scalars['String']>,
  client_lte?: Maybe<Scalars['String']>,
  client_gt?: Maybe<Scalars['String']>,
  client_gte?: Maybe<Scalars['String']>,
  client_contains?: Maybe<Scalars['String']>,
  client_not_contains?: Maybe<Scalars['String']>,
  client_starts_with?: Maybe<Scalars['String']>,
  client_not_starts_with?: Maybe<Scalars['String']>,
  client_ends_with?: Maybe<Scalars['String']>,
  client_not_ends_with?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  location_not?: Maybe<Scalars['String']>,
  location_in?: Maybe<Array<Scalars['String']>>,
  location_not_in?: Maybe<Array<Scalars['String']>>,
  location_lt?: Maybe<Scalars['String']>,
  location_lte?: Maybe<Scalars['String']>,
  location_gt?: Maybe<Scalars['String']>,
  location_gte?: Maybe<Scalars['String']>,
  location_contains?: Maybe<Scalars['String']>,
  location_not_contains?: Maybe<Scalars['String']>,
  location_starts_with?: Maybe<Scalars['String']>,
  location_not_starts_with?: Maybe<Scalars['String']>,
  location_ends_with?: Maybe<Scalars['String']>,
  location_not_ends_with?: Maybe<Scalars['String']>,
  shorterUrl?: Maybe<Scalars['String']>,
  shorterUrl_not?: Maybe<Scalars['String']>,
  shorterUrl_in?: Maybe<Array<Scalars['String']>>,
  shorterUrl_not_in?: Maybe<Array<Scalars['String']>>,
  shorterUrl_lt?: Maybe<Scalars['String']>,
  shorterUrl_lte?: Maybe<Scalars['String']>,
  shorterUrl_gt?: Maybe<Scalars['String']>,
  shorterUrl_gte?: Maybe<Scalars['String']>,
  shorterUrl_contains?: Maybe<Scalars['String']>,
  shorterUrl_not_contains?: Maybe<Scalars['String']>,
  shorterUrl_starts_with?: Maybe<Scalars['String']>,
  shorterUrl_not_starts_with?: Maybe<Scalars['String']>,
  shorterUrl_ends_with?: Maybe<Scalars['String']>,
  shorterUrl_not_ends_with?: Maybe<Scalars['String']>,
  finished?: Maybe<Scalars['Boolean']>,
  finished_not?: Maybe<Scalars['Boolean']>,
  originalAddress?: Maybe<Scalars['String']>,
  originalAddress_not?: Maybe<Scalars['String']>,
  originalAddress_in?: Maybe<Array<Scalars['String']>>,
  originalAddress_not_in?: Maybe<Array<Scalars['String']>>,
  originalAddress_lt?: Maybe<Scalars['String']>,
  originalAddress_lte?: Maybe<Scalars['String']>,
  originalAddress_gt?: Maybe<Scalars['String']>,
  originalAddress_gte?: Maybe<Scalars['String']>,
  originalAddress_contains?: Maybe<Scalars['String']>,
  originalAddress_not_contains?: Maybe<Scalars['String']>,
  originalAddress_starts_with?: Maybe<Scalars['String']>,
  originalAddress_not_starts_with?: Maybe<Scalars['String']>,
  originalAddress_ends_with?: Maybe<Scalars['String']>,
  originalAddress_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  lastLocation?: Maybe<TrackingLocationWhereInput>,
  businessType?: Maybe<TrackingBusinessTypeWhereInput>,
  parent?: Maybe<TrackingWhereInput>,
  children_every?: Maybe<TrackingWhereInput>,
  children_some?: Maybe<TrackingWhereInput>,
  children_none?: Maybe<TrackingWhereInput>,
  points_every?: Maybe<TrackingPointWhereInput>,
  points_some?: Maybe<TrackingPointWhereInput>,
  points_none?: Maybe<TrackingPointWhereInput>,
  history_every?: Maybe<TrackingHistoryStatusWhereInput>,
  history_some?: Maybe<TrackingHistoryStatusWhereInput>,
  history_none?: Maybe<TrackingHistoryStatusWhereInput>,
  alerts_every?: Maybe<TrackingAlertWhereInput>,
  alerts_some?: Maybe<TrackingAlertWhereInput>,
  alerts_none?: Maybe<TrackingAlertWhereInput>,
  lastStatus?: Maybe<TrackingStatusWhereInput>,
};

export type TrackingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type CreateTrackingIssueMutationVariables = {
  tracking?: Maybe<TrackingCreateOneInput>,
  issue?: Maybe<IssueCreateManyInput>,
  comments: Scalars['String'],
  status?: Maybe<Scalars['String']>
};


export type CreateTrackingIssueMutation = (
  { __typename?: 'Mutation' }
  & { createTrackingIssue: (
    { __typename?: 'TrackingIssue' }
    & { tracking: Maybe<(
      { __typename?: 'Tracking' }
      & Pick<Tracking, 'id' | 'guide' | 'orderId'>
    )> }
  ) }
);

export type GetIssuesQueryVariables = {};


export type GetIssuesQuery = (
  { __typename?: 'Query' }
  & { issues: Array<Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'name' | 'description' | 'input'>
  )>> }
);

export type GetTimelineByGuideQueryVariables = {
  guide: Scalars['String']
};


export type GetTimelineByGuideQuery = (
  { __typename?: 'Query' }
  & { trackings: Array<Maybe<(
    { __typename?: 'Tracking' }
    & Pick<Tracking, 'guide' | 'orderId' | 'client' | 'lastAddress' | 'messenger' | 'messengerObj' | 'lastLocationRaw'>
    & { businessType: Maybe<(
      { __typename?: 'TrackingBusinessType' }
      & Pick<TrackingBusinessType, 'name'>
    )>, lastStatus: Maybe<(
      { __typename?: 'TrackingStatus' }
      & Pick<TrackingStatus, 'name' | 'description' | 'activeTitle' | 'activeSubtitle' | 'inactiveDescription' | 'order' | 'createdAt'>
    )>, points: Maybe<Array<(
      { __typename?: 'TrackingPoint' }
      & Pick<TrackingPoint, 'id'>
      & { receivers: Maybe<Array<(
        { __typename?: 'TrackingReceiver' }
        & Pick<TrackingReceiver, 'phone' | 'name' | 'email'>
      )>>, addresses: Maybe<Array<(
        { __typename?: 'TrackingAddress' }
        & Pick<TrackingAddress, 'address' | 'addressObject' | 'latitude' | 'longitude'>
      )>> }
    )>>, alerts: Maybe<Array<(
      { __typename?: 'TrackingAlert' }
      & Pick<TrackingAlert, 'description'>
    )>>, lastLocation: Maybe<(
      { __typename?: 'TrackingLocation' }
      & Pick<TrackingLocation, 'latitude' | 'longitude'>
    )>, history: Maybe<Array<(
      { __typename?: 'TrackingHistoryStatus' }
      & Pick<TrackingHistoryStatus, 'id' | 'createdAt'>
      & { status: Maybe<(
        { __typename?: 'TrackingStatus' }
        & Pick<TrackingStatus, 'name' | 'description' | 'activeSubtitle' | 'inactiveDescription' | 'order'>
      )> }
    )>>, currentStatus: (
      { __typename?: 'CurrentStatus' }
      & Pick<CurrentStatus, 'title' | 'message'>
    ), timeline: Array<(
      { __typename?: 'TimelineItem' }
      & Pick<TimelineItem, 'id' | 'title' | 'message' | 'status' | 'order'>
    )> }
  )>> }
);

export type GetTimelineQueryVariables = {
  id: Scalars['ID']
};


export type GetTimelineQuery = (
  { __typename?: 'Query' }
  & { tracking: Maybe<(
    { __typename?: 'Tracking' }
    & Pick<Tracking, 'guide' | 'orderId' | 'client' | 'lastAddress' | 'messenger' | 'messengerObj' | 'lastLocationRaw'>
    & { businessType: Maybe<(
      { __typename?: 'TrackingBusinessType' }
      & Pick<TrackingBusinessType, 'name'>
    )>, lastStatus: Maybe<(
      { __typename?: 'TrackingStatus' }
      & Pick<TrackingStatus, 'name' | 'description' | 'activeTitle' | 'activeSubtitle' | 'inactiveDescription' | 'order' | 'createdAt'>
    )>, points: Maybe<Array<(
      { __typename?: 'TrackingPoint' }
      & Pick<TrackingPoint, 'id'>
      & { receivers: Maybe<Array<(
        { __typename?: 'TrackingReceiver' }
        & Pick<TrackingReceiver, 'phone' | 'name' | 'email'>
      )>>, addresses: Maybe<Array<(
        { __typename?: 'TrackingAddress' }
        & Pick<TrackingAddress, 'address' | 'addressObject' | 'latitude' | 'longitude'>
      )>> }
    )>>, alerts: Maybe<Array<(
      { __typename?: 'TrackingAlert' }
      & Pick<TrackingAlert, 'description'>
    )>>, lastLocation: Maybe<(
      { __typename?: 'TrackingLocation' }
      & Pick<TrackingLocation, 'latitude' | 'longitude'>
    )>, history: Maybe<Array<(
      { __typename?: 'TrackingHistoryStatus' }
      & Pick<TrackingHistoryStatus, 'id' | 'createdAt'>
      & { status: Maybe<(
        { __typename?: 'TrackingStatus' }
        & Pick<TrackingStatus, 'name' | 'description' | 'activeSubtitle' | 'inactiveDescription' | 'order'>
      )> }
    )>>, currentStatus: (
      { __typename?: 'CurrentStatus' }
      & Pick<CurrentStatus, 'title' | 'message'>
    ), timeline: Array<(
      { __typename?: 'TimelineItem' }
      & Pick<TimelineItem, 'id' | 'title' | 'message' | 'status' | 'order'>
    )> }
  )> }
);

export type SearchTrackingQueryVariables = {
  search?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>
};


export type SearchTrackingQuery = (
  { __typename?: 'Query' }
  & { trackings: Array<Maybe<(
    { __typename?: 'Tracking' }
    & Pick<Tracking, 'id' | 'guide' | 'orderId'>
  )>> }
);

export type GetTrackingByGuideSubscriptionVariables = {
  guide: Scalars['String']
};


export type GetTrackingByGuideSubscription = (
  { __typename?: 'Subscription' }
  & { tracking: Maybe<(
    { __typename?: 'TrackingSubscriptionPayload' }
    & { node: Maybe<(
      { __typename?: 'Tracking' }
      & Pick<Tracking, 'guide' | 'orderId' | 'client' | 'lastAddress' | 'messenger' | 'messengerObj' | 'lastLocationRaw'>
      & { businessType: Maybe<(
        { __typename?: 'TrackingBusinessType' }
        & Pick<TrackingBusinessType, 'name'>
      )>, lastStatus: Maybe<(
        { __typename?: 'TrackingStatus' }
        & Pick<TrackingStatus, 'name' | 'description' | 'activeTitle' | 'activeSubtitle' | 'inactiveDescription' | 'order' | 'createdAt'>
      )>, points: Maybe<Array<(
        { __typename?: 'TrackingPoint' }
        & Pick<TrackingPoint, 'id'>
        & { receivers: Maybe<Array<(
          { __typename?: 'TrackingReceiver' }
          & Pick<TrackingReceiver, 'phone' | 'name' | 'email'>
        )>>, addresses: Maybe<Array<(
          { __typename?: 'TrackingAddress' }
          & Pick<TrackingAddress, 'address' | 'addressObject' | 'latitude' | 'longitude'>
        )>> }
      )>>, alerts: Maybe<Array<(
        { __typename?: 'TrackingAlert' }
        & Pick<TrackingAlert, 'description'>
      )>>, lastLocation: Maybe<(
        { __typename?: 'TrackingLocation' }
        & Pick<TrackingLocation, 'latitude' | 'longitude'>
      )>, history: Maybe<Array<(
        { __typename?: 'TrackingHistoryStatus' }
        & Pick<TrackingHistoryStatus, 'id' | 'createdAt'>
        & { status: Maybe<(
          { __typename?: 'TrackingStatus' }
          & Pick<TrackingStatus, 'name' | 'description' | 'activeSubtitle' | 'inactiveDescription' | 'order'>
        )> }
      )>>, currentStatus: (
        { __typename?: 'CurrentStatus' }
        & Pick<CurrentStatus, 'title' | 'message'>
      ), timeline: Array<(
        { __typename?: 'TimelineItem' }
        & Pick<TimelineItem, 'id' | 'title' | 'message' | 'status' | 'order'>
      )> }
    )> }
  )> }
);

export type GetTrackingSubscriptionVariables = {
  id?: Maybe<Scalars['ID']>
};


export type GetTrackingSubscription = (
  { __typename?: 'Subscription' }
  & { tracking: Maybe<(
    { __typename?: 'TrackingSubscriptionPayload' }
    & { node: Maybe<(
      { __typename?: 'Tracking' }
      & Pick<Tracking, 'guide' | 'orderId' | 'client' | 'lastAddress' | 'messenger' | 'messengerObj' | 'lastLocationRaw'>
      & { businessType: Maybe<(
        { __typename?: 'TrackingBusinessType' }
        & Pick<TrackingBusinessType, 'name'>
      )>, lastStatus: Maybe<(
        { __typename?: 'TrackingStatus' }
        & Pick<TrackingStatus, 'name' | 'description' | 'activeTitle' | 'activeSubtitle' | 'inactiveDescription' | 'order' | 'createdAt'>
      )>, points: Maybe<Array<(
        { __typename?: 'TrackingPoint' }
        & Pick<TrackingPoint, 'id'>
        & { receivers: Maybe<Array<(
          { __typename?: 'TrackingReceiver' }
          & Pick<TrackingReceiver, 'phone' | 'name' | 'email'>
        )>>, addresses: Maybe<Array<(
          { __typename?: 'TrackingAddress' }
          & Pick<TrackingAddress, 'address' | 'addressObject' | 'latitude' | 'longitude'>
        )>> }
      )>>, alerts: Maybe<Array<(
        { __typename?: 'TrackingAlert' }
        & Pick<TrackingAlert, 'description'>
      )>>, lastLocation: Maybe<(
        { __typename?: 'TrackingLocation' }
        & Pick<TrackingLocation, 'latitude' | 'longitude'>
      )>, history: Maybe<Array<(
        { __typename?: 'TrackingHistoryStatus' }
        & Pick<TrackingHistoryStatus, 'id' | 'createdAt'>
        & { status: Maybe<(
          { __typename?: 'TrackingStatus' }
          & Pick<TrackingStatus, 'name' | 'description' | 'activeSubtitle' | 'inactiveDescription' | 'order'>
        )> }
      )>>, currentStatus: (
        { __typename?: 'CurrentStatus' }
        & Pick<CurrentStatus, 'title' | 'message'>
      ), timeline: Array<(
        { __typename?: 'TimelineItem' }
        & Pick<TimelineItem, 'id' | 'title' | 'message' | 'status' | 'order'>
      )> }
    )> }
  )> }
);


export const CreateTrackingIssueDocument = gql`
    mutation createTrackingIssue($tracking: TrackingCreateOneInput, $issue: IssueCreateManyInput, $comments: String!, $status: String) {
  createTrackingIssue(data: {tracking: $tracking, issue: $issue, comments: $comments, status: $status}) {
    tracking {
      id
      guide
      orderId
    }
  }
}
    `;
export type CreateTrackingIssueMutationFn = ApolloReactCommon.MutationFunction<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables>;
export type CreateTrackingIssueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables>, 'mutation'>;

    export const CreateTrackingIssueComponent = (props: CreateTrackingIssueComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables> mutation={CreateTrackingIssueDocument} {...props} />
    );
    

    export function useCreateTrackingIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables>(CreateTrackingIssueDocument, baseOptions);
    };
export type CreateTrackingIssueMutationHookResult = ReturnType<typeof useCreateTrackingIssueMutation>;
export type CreateTrackingIssueMutationResult = ApolloReactCommon.MutationResult<CreateTrackingIssueMutation>;
export type CreateTrackingIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTrackingIssueMutation, CreateTrackingIssueMutationVariables>;
export const GetIssuesDocument = gql`
    query getIssues {
  issues {
    id
    name
    description
    input
  }
}
    `;
export type GetIssuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIssuesQuery, GetIssuesQueryVariables>, 'query'>;

    export const GetIssuesComponent = (props: GetIssuesComponentProps) => (
      <ApolloReactComponents.Query<GetIssuesQuery, GetIssuesQueryVariables> query={GetIssuesDocument} {...props} />
    );
    

    export function useGetIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, baseOptions);
    };
      export function useGetIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, baseOptions);
      };
      
export type GetIssuesQueryHookResult = ReturnType<typeof useGetIssuesQuery>;
export type GetIssuesQueryResult = ApolloReactCommon.QueryResult<GetIssuesQuery, GetIssuesQueryVariables>;
export const GetTimelineByGuideDocument = gql`
    query GetTimelineByGuide($guide: String!) {
  trackings(where: {guide: $guide}) {
    guide
    orderId
    client
    businessType {
      name
    }
    lastAddress
    lastStatus {
      name
      description
      activeTitle
      activeSubtitle
      inactiveDescription
      order
      createdAt
    }
    points {
      id
      receivers {
        phone
        name
        email
      }
      addresses(last: 1) {
        address
        addressObject
        latitude
        longitude
      }
    }
    alerts {
      description
    }
    messenger
    messengerObj
    lastLocationRaw
    lastLocation {
      latitude
      longitude
    }
    history {
      id
      status {
        name
        description
        activeSubtitle
        inactiveDescription
        order
      }
      createdAt
    }
    currentStatus @client {
      title
      message
    }
    timeline @client {
      id
      title
      message
      status
      order
    }
  }
}
    `;
export type GetTimelineByGuideComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>, 'query'> & ({ variables: GetTimelineByGuideQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTimelineByGuideComponent = (props: GetTimelineByGuideComponentProps) => (
      <ApolloReactComponents.Query<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables> query={GetTimelineByGuideDocument} {...props} />
    );
    

    export function useGetTimelineByGuideQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>(GetTimelineByGuideDocument, baseOptions);
    };
      export function useGetTimelineByGuideLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>(GetTimelineByGuideDocument, baseOptions);
      };
      
export type GetTimelineByGuideQueryHookResult = ReturnType<typeof useGetTimelineByGuideQuery>;
export type GetTimelineByGuideQueryResult = ApolloReactCommon.QueryResult<GetTimelineByGuideQuery, GetTimelineByGuideQueryVariables>;
export const GetTimelineDocument = gql`
    query GetTimeline($id: ID!) {
  tracking(where: {id: $id}) {
    guide
    orderId
    client
    businessType {
      name
    }
    lastAddress
    lastStatus {
      name
      description
      activeTitle
      activeSubtitle
      inactiveDescription
      order
      createdAt
    }
    points {
      id
      receivers {
        phone
        name
        email
      }
      addresses(last: 1) {
        address
        addressObject
        latitude
        longitude
      }
    }
    alerts {
      description
    }
    messenger
    messengerObj
    lastLocationRaw
    lastLocation {
      latitude
      longitude
    }
    history {
      id
      status {
        name
        description
        activeSubtitle
        inactiveDescription
        order
      }
      createdAt
    }
    currentStatus @client {
      title
      message
    }
    timeline @client {
      id
      title
      message
      status
      order
    }
  }
}
    `;
export type GetTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTimelineQuery, GetTimelineQueryVariables>, 'query'> & ({ variables: GetTimelineQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTimelineComponent = (props: GetTimelineComponentProps) => (
      <ApolloReactComponents.Query<GetTimelineQuery, GetTimelineQueryVariables> query={GetTimelineDocument} {...props} />
    );
    

    export function useGetTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimelineQuery, GetTimelineQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTimelineQuery, GetTimelineQueryVariables>(GetTimelineDocument, baseOptions);
    };
      export function useGetTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTimelineQuery, GetTimelineQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetTimelineQuery, GetTimelineQueryVariables>(GetTimelineDocument, baseOptions);
      };
      
export type GetTimelineQueryHookResult = ReturnType<typeof useGetTimelineQuery>;
export type GetTimelineQueryResult = ApolloReactCommon.QueryResult<GetTimelineQuery, GetTimelineQueryVariables>;
export const SearchTrackingDocument = gql`
    query SearchTracking($search: String, $orderId: Int) {
  trackings(where: {guide_ends_with: $search, orderId: $orderId}) {
    id
    guide
    orderId
  }
}
    `;
export type SearchTrackingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchTrackingQuery, SearchTrackingQueryVariables>, 'query'>;

    export const SearchTrackingComponent = (props: SearchTrackingComponentProps) => (
      <ApolloReactComponents.Query<SearchTrackingQuery, SearchTrackingQueryVariables> query={SearchTrackingDocument} {...props} />
    );
    

    export function useSearchTrackingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTrackingQuery, SearchTrackingQueryVariables>) {
      return ApolloReactHooks.useQuery<SearchTrackingQuery, SearchTrackingQueryVariables>(SearchTrackingDocument, baseOptions);
    };
      export function useSearchTrackingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTrackingQuery, SearchTrackingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<SearchTrackingQuery, SearchTrackingQueryVariables>(SearchTrackingDocument, baseOptions);
      };
      
export type SearchTrackingQueryHookResult = ReturnType<typeof useSearchTrackingQuery>;
export type SearchTrackingQueryResult = ApolloReactCommon.QueryResult<SearchTrackingQuery, SearchTrackingQueryVariables>;
export const GetTrackingByGuideDocument = gql`
    subscription GetTrackingByGuide($guide: String!) {
  tracking(where: {mutation_in: [UPDATED], node: {guide: $guide}}) {
    node {
      guide
      orderId
      client
      businessType {
        name
      }
      lastAddress
      lastStatus {
        name
        description
        activeTitle
        activeSubtitle
        inactiveDescription
        order
        createdAt
      }
      points {
        id
        receivers {
          phone
          name
          email
        }
        addresses(last: 1) {
          address
          addressObject
          latitude
          longitude
        }
      }
      alerts {
        description
      }
      messenger
      messengerObj
      lastLocationRaw
      lastLocation {
        latitude
        longitude
      }
      history {
        id
        status {
          name
          description
          activeSubtitle
          inactiveDescription
          order
        }
        createdAt
      }
      currentStatus @client {
        title
        message
      }
      timeline @client {
        id
        title
        message
        status
        order
      }
    }
  }
}
    `;
export type GetTrackingByGuideComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<GetTrackingByGuideSubscription, GetTrackingByGuideSubscriptionVariables>, 'subscription'>;

    export const GetTrackingByGuideComponent = (props: GetTrackingByGuideComponentProps) => (
      <ApolloReactComponents.Subscription<GetTrackingByGuideSubscription, GetTrackingByGuideSubscriptionVariables> subscription={GetTrackingByGuideDocument} {...props} />
    );
    

    export function useGetTrackingByGuideSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetTrackingByGuideSubscription, GetTrackingByGuideSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<GetTrackingByGuideSubscription, GetTrackingByGuideSubscriptionVariables>(GetTrackingByGuideDocument, baseOptions);
    };
export type GetTrackingByGuideSubscriptionHookResult = ReturnType<typeof useGetTrackingByGuideSubscription>;
export type GetTrackingByGuideSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetTrackingByGuideSubscription>;
export const GetTrackingDocument = gql`
    subscription GetTracking($id: ID) {
  tracking(where: {mutation_in: [UPDATED], node: {id: $id}}) {
    node {
      guide
      orderId
      client
      businessType {
        name
      }
      lastAddress
      lastStatus {
        name
        description
        activeTitle
        activeSubtitle
        inactiveDescription
        order
        createdAt
      }
      points {
        id
        receivers {
          phone
          name
          email
        }
        addresses(last: 1) {
          address
          addressObject
          latitude
          longitude
        }
      }
      alerts {
        description
      }
      messenger
      messengerObj
      lastLocationRaw
      lastLocation {
        latitude
        longitude
      }
      history {
        id
        status {
          name
          description
          activeSubtitle
          inactiveDescription
          order
        }
        createdAt
      }
      currentStatus @client {
        title
        message
      }
      timeline @client {
        id
        title
        message
        status
        order
      }
    }
  }
}
    `;
export type GetTrackingComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<GetTrackingSubscription, GetTrackingSubscriptionVariables>, 'subscription'>;

    export const GetTrackingComponent = (props: GetTrackingComponentProps) => (
      <ApolloReactComponents.Subscription<GetTrackingSubscription, GetTrackingSubscriptionVariables> subscription={GetTrackingDocument} {...props} />
    );
    

    export function useGetTrackingSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetTrackingSubscription, GetTrackingSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<GetTrackingSubscription, GetTrackingSubscriptionVariables>(GetTrackingDocument, baseOptions);
    };
export type GetTrackingSubscriptionHookResult = ReturnType<typeof useGetTrackingSubscription>;
export type GetTrackingSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetTrackingSubscription>;