import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { theme } from 'styled-tools';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-shadow: inset 0px 11px 8px -10px ${theme('colors.dustyGray')},
    inset 0px -8px 8px -10px ${theme('colors.dustyGray')};
  background-color: ${theme('colors.blackRgba')};
`;

export const TextCard = styled(Text)`
  align-self: center;
  font-size: 15px;
  font-weight: 500;
  padding-top: 3%;
  padding-bottom: 2%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 4%;
  width: 25%;
  align-self: center;
`;

export const Thumb = styled.img`
  width: 25px;
  height: 25px;
`;
