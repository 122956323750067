import styled from 'styled-components';
import { Element } from 'react-scroll';

export const Container = styled(Element)`
  width: 100%;
  position: relative;
  overflow: scroll;
  padding: 1%;
  z-index: 1250;
  &::-webkit-scrollbar {
    display: none;
  }
`;
