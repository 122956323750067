import * as React from 'react';
import { Box, Heading, Text } from './style';

export interface MainInformationProps {
  title: string;
  message: string;
  image?: string;
}

export const MainInformation: React.FC<MainInformationProps> = ({
  title,
  message,
  image,
}: MainInformationProps): JSX.Element => {
  return (
    <Box px={3} alignSelf="left">
      <Heading color="primary">{message}</Heading>
      <Text fontSize={1}>{title}</Text>
    </Box>
  );
};
