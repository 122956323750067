import styled from 'styled-components';
import { Box, Text } from 'rebass/styled-components';
import { theme, prop } from 'styled-tools';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${theme('colors.alto')};
  padding: 15px;
  background-color: ${theme('colors.white')};
  align-items: center;
`;

export const MainText = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
`;

export const Icon = styled(Box)`
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${prop('src')});
  margin-right: 2%;
  cursor: pointer;
`;
