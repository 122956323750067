import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Box } from '../';

interface CustomPaperProps {
  children: React.ReactNode;
  style?: any;
}

export const CustomPaper: React.FC<CustomPaperProps> = ({
  children,
  style,
}: CustomPaperProps): JSX.Element => {
  const matchesPhone = useMediaQuery('(max-width:780px)');
  if (!matchesPhone) {
    return (
      <Paper elevation={3} style={style}>
        {children}
      </Paper>
    );
  } else {
    return <Box style={style}>{children}</Box>;
  }
};
