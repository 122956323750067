import * as React from 'react';
import { Timeline, TimelineItem } from '../../components/Timeline';
import { Box, Text } from 'rebass/styled-components';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {
  GOOGLE_INTEGRATION_KEY,
  GOOGLE_INTEGRATION_URL,
  MAP_URL,
} from '../../config';
import {
  messengerIcon,
  commerceIcon,
  houseIcon,
} from '../../components/Map/icons';
import axios from 'axios';
import { MapLeaflet } from '../../components/Map/style';
import { Marker, TileLayer } from 'react-leaflet';
import 'leaflet-routing-machine';
import firebase from 'firebase';

interface OrderLiteProps {
  data: any;
  guide: any;
}

export const OrderLiteContainer: React.FC<OrderLiteProps> = ({
  data,
  guide,
}: OrderLiteProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [dataGuide, setDataGuide] = React.useState(data);
  const matchesDeskGrid = useMediaQuery('(min-width:655px)');
  const pointsTimeline: any = [];

  React.useEffect(() => {
    const orderQuery = `{\n orderTracking(order: ${parseInt(
      guide,
    )}) {\n    eta\n  orderStatus {\n   id\n    name\n   }\n   messenger {\n      firstName\n      lastName\n      latitude\n      longitude\n    }\n    orderAddresses {\n      address {\n        externalNumber\n        street\n      latitude\n     longitude\n      }\n    }\n    orderHistories {\n      nameOrderStatus\n      dateAdd\n    }\n  }\n}\n`;
    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };
    const request = setInterval(() => {
      axios
        .post(
          GOOGLE_INTEGRATION_URL,
          {
            query: orderQuery,
          },
          config,
        )
        .then((response: any) => {
          const { orderTracking } = response.data.data;
          if (orderTracking) {
            console.log(orderTracking);
            setDataGuide(orderTracking);
          }
        })
        .catch((err: any) => {
          console.log(err);

          firebase.analytics().logEvent('order_container_catch', { guide });
        });
    }, 5000);
    return () => {
      return clearInterval(request);
    };
  }, [guide]);

  if (dataGuide.orderHistories) {
    /* eslint-disable array-callback-return*/
    dataGuide.orderHistories.map((dataPoints: any, index: any) => {
      pointsTimeline.push({
        id: index,
        message: dataPoints.nameOrderStatus,
        order: index,
        time: dataPoints.dateAdd,
      });
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box height="100%">
      <Card className="CardGray">
        <Box
          style={{
            display: 'flex',
            flexDirection: !matchesDeskGrid ? 'column' : 'row',
          }}
        >
          {dataGuide.messenger && (
            <Grid item sm={12} md={6} lg={6}>
              <Text paddingBottom={2} color="purple">
                Mensajero:
              </Text>
              <Text
                paddingRight={2}
                paddingBottom={2}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'lowercase',
                }}
              >
                {dataGuide.messenger.firstName} {dataGuide.messenger.lastName}
              </Text>
            </Grid>
          )}
          <Grid item sm={12} md={6} lg={6}>
            <Text paddingBottom={2} color="purple">
              Dirección de entrega:
            </Text>
            <Text
              paddingRight={2}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'lowercase',
              }}
            >
              {dataGuide.orderAddresses[1].address.street}{' '}
              {dataGuide.orderAddresses[1].address.externalNumber}
            </Text>
          </Grid>
        </Box>
      </Card>
      <Box padding={2} />
      <Card>
        {dataGuide.messenger && dataGuide.orderStatus.id < 6 && (
          <Box
            width="100%"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button color="primary" onClick={handleOpen}>
              Ver en mapa
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2700,
              }}
            >
              <Fade in={open}>
                <MapLeaflet
                  // @ts-ignore
                  center={[
                    dataGuide.messenger.latitude,
                    dataGuide.messenger.longitude,
                  ]}
                  zoom={15}
                  style={{ height: '80%', width: '80%' }}
                  zoomControl={false}
                >
                  <TileLayer url={String(MAP_URL)} />
                  {dataGuide.orderAddresses.map(
                    (points: any, index: number) => (
                      /* eslint-disable @typescript-eslint/ban-ts-ignore*/
                      //@ts-ignore
                      <Marker
                        key={index}
                        //@ts-ignore
                        icon={index === 0 ? commerceIcon : houseIcon}
                        position={[
                          points.address.latitude,
                          points.address.longitude,
                        ]}
                      />
                    ),
                  )}
                  <Marker
                    //@ts-ignore
                    icon={messengerIcon}
                    position={[
                      dataGuide.messenger.latitude,
                      dataGuide.messenger.longitude,
                    ]}
                  />
                </MapLeaflet>
              </Fade>
            </Modal>
          </Box>
        )}
        <Timeline>
          {pointsTimeline
            .reverse()
            .map(
              (points: any) =>
                !points.hide && (
                  <TimelineItem
                    type
                    key={points.id}
                    status={points.time}
                    message={points.statusDescription}
                    time={points.createdAt}
                    {...points}
                  />
                ),
            )}
        </Timeline>
      </Card>
    </Box>
  );
};
