import * as React from 'react';
import useReactRouter from 'use-react-router';
import {
  MainInformation,
  Timeline,
  Placeholder,
  Alert,
  TimelineItem,
  Button,
  Line,
  Messenger,
  ComponentSwipeable,
  Map,
  ButtonsHelp,
  StepperComponent,
  ScrollDiv,
  Layout,
  LogoShein,
} from '../../components';
import {
  GetTimelineDocument,
  GetTrackingDocument,
  GetTrackingByGuideDocument,
  GetTimelineByGuideDocument,
} from '../../graphql/generated';

import { useApolloClient } from 'react-apollo';
import { MAP_URL } from '../../config';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Box, Text, Card } from 'rebass/styled-components';
import Ivoy from '../../images/logo.svg';


export interface TrackingDetailProps {
  id: string;
  messenger: boolean;
  infoUrl: any;
}

const AlertError = (): JSX.Element => {
  const { history } = useReactRouter();

  return (
    <Alert
      title="No hay nada por acá"
      subtitle="¿En qué te podemos ayudar?"
      actions={
        <>
          {/*
            // @ts-ignore TS2589 */}
          <Button onClick={(): void => history.push('/')} raised>
            Necesito ayuda
          </Button>

          <Button onClick={(): void => history.push('/')} mt={2} outlined>
            Rastrear otra guía
          </Button>
        </>
      }
    />
  );
};

function reducer(state: any, action: any): any {
  switch (action.type) {
    case 'SET_TRACKING':
      return action.payload;
    case 'UPDATE_TRACKING':
      const newTracking = { ...state.data.tracking, ...action.payload };
      return { ...state, ...{ data: { tracking: newTracking } } };
    default:
      return state;
  }
}

export const TrackingDetail: React.FC<TrackingDetailProps> = ({
  id,
  messenger,
  infoUrl,
}: TrackingDetailProps): JSX.Element => {
  const dataUrl = infoUrl.pathname.split('/');
  const client = useApolloClient();
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  // @ts-ignore
  const initialState = { loading: true, data: null, error: null };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const matchesPhone = useMediaQuery('(max-width:780px)');
  /* eslint-disable @typescript-eslint/no-unused-vars*/
  const [ex, setEx] = React.useState(false);
  const [url, setUrl] = React.useState();

  if (!url) {
    setUrl(dataUrl);
  }

  React.useEffect((): void => {
    async function timelineQuery(): Promise<void> {
      // @ts-ignore
      if (url[1] === 'guide') {
        const payload = await client.query({
          query: GetTimelineByGuideDocument,
          // @ts-ignore
          variables: { guide: url[2] },
        });
        dispatch({ type: 'SET_TRACKING', payload });
      } else {
        const payload = await client.query({
          query: GetTimelineDocument,
          variables: { id },
        });
        dispatch({ type: 'SET_TRACKING', payload });
      }
    }

    timelineQuery();
  }, [client, id, url]);

  React.useEffect((): any => {
    // @ts-ignore
    if (url[1] === 'guide') {
      const subscription = client
        .subscribe({
          query: GetTrackingByGuideDocument,
          // @ts-ignore
          variables: { guide: url[2] },
        })
        .subscribe({
          next({
            data: {
              tracking: { node: payload },
            },
          }): void {
            console.log(payload);
            dispatch({ type: 'UPDATE_TRACKING', payload });
          },
        });
      return (): void => subscription.unsubscribe();
    } else {
      const subscription = client
        .subscribe({
          query: GetTrackingDocument,
          variables: { id },
        })
        .subscribe({
          next({
            data: {
              tracking: { node: payload },
            },
          }): void {
            console.log(payload);
            dispatch({ type: 'UPDATE_TRACKING', payload });
          },
        });
      return (): void => subscription.unsubscribe();
    }
  }, [client, id, url]);

  if (state.loading) {
    return (
      <Layout>
        <Card style={{ display: 'block' }} padding='20px' >
          <Card style={{ boxShadow: 'none', float: 'right' }} >
            <LogoShein src={Ivoy} />
          </Card>
          <Text fontSize='2em' margin='20px 0 20px' fontWeight='bold' >404</Text>
          <Text fontSize={4} fontWeight='bold'>
            Lo sentimos, la página que busca no existe.
            </Text>
          <Text fontSize={2} margin='22px 0 22px' fontWeight='bold'>
            Por favor verifique el enlace e intentelo de nuevo,
            es posible que este roto o que ya no exista.
            </Text>
          <Button
            onClick={() => window.location.replace('/')}
            outlined
            style={{ width: '175px' }}>
            Buscar una guía
          </Button>
        </Card>
      </Layout>
    );
  }

  if (state.data) {
    /* eslint-disable prefer-const*/
    let { tracking, trackings } = state.data;
    const pointsSameDay = [];
    const pointsTimeline: any = [];

    if (trackings) {
      tracking = trackings[0];
    }

    if (tracking) {
      tracking.history.map((dataPoints: any, index: any) =>
        pointsTimeline.push({
          id: dataPoints.id,
          message:
            index + 1 === dataPoints.status.order
              ? dataPoints.status.activeSubtitle
              : dataPoints.status.inactiveDescription,
          order: dataPoints.status.order,
          time: dataPoints.createdAt,
        }),
      );

      const mapPoints = tracking.points.map(
        // @ts-ignore
        ({ id, addresses }): any => ({
          id,
          position: addresses
            ? [addresses[0].latitude, addresses[0].longitude]
            : null,
        }),
      );
      if (tracking.points[1]) {
        pointsSameDay.push({
          id: tracking.points[1].id,
          position: tracking.points[1].addresses
            ? [
              tracking.points[1].addresses[0].latitude,
              tracking.points[1].addresses[0].longitude,
            ]
            : null,
        });
      }

      const visible =
        tracking.lastLocationRaw === null ||
        tracking.points.length === 0 ||
        tracking.lastStatus.order >= 9;

      return (
        <Box style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
          {tracking.points.length !== 0 &&
            !visible &&
            tracking.lastLocationRaw && (
              <Map
                zoom={15}
                mapTile={String(MAP_URL)}
                points={
                  tracking.businessType.name === 'EXPRESS'
                    ? mapPoints
                    : pointsSameDay
                }
                draggable={ex}
                messenger={{
                  id: 'id-messenger',
                  position: tracking.lastLocationRaw
                    ? [
                      tracking.lastLocationRaw[0].latitude,
                      tracking.lastLocationRaw[0].longitude,
                    ]
                    : undefined,
                  orientation: tracking.lastLocationRaw
                    ? tracking.lastLocationRaw[0].orientation
                    : undefined,
                }}
                businessType={tracking.businessType.name}
              />
            )}
          <Box width="100%" height="100%">
            <ComponentSwipeable
              header={
                <>
                  <Line />
                  <MainInformation
                    title={
                      tracking.businessType.name === 'EXPRESS'
                        ? tracking.points !== undefined &&
                        tracking.points[1].addresses[0].address
                        : `${tracking.lastAddress.address.neighborhood} ${tracking.lastAddress.address.street} ${tracking.lastAddress.address.externalNumber}`
                    }
                    message={
                      tracking.guide
                        ? 'Guía No. ' + tracking.guide
                        : 'Orden No. ' + tracking.orderId
                    }
                  />
                  <StepperComponent data={pointsTimeline} />
                </>
              }
              completed={messenger}
              visible={visible}
              tracking={[tracking, id]}
            >
              {tracking.messengerObj && tracking.lastStatus.order < 9 ? (
                <>
                  <Messenger
                    img={tracking.messengerObj.image}
                    title="Mensajero"
                    name={tracking.messenger}
                  />
                  <Line />
                </>
              ) : null}
              <MainInformation
                title={
                  tracking.businessType.name === 'EXPRESS'
                    ? tracking.points !== undefined &&
                    tracking.points[1].addresses[0].address
                    : `${tracking.lastAddress.address.neighborhood} ${tracking.lastAddress.address.street} ${tracking.lastAddress.address.externalNumber}`
                }
                message={
                  tracking.guide
                    ? 'Guía No. ' + tracking.guide
                    : 'Orden No. ' + tracking.orderId
                }
              />
              <Line />

              {/* messenger ? (
                <CardNotification title="Una firma será requerída al momento de la entrega" />
              ) : null */}

              {tracking.history && (
                <ScrollDiv isAddress={false}>
                  <Timeline>
                    {pointsTimeline.reverse().map((timeline: any) => (
                      <TimelineItem
                        key={timeline.id}
                        status={timeline.order === tracking.lastStatus.order}
                        {...timeline}
                      />
                    ))}
                  </Timeline>
                </ScrollDiv>
              )}
            </ComponentSwipeable>
            {matchesPhone && <ButtonsHelp id={id} data={tracking} />}
          </Box>
        </Box>
      );
    }
  }

  if (state.error) {
    console.error(state.error.message);
  }

  return <AlertError />;
};
