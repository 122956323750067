/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { SENTRY_DNS, SENTRY_ENV } from './config';
import app from 'firebase/app';
import 'firebase/analytics';
app.initializeApp({
  //other config
  measurementId: 'G-922N2Q109G',
  appId: '1:336356211621:web:7c392d21d2e63a8931064c',
  projectId: 'ivoy-tracking',
  apiKey: 'AIzaSyBjIaeiX013hwTqljZNMEG4uA1B8xCc4v4',
});
app.analytics();

Sentry.init({ dsn: SENTRY_DNS, environment: SENTRY_ENV });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
