import styled from 'styled-components';
import { TextField } from '@rmwc/textfield';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

export const Input = styled(TextField)`
  width: 100%;
`;
