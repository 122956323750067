import styled from 'styled-components';
import { theme } from 'styled-tools';

export const ContainerPadding = styled.div`
  font-family: ${theme('fonts.body')};
  padding: 16px;
`;

export const Container = styled.div`
  font-family: ${theme('fonts.body')};
`;
