import styled from 'styled-components';
import { theme } from 'styled-tools';

export const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  border: 1px solid ${theme('colors.primary')};
  padding: 10px;
  padding-left: 20px;
  width: 20px;
  margin: 5px;
  text-transform: uppercase;
`;

export const WrapperButton = styled.div`
  display: flex;
  padding: 5%;
  align-self: center;
  justify-content: center;
  align-items: center;
`;
