import * as React from 'react';

import { Container, ContainerProps } from './style';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarFixedAdjust,
} from '../../components';

export interface LayoutProps extends ContainerProps {
  children: React.ReactNode;
  topAppBar?: {
    title: string;
  };
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  topAppBar,
  ...props
}: LayoutProps): JSX.Element => {
  return (
    <>
      {topAppBar && (
        <>
          <TopAppBar>
            <TopAppBarRow>
              <TopAppBarSection>
                <TopAppBarTitle>{topAppBar.title}</TopAppBarTitle>
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
          <TopAppBarFixedAdjust />
        </>
      )}

      <>
        <Container maxWidth="xs" {...props}>
          {children}
        </Container>
      </>
    </>
  );
};
