import React, { FC } from 'react';

interface BadgeProperties {
  text: string;
  color: string;
  variant?: 'contained' | 'outlined';
  width?: string;
  textColor?: string;
  textColorHex?: string;
  styleBadge?: string;
}

const defaultProps: BadgeProperties = {
  text: 'Admin',
  color: 'purple',
  variant: 'outlined',
  width: '32',
};

export const Badge: FC<BadgeProperties> = ({
  text,
  color,
  variant,
  width,
  textColor,
  textColorHex,
  styleBadge,
}: BadgeProperties) => {
  const CONTAINER_STATUS = `flex capitalize
    ${text === 'admin' ? `text-${color}` : `text-${color}`}
    `;
  const CONTAINER_SVG = 'w-4 h-4 pt-1.5 pr-1.5';
  const CONTAINER_PARRAFO = 'text-gray-3';

  const OutLined = () => {
    return (
      <div className={CONTAINER_STATUS}>
        <svg
          className={CONTAINER_SVG}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <circle cx="8" cy="8" r="6" />
        </svg>
        <span className={CONTAINER_PARRAFO}>{text}</span>
      </div>
    );
  };

  const Contained = () => {
    return (
      <div
        className={`text-${textColor} flex items-center justify-center px-1.5 rounded-sm bg-${color} text-white font-bold text-xs ${textColorHex ? '' : 'font-semibold'
          } ${styleBadge ? styleBadge : 'w-${width}'}`}
        style={{
          color: textColorHex,
          padding: '4px 6px',
          backgroundColor: color,
          width: 'auto',
          maxWidth: 155,
          fontSize: 14,
        }}
      >
        {text}
      </div>
    );
  };

  return variant === 'contained' ? <Contained /> : <OutLined />;
};

Badge.defaultProps = defaultProps;
