import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { StepIconProps } from '@material-ui/core/StepIcon';
import ArchiveIcon from '@material-ui/icons/Archive';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import HomeIcon from '@material-ui/icons/Home';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        ' linear-gradient(to right, rgba(73,155,234,1) 0%, rgba(147,49,144,1) 100%);',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        ' linear-gradient(to right, rgba(73,155,234,1) 0%, rgba(147,49,144,1) 100%);',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      ' linear-gradient(to right, rgba(73,155,234,1) 0%, rgba(73,155,234,1) 100%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      ' linear-gradient(to right, rgba(73,155,234,1) 0%, rgba(73,155,234,1) 100%);',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <ArchiveIcon />,
    2: <MotorcycleIcon />,
    3: <HomeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ['En proceso', 'En camino', 'Entregado'];
}

interface StepperComponentProps {
  data: Array<any>;
}

export const StepperComponent: React.FC<StepperComponentProps> = ({
  data,
}: StepperComponentProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  /* eslint-disable array-callback-return*/
  data.map((items) => {
    if (items.order === 7 && activeStep === 0) {
      setActiveStep(1);
    } else if (items.order === 9 && activeStep === 1) {
      setActiveStep(2);
    }
  });

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
