import * as React from 'react';
import { MainWrapper, Icon, MainText } from './style';
import logo from '../../images/backArrow.png';
import useReactRouter from 'use-react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

interface HeaderProps {
  text?: string;
  icon: boolean;
  onClick?: any;
}

export const Header: React.FC<HeaderProps> = ({
  text,
  icon,
  onClick,
}: HeaderProps): JSX.Element => {
  const { history } = useReactRouter();
  const matchesPhone = useMediaQuery('(max-width:780px)');
  return (
    <MainWrapper style={{ marginTop: !matchesPhone ? '13%' : 0 }}>
      {icon && (
        <Icon src={logo} onClick={onClick ? onClick : () => history.goBack()} />
      )}
      <MainText>{text}</MainText>
    </MainWrapper>
  );
};
