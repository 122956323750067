import L from 'leaflet';

export const pointIcon = new L.Icon({
  iconRetinaUrl: require('../../images/markerMap.png'),
  iconUrl: require('../../images/markerMap.png'),
  iconSize: [41, 41],
});
export const messengerIcon = new L.Icon({
  iconRetinaUrl: require('../../images/markerBike.png'),
  iconUrl: require('../../images/markerBike.png'),
  iconSize: [41, 50],
});

export const commerceIcon = new L.Icon({
  iconRetinaUrl: require('../../images/markerCommerce.png'),
  iconUrl: require('../../images/markerCommerce.png'),
  iconSize: [31, 40],
});

export const houseIcon = new L.Icon({
  iconRetinaUrl: require('../../images/markerHouse.png'),
  iconUrl: require('../../images/markerHouse.png'),
  iconSize: [38, 40],
});

const DefaultIcon = L.icon({
  iconUrl: 'example',
  shadowUrl: 'example',
});

L.Marker.prototype.options.icon = DefaultIcon;
