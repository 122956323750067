import React from 'react';

//@ts-ignore
function Tooltip(props) {
  const { text, children } = props;
  return (
    <div className="tooltip ml-1" style={{ marginLeft: 5, cursor: 'pointer' }}>
      {children}
      <span className="tooltiptext">{text}</span>
    </div>
  );
}

export default Tooltip;
