import * as React from 'react';
import { Layout } from '../../containers/Layout';
import { SaveAddressContainer } from '../../containers';
import { RouteComponentProps } from 'react-router';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import useReactRouter from 'use-react-router';

export const SaveAddressPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps): JSX.Element => {
  const [data, setData] = React.useState();
  const [error, setError] = React.useState(false);
  const [business, setBusiness] = React.useState();
  const dataUrl = location.pathname.split('/');
  const { history } = useReactRouter();

  if (location.state !== undefined && !data) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    const { data, business } = location.state;
    setData(data);
    setBusiness(business);
  } else if (location.state === undefined && !error) {
    setError(true);
  }

  if (error) {
    return (
      <Alert
        title={
          error
            ? 'No se ha podido verificar el código'
            : 'No fue posible comunicarse con el servidor'
        }
        subtitle="Intentalo de nuevo más tarde"
        actions={
          <>
            {/*
          // @ts-ignore TS2589 */}
            <Button onClick={() => history.push(`/${dataUrl[1]}`)} raised>
              Volver
            </Button>

            <Button onClick={() => history.push('/')} mt={2} outlined>
              Rastrear otra guía
            </Button>
          </>
        }
      />
    );
  }

  return (
    <Layout overflow="none">
      <SaveAddressContainer id={dataUrl[1]} data={data} business={business} />
    </Layout>
  );
};
