import styled from 'styled-components';

export { Box, Button, Heading, Text } from 'rebass/styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  transition: 0.3s;
`;
