import * as React from 'react';
import firebase from 'firebase';
import {
  Button,
  Container,
  Header,
  Input,
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  AppBar,
} from '../../components';
import { Text } from 'rebass/styled-components';
import { Layout } from '../../containers/Layout';
import { CreateTrackingIssueDocument } from '../../graphql/generated';
import { useApolloClient } from 'react-apollo';
import useReactRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import useForm from 'react-hook-form';
import axios from 'axios';
import {
  CLIENT_URL,
  GOOGLE_INTEGRATION_GRAPHQL_URL,
  GOOGLE_INTEGRATION_KEY,
} from '../../config';

interface GeneralHelpPageProps {
  id: string;
  location: any;
}

interface GeneralHelpPageForm {
  comment: string;
}

function reducer(state: any, action: any): any {
  if (action.type === 'POST_ISSUE') {
    return action.payload;
  } else {
    return state;
  }
}

export const GeneralHelpPage: React.FC<RouteComponentProps<
  GeneralHelpPageProps
>> = ({
  match,
  location,
}: RouteComponentProps<GeneralHelpPageProps>): JSX.Element => {
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  // @ts-ignore
  const {
    idGuide,
    name,
    title,
    description,
    input,
    lite,
    items,
    business,
    saveAddress,
  }: any = location.state;
  const client = useApolloClient();
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  // @ts-ignore
  const initialState = { loading: true, data: { data: null }, error: null };
  const [showDialog, setShowDialog] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [submmiting, setSubmiting] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [error, setError] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');
  /* eslint-disable @typescript-eslint/no-unused-vars*/
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { history } = useReactRouter();
  const dataUrl = location.pathname.split('/');
  /* eslint-disable @typescript-eslint/no-unused-vars*/
  const { register, handleSubmit } = useForm<GeneralHelpPageForm>();

  if (saveAddress && !address) {
    setAddress(saveAddress);
  }

  const onSubmit = async ({ comment }: GeneralHelpPageForm): Promise<void> => {
    setDisabled(true);
    setSubmiting(true);
    const data = {
      tracking: { connect: { id: match.params.id } },
      issue: { connect: { id: idGuide } },
      comments: comment === undefined ? name : comment.replace(/\n/g, '\\n'),
      status: 'OPEN',
    };

    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };

    async function postIssuesQuery(): Promise<void> {
      const queryLite = `mutation {\n  createTicket(id: "${idGuide}", message: "${data.comments}", type: "${title}", business: "${business}") {\n    create\n    duplicated\n  }\n}\n`;
      if (lite) {
        axios
          .post(
            GOOGLE_INTEGRATION_GRAPHQL_URL,
            {
              query: queryLite,
            },
            config,
          )
          .then((response) => {
            const { createTicket } = response.data.data;
            if (createTicket.duplicated) {
              setShowDialog(true);
              setDisabled(false);
              setSubmiting(false);
              setModalMessage(
                `No ha sido posible enviar su mensaje porque ya tiene una solicitud del mismo tipo "${title}", nuestro equipo de operaciones se pondrá en contacto con usted para resolver su caso`,
              );
            } else if (createTicket.create) {
              setShowDialog(true);
              setDisabled(false);
              setSubmiting(false);
              setModalMessage(
                'Su mensaje ha sido enviado, nuestro equipo de operaciones se pondrá en contacto con usted para resolver su caso',
              );
            }
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setShowDialog(true);
            setDisabled(false);
            setSubmiting(false);
            setModalMessage(
              'No ha sido posible enviar su mensaje, intentalo de nuevo',
            );
            firebase
              .analytics()
              .logEvent('create_ticket_catch', { guide: idGuide });
          });
      } else {
        const payload = await client
          .mutate({
            mutation: CreateTrackingIssueDocument,
            variables: data,
          })
          .then(async () => {
            axios({
              method: 'post',
              url: `${CLIENT_URL}create-ticket`,
              data,
            })
              .then((response) => {
                setError(false);
                setShowDialog(true);
              })
              .catch((err) => {
                console.log(err);
                setError(false);
                setShowDialog(true);
              });
          })
          .catch(() => {
            setError(true);
            setShowDialog(true);
          });

        dispatch({ type: 'POST_ISSUE', payload });
      }
    }
    postIssuesQuery();
  };

  React.useEffect(() => {
    const addressString = address.replace(/\s/g, '');

    if (addressString.length > 15) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [address]);

  return (
    <>
      <AppBar
        title="Realiza el seguimiento de tu paquete"
        iconClick={() => history.push('/')}
        guide={idGuide}
      />
      <Container header={<Header icon text="Dinos que pasó" />}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Layout>
            <Text fontSize={20}>{name}</Text>
            <Text fontSize={15} opacity={0.5} mb="10px" mt={10}>
              {description}
            </Text>
            <Text fontSize={15} opacity={0.5} mb="10px" fontWeight="bold">
              {items}
            </Text>
            {input && (
              <Input
                textarea
                rows={6}
                name="comment"
                value={address}
                invalid={disabled}
                style={{ backgroundColor: '#f3f3f3' }}
                inputRef={register({ required: true, minLength: 5 })}
                onChange={(event: any) => setAddress(event.target.value)}
              />
            )}
            {disabled && (
              <Text fontSize={13} pt="10px" opacity={0.5} color="red">
                Revisa el campo
              </Text>
            )}
            {/*
              // @ts-ignore TS2589 */}
            <Button
              type="submit"
              label={submmiting ? 'Enviando...' : 'Enviar'}
              mt="10px"
              py="14px"
              height="auto"
              disabled={disabled || submmiting}
              raised
            />
          </Layout>
        </form>
        <Dialog open={showDialog} onClose={(): void => setShowDialog(false)}>
          <DialogContent>{modalMessage}</DialogContent>
          <DialogActions>
            <DialogButton
              action="close"
              onClick={() =>
                error
                  ? setShowDialog(false)
                  : history.push(`/guide/${dataUrl[1]}`)
              }
              isDefaultAction
            >
              Volver
            </DialogButton>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};
