import get from 'lodash.get';
import { transparentize } from 'polished';

export const colors = {
  text: '#2F2D2D',
  background: '#fff',
  primary: '#f69f36',
  secondary: '#f69f36',
  muted: '#f6f6f6',
  gray: '#444444',
  alto: '#D8D8D8',
  dustyGray: '#979797',
  white: '#fafafa',
  sand: '#e8bd80',
  fadedOrange: '#ed804a',
  brownishOrange: '#ce6b2e',
  blackRgba: 'rgba(0, 0, 0, 0.1)',
  blueFirstButton: 'rgba(93, 158, 149, 0.950)',
  blueSecondButton: '#44a095',

  get placeholder(): string {
    return this.dustyGray;
  },

  transparent(amout = 1, color: string): string {
    return transparentize(amout, get(this, color));
  },
};

export default {
  colors,
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Poppins, serif',
    monospace: '"Roboto Mono", monospace',
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 34, 48, 60, 96],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    icon: 24,
    avatar: 48,
  },
  radii: {
    default: 4,
    circle: 99999,
  },
  shadows: {
    1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 2,
    },
    link: {
      color: 'primary',
    },
    nav: {
      variant: 'text.caps',
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  buttons: {
    primary: {
      variant: 'text.caps',
      fontSize: 2,
      fontWeight: 'body',
      color: 'background',
      bg: 'primary',
      borderRadius: 0,
      paddingX: 2,
      paddingY: 3,
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 2px',
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary',
    },
  },
  input: {
    primary: {
      borderBottom: '2px solid #d1c4e9',
    },
  },
  timelineItems: {
    pending: {
      '&::before': {
        background: colors.dustyGray,
      },
    },
    current: {
      fontWeight: 500,
      '&::before': {
        boxShadow: `0 0 0 4px ${colors.transparent(0.7, 'primary')}`,
        background: colors.primary,
        top: '3px',
      },
    },
    done: {
      '&::before': {
        background: colors.primary,
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
};
