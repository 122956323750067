import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  padding: 7%;
  transition: 0.1s;
`;

export const Tab = styled.div`
  position: absolute;
  top: 10px;
  height: 5px;
  width: 10%;
  background-color: gray;
  right: 45%;
  left: 45%;
  border-radius: 10px;
  transition: 0.1s;
`;

export const ContainerNotification = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -55px;
  transition: 0.1s;
`;

export const WrapperClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: gray;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperHeader = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  padding-top: 5px;
`;

export const WrapperItems = styled.div`
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const WrapperItemsButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: 0.1s;
  z-index: 1300;
  width: 100%;
  height: 100%;
`;
