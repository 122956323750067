import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  GOOGLE_INTEGRATION_KEY,
  GOOGLE_INTEGRATION_URL,
  MAP_URL,
} from '../../config';
import useReactRouter from 'use-react-router';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Box,
  AppBar,
  CustomPaper,
} from '../../components';
import { pointIcon } from '../../components/Map/icons';
import { MapLeaflet } from '../../components/Map/style';
import { WrapperRow } from '../../components/AddressesCard/style';
import { Marker, TileLayer } from 'react-leaflet';
import { SwipeSteps, WrapperButtons } from '../AddressContainer/style';
import { items } from '../HelpLiteContainer/items';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { getSteps } from '../AddressContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import firebase from 'firebase';

interface SaveAddressProps {
  data: any;
  id: any;
  business: string;
}

export const SaveAddressContainer: React.FC<SaveAddressProps> = ({
  data,
  id,
  business,
}: SaveAddressProps): JSX.Element => {
  const { history } = useReactRouter();
  const steps = getSteps();
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const [showModalError, setShowModalError] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [modalActualStep, setModalActualStep] = React.useState(true);
  const [activeStep] = React.useState(2);
  const {
    area,
    localityValue,
    subLocalityValue,
    routeValue,
    streetNumberValue,
    intNumberValue,
    cpValue,
    referenceValue,
    location,
  } = data;

  const confirmData = {
    area: area,
    street: routeValue,
    neighborhood: subLocalityValue,
    state: localityValue,
    zipcode: cpValue,
    streetNumber: streetNumberValue,
    intNumber: intNumberValue,
    reference: referenceValue,
  };

  const onSubmit = async (): Promise<void> => {
    firebase.analytics().logEvent('page_reconfirm', { id });
    setLoadingAddress(true);
    const address = `${routeValue}, ${
      streetNumberValue ? streetNumberValue : '-'
    }, ${
      intNumberValue ? intNumberValue : '-'
    }, ${localityValue}, ${subLocalityValue}, ${referenceValue}, ${cpValue}`;
    const config = {
      headers: {
        'x-api-key': GOOGLE_INTEGRATION_KEY,
      },
    };
    const query = `mutation {\n  changeAddress(
        guide: "${id}",
        state: "${area} ",
        municipality: "${localityValue}",
        neighborhood: "${subLocalityValue}",
        street: "${routeValue}",
        latitude: ${location[0]},
        longitude: ${location[1]},
        external: "${streetNumberValue ? streetNumberValue : '-'}",
        internal: "${intNumberValue ? intNumberValue : '-'}",
        reference: "${referenceValue}",
        zipCode: ${Number(cpValue)},
        originalAddress: "${address}"
      ) {\n    guide\n   }\n }`;
    axios
      .post(
        GOOGLE_INTEGRATION_URL,
        {
          query: query,
        },
        config,
      )
      .then((response) => {
        const { changeAddress } = response.data.data;
        if (changeAddress.guide) {
          setShowModalSuccess(true);
        } else {
          setShowModalSuccess(true);
          setShowModalError(true);
        }
        setLoadingAddress(false);
      })
      .catch((err) => {
        console.log(err);
        setShowModalSuccess(true);
        setShowModalError(true);
        setLoadingAddress(false);
        history.push({
          pathname: `/${id}/general-help`,
          state: {
            lite: true,
            idGuide: id,
            business: 'SD',
            ...items['SD'][0],
            saveAddress: address,
          },
        });

        firebase.analytics().logEvent('save_address_catch', { guide: id });
      });
  };

  const matchesPhone = useMediaQuery('(max-width:780px)');

  return (
    <>
      {!matchesPhone && (
        <AppBar
          title="Información de tu paquete"
          iconClick={() => history.push('/')}
          guide={id}
        />
      )}
      <CustomPaper style={{ marginTop: !matchesPhone ? '12%' : 0 }}>
        <SwipeSteps>
          <Stepper
            style={{ backgroundColor: 'transparent', paddingBottom: 5 }}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel> </StepLabel>
              </Step>
            ))}
          </Stepper>
        </SwipeSteps>
        <Box padding={2}>
          <MapLeaflet
            // @ts-ignore
            center={location}
            zoom={16}
            style={{ position: 'static', width: '100%', height: 200 }}
            dragging={false}
          >
            <TileLayer
              url={String(MAP_URL)}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              /* eslint-disable @typescript-eslint/ban-ts-ignore*/
              //@ts-ignore
              icon={pointIcon}
              position={location}
              draggable={false}
            />
          </MapLeaflet>
          <Box
            width="100%"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <WrapperRow style={{ justifyContent: 'space-between' }}>
              <ListItem>
                <ListItemText secondary={subLocalityValue} primary="Colónia" />
              </ListItem>
              <ListItem>
                <ListItemText secondary={routeValue} primary="Calle" />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={streetNumberValue}
                  primary="Número exterior"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={intNumberValue ? intNumberValue : 's/n'}
                  primary="Número interior"
                />
              </ListItem>
            </WrapperRow>
            <WrapperRow>
              <ListItem>
                <ListItemText secondary={localityValue} primary="Municipio" />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={cpValue ? cpValue : 's/n'}
                  primary="Código postal"
                />
              </ListItem>
              <ListItem>
                <ListItemText secondary={referenceValue} primary="Referencia" />
              </ListItem>
            </WrapperRow>
          </Box>
          <WrapperButtons>
            {/*
                  //@ts-ignore*/}
            <Button
              onClick={() =>
                history.push({
                  pathname: `/${id}/address`,
                  state: {
                    id,
                    edit: true,
                    data: confirmData,
                    business,
                    locationAddress: location,
                    confirm: true,
                    step: 1,
                    streetNumber: streetNumberValue,
                  },
                })
              }
              mt="10px"
              mb="10px"
              py="14px"
              mr="10px"
              height="auto"
              outlined
            >
              Modificar
            </Button>
            {/*
                  //@ts-ignore*/}
            <Button
              onClick={() => onSubmit()}
              label={loadingAddress ? 'Enviando...' : 'Enviar'}
              disabled={loadingAddress}
              mt="10px"
              mb="10px"
              py="14px"
              height="auto"
              raised
            />
          </WrapperButtons>
          <Dialog
            open={showModalSuccess}
            onClose={() => !showModalError && history.push(`/guide/${id}`)}
            style={{ zIndex: 2700 }}
          >
            <DialogTitle>{showModalError ? 'Ops...' : 'Perfecto!'}</DialogTitle>
            <DialogContent>
              {showModalError
                ? 'No ha sido posible realizar la solicitud de cambio de cambio de dirección, intentalo de nuevo'
                : 'La confirmación de dirección se realizó correctamente'}
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="close"
                onClick={() =>
                  !showModalError
                    ? history.push(`/guide/${id}`)
                    : setShowModalSuccess(!showModalSuccess)
                }
                isDefaultAction
              >
                Continuar
              </DialogButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={modalActualStep}
            onClose={() => setModalActualStep(!modalActualStep)}
            style={{ zIndex: 2700 }}
          >
            <DialogTitle>Revisa y Envía</DialogTitle>
            <DialogContent>
              <Box>¡Ya casi terminamos!</Box>
              Dale un último vistazo a tu información y si todo esta bien,
              presiona enviar.
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="close"
                onClick={() => setModalActualStep(!modalActualStep)}
                isDefaultAction
              >
                Continuar
              </DialogButton>
            </DialogActions>
          </Dialog>
        </Box>
      </CustomPaper>
    </>
  );
};
