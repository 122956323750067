import { theme } from 'styled-tools';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const MainContainer = styled(Box)`
  width: 100%;
  height: 100%;
  // position: absolute;
  z-index: 1;
  background-color: ${theme('colors.background')};
`;

export const ContainerList = styled.div`
  left: 0;
  top: 61px;
  right: 0;
  // position: absolute;
`;
