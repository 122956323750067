import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Text } from 'rebass/styled-components';
import { ReactComponent } from '../../images/exclamationButton.svg';

export const Container = styled.div`
  display: flex;
  background-color: ${theme('colors.sand')};
  height: auto;
  width: 100%;
  border-radius: 2px;
  border: solid 1px ${theme('colors.fadedOrange')};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
`;

export const TextCard = styled(Text)`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2%;
  color: ${theme('colors.brownishOrange')};
  font-size: 12px;
`;

export const Image = styled(ReactComponent)`
  width: 20px;
  height: 20px;
  padding-left: 4%;
  padding-right: 1%;
`;
