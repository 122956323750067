import * as React from 'react';
import { HelpLiteContainer, ScreenHelp } from '../../containers';
import { RouteComponentProps } from 'react-router';

interface HelpPageProps {
  id: string;
}

export const HelpPage: React.FC<RouteComponentProps<HelpPageProps>> = ({
  match,
  location,
}: RouteComponentProps<HelpPageProps>): JSX.Element => {
  /* eslint-disable @typescript-eslint/ban-ts-ignore*/
  //@ts-ignore
  const { data, lite, business } = location.state;
  if (!lite) {
    return (
      <ScreenHelp
        headerText="Necesito ayuda"
        id={match.params.id}
        data={data}
        business={business}
      />
    );
  } else {
    return (
      <HelpLiteContainer data={data} infoUrl={location} business={business} />
    );
  }
};
