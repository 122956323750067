import styled from 'styled-components';
import { Map } from 'react-leaflet';
import { Text } from 'rebass/styled-components';
import { theme } from 'styled-tools';

export const MapLeaflet = styled(Map)`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`;

export const WrapperTime = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme('colors.primary')};
  color: ${theme('colors.white')};
`;

export const WrapperTool = styled.div`
  padding: 5px;
  background-color: ${theme('colors.primary')};
  color: ${theme('colors.white')};
  font-size: 14px;
`;

export const MainTimeText = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  line-height: 0.9;
`;

export const SubTimeText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 0.7;
`;

export const WrapperText = styled.div`
  padding: 3%;
  padding-left: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme('colors.white')}
  color: ${theme('colors.black')};
`;
