import * as React from 'react';
import { Marker, TileLayer, Tooltip } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { messengerIcon, commerceIcon, houseIcon } from './icons';
import { useLeafletBounds } from 'use-leaflet';
import { MapLeaflet, WrapperTime, MainTimeText, SubTimeText } from './style';
import L from 'leaflet';
import 'leaflet-rotatedmarker';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

export interface MapProps {
  mapTile: string;
  center?: LatLngExpression;
  zoom: number;
  points?: MapPoint[];
  messenger?: MapMessenger;
  draggable: boolean;
  businessType?: string;
  lastStatus?: Status;
}

export interface Status {
  name: string;
  order: number;
}

export interface MapPoint {
  id: string;
  position: LatLngExpression;
}

export interface MapMessenger {
  id: string;
  name?: string;
  position: LatLngExpression;
  orientation: number;
}

export const Map: React.FC<MapProps> = ({
  mapTile,
  center,
  zoom,
  points,
  messenger,
  draggable,
  businessType,
  lastStatus,
}): JSX.Element => {
  let mapCenter = {
    latitude: messenger.position
      ? messenger.position[0]
      : points[1].position[0],
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    // @ts-ignore
    longitude: messenger.position
      ? messenger.position[1]
      : points[1].position[1],
  };
  const latLngMarkerBike = {
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    // @ts-ignore
    latitude: messenger.position
      ? messenger.position[0]
      : points[1].position[0],
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    // @ts-ignore
    longitude: messenger.position
      ? messenger.position[1]
      : points[1].position[1],
    orientation: messenger.orientation,
  };
  const [zoomValue, setZoomValue] = React.useState(0);
  const [latLngScreen, setLatLngScreen] = React.useState({
    latitudeS: 0,
    longitudeW: 0,
    latitudeN: 0,
    longitudeE: 0,
  });
  const [isMapInit, setIsMapInit] = React.useState(false);
  const [map, setMap] = React.useState({});

  const ZoomTool = (props: any) => {
    const [[south, west], [north, east]] = useLeafletBounds();
    if (latLngScreen.latitudeS !== south) {
      setLatLngScreen({
        latitudeS: south,
        latitudeN: north,
        longitudeE: east,
        longitudeW: west,
      });
    }
    if (businessType === 'EXPRESS') {
      return (
        <Tooltip {...props}>
          <WrapperTime>
            {/*
              eslint-disable @typescript-eslint/no-use-before-define*/}
            <MainTimeText>{isMapInit && time}</MainTimeText>
            <SubTimeText>{isMapInit && 'min'}</SubTimeText>
          </WrapperTime>
          {/*<WrapperText>
            Primer entrega
          </WrapperText>*/}
        </Tooltip>
      );
    } else return <></>;
  };

  if (
    latLngScreen.latitudeS > latLngMarkerBike.latitude ||
    latLngScreen.latitudeN < latLngMarkerBike.latitude ||
    latLngScreen.longitudeW > latLngMarkerBike.longitude ||
    latLngScreen.longitudeE < latLngMarkerBike.longitude
  ) {
    if (latLngMarkerBike.latitude !== mapCenter.latitude) {
      mapCenter = {
        latitude: latLngMarkerBike.latitude,
        longitude: latLngMarkerBike.longitude,
      };
    }
  }

  function onZoom(zoom: number) {
    if (zoomValue !== zoom) {
      setZoomValue(zoom);
    }
  }

  function saveMap(map: any) {
    setMap(map);
    setIsMapInit(true);
  }

  function iconSelect(value: number) {
    if (value !== 0 && businessType === 'EXPRESS') {
      return houseIcon;
    } else if (value === 0 && businessType === 'SAMEDAY') {
      return houseIcon;
    } else {
      return commerceIcon;
    }
  }

  const [time, setTime] = React.useState(25);
  const [firstStateMap, setFirstStateMap] = React.useState(true);
  const [showLeafletElement, leafletElement] = React.useState();

  if (
    isMapInit &&
    businessType === 'EXPRESS' &&
    messenger.position &&
    firstStateMap
  ) {
    /* eslint-disable @typescript-eslint/ban-ts-ignore*/
    // @ts-ignore
    leafletElement(
      // @ts-ignore
      L.Routing.control({
        // @ts-ignore
        waypoints: [L.latLng(messenger.position), L.latLng(points[0].position)],
        // @ts-ignore
        serviceUrl: 'http://router.project-osrm.org/route/v1',
        lineOptions: {
          addWaypoints: false,
        },
        // @ts-ignore
      }).addTo(map.leafletElement),
    );
    setFirstStateMap(false);
  }

  if (showLeafletElement) {
    // @ts-ignore
    showLeafletElement.hide();
    // @ts-ignore
    showLeafletElement._container.style.display = 'None';
    // @ts-ignore
    showLeafletElement.on('routesfound', function (event) {
      const routes = event.routes;
      const summary = routes[0].summary;
      const tim = Math.round((summary.totalTime % 3600) / 60);
      //const km = summary.totalDistance / 1000;
      setTime(tim);
    });
    // @ts-ignore
    const latLngRoute = showLeafletElement.options.waypoints[0];
    // @ts-ignore
    if (
      !firstStateMap &&
      latLngRoute.lat !== messenger.position[0] &&
      latLngRoute.lng !== messenger.position[1]
    ) {
      // @ts-ignore
      showLeafletElement.remove();
      setFirstStateMap(true);
    }
  }

  const matchesPhone = useMediaQuery('(max-width:780px)');

  return (
    <MapLeaflet
      // @ts-ignore
      center={[mapCenter.latitude - 0.005, mapCenter.longitude - 0.003]}
      zoom={zoomValue !== 0 ? zoomValue : zoom}
      onzoomend={(e: any) => onZoom(e.target._zoom)}
      // @ts-ignore
      ref={(ref) => saveMap(ref)}
      style={{ position: matchesPhone ? 'absolute' : 'static' }}
    >
      <TileLayer
        url={mapTile}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {points &&
        points.map(
          (point, index): JSX.Element => (
            <Marker
              key={point.id}
              position={point.position}
              // @ts-ignore
              icon={iconSelect(index)}
            />
          ),
        )}
      {messenger!.position && (
        <Marker
          position={[latLngMarkerBike.latitude, latLngMarkerBike.longitude]}
          // @ts-ignore
          icon={messengerIcon}
          rotationAngle={latLngMarkerBike.orientation}
        >
          <ZoomTool permanent direction="left" />
        </Marker>
      )}
    </MapLeaflet>
  );
};

Map.defaultProps = {
  center: [19.3907336, -99.1436127],
};
