import * as React from 'react';

import { Box, Container } from './style';
import { ReactComponent as DeliveryIcon } from '../../images/warehouse.svg';

export interface SvgIconProps {
  image?: React.ReactNode;
  width: string;
  mt?: number;
  mb?: number;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  image,
  width,
  mt,
  mb,
}: SvgIconProps): JSX.Element => (
  <Container>
    {React.isValidElement(image) && (
      <Box marginTop={mt} marginBottom={mb}>
        {React.cloneElement(image, {
          //@ts-ignore
          width: width,
          height: 'auto',
        })}
      </Box>
    )}
  </Container>
);

SvgIcon.defaultProps = {
  image: <DeliveryIcon />,
};
