export interface TimelineStatus {
  title?: string;
  message: string;
  businessType: string[];
}

export interface DoneTimelineStatus extends TimelineStatus {
  showFromStatus: string;
}

export interface CurrentTimelineStatus extends TimelineStatus {
  title: string;
  showInStatus: string;
  showMapInBusiness?: string[];
}

export interface PendingTimelineStatus extends TimelineStatus {
  order: number;
  showUntilstatus: string;
}

export const doneStatuses: DoneTimelineStatus[] = [
  {
    message: 'Asignó',
    businessType: ['EXPRESS'],
    showFromStatus: 'ASIGNADO',
  },
  {
    message: 'Recolectó',
    businessType: ['*'],
    showFromStatus: 'RECOLECTADO',
  },
];

export const currentStatuses: CurrentTimelineStatus[] = [
  {
    title: 'Entrega en proceso',
    message: 'Te entregaremos entre las 6PM y las 8PM',
    businessType: ['SAMEDAY'],
    showInStatus: 'EN_PROCESO',
  },
  {
    title: 'Buscando mensajero',
    message: 'Buscando mensajero',
    businessType: ['EXPRESS'],
    showInStatus: 'BUSCANDO_MENSAJERO',
  },
  {
    title: 'Mensajero asignado',
    message: 'Mensajero asignado',
    businessType: ['EXPRESS'],
    showMapInBusiness: ['EXPRESS'],
    showInStatus: 'MENSAJERO_ASIGNADO',
  },
  {
    title: 'Esperando paquete',
    message: 'Esperando',
    businessType: ['EXPRESS'],
    showInStatus: 'ESPERANDO_PAQUETE',
  },
  {
    title: 'Paquete recolectado',
    message: 'Recolectado',
    businessType: ['*'],
    showMapInBusiness: ['SAMEDAY'],
    showInStatus: 'PAQUETE_RECOLECTADO',
  },
  {
    title: 'En camino a tu dirección',
    message: 'En camino',
    businessType: ['*'],
    showInStatus: 'MENSAJERO_EN_CAMINO',
  },
  {
    title: 'Mensajero cerca',
    message: 'Cerca',
    businessType: ['*'],
    showInStatus: 'MENSAJERO_CERCA',
  },
  {
    title: 'Paquete entregado',
    message: 'Entregado',
    businessType: ['*'],
    showInStatus: 'PAQUETE_ENTREGADO',
  },
  {
    title: 'Entrega cancelada',
    message: 'Cancelado',
    businessType: ['*'],
    showInStatus: 'ENTREGA_CANCELADA',
  },
];

export const pendingStatuses: PendingTimelineStatus[] = [
  {
    message: 'Asignará',
    businessType: ['EXPRESS'],
    showUntilstatus: 'BUSCANDO_MENSAJERO',
    order: 1,
  },
  {
    message: 'Esperará',
    businessType: ['EXPRESS'],
    showUntilstatus: 'MENSAJERO_ASIGNADO',
    order: 2,
  },
  {
    message: 'Tu mensajero recolectará el paquete',
    businessType: ['*'],
    showUntilstatus: 'ESPERANDO_PAQUETE',
    order: 3,
  },
  {
    message: 'Tu mensajero irá a tu dirección',
    businessType: ['*'],
    showUntilstatus: 'PAQUETE_RECOLECTADO',
    order: 4,
  },
  {
    message: 'Te entregaremos en la dirección Carlos B Zetina 55',
    businessType: ['*'],
    showUntilstatus: 'EN_CAMINO',
    order: 5,
  },
];
