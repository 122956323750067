import { theme } from 'styled-tools';
import styled from 'styled-components';
import { Button } from 'rebass/styled-components';

export const WrapperButtons = styled.div`
  font-family: ${theme('fonts.body')};
  display: flex;
  width: 100%;
  flex-direction: row;
  z-index: 1300;
`;

export const ButtonHelp = styled(Button)`
  flex: 1;
  background-color: ${theme('colors.blueSecondButton')};
  font-size: 12px;
  align-self: center;
  color: ${theme('colors.background')};
`;

export const ButtonHelpHighLight = styled(Button)`
  flex: 1;
  background-color: ${theme('colors.blueFirstButton')};
  font-size: 12px;
  align-self: center;
  color: ${theme('colors.background')};
`;
