import * as React from 'react';
import { MainContainer, ContainerList } from './style';
import { CustomPaper } from '../';
interface ContainerProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  header,
}: ContainerProps): JSX.Element => {
  return (
    <MainContainer>
      <CustomPaper>
        {header}
        <ContainerList>{children}</ContainerList>
      </CustomPaper>
    </MainContainer>
  );
};
